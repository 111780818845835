import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cadastro-livelo-regulamento',
  templateUrl: './cadastro-livelo-regulamento.component.html',
  styleUrls: ['./cadastro-livelo-regulamento.component.css']
})
export class CadastroLiveloRegulamentoComponent implements OnInit {

  constructor() { }
  src: any = "../../../assets/pdf/RegulamentoGift.pdf"
  ngOnInit(): void {
  }

}
