import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clube-pipooh-planosebeneficios',
  templateUrl: './clube-pipooh-planosebeneficios.component.html',
  styleUrls: ['./clube-pipooh-planosebeneficios.component.css']
})
export class ClubePipoohPlanosebeneficiosComponent implements OnInit {

  constructor() { }
  src: any = "../../../assets/pdf/Tabela de Mensalidade dos Planos-convertido.pdf"
  ngOnInit(): void {
  }

}
