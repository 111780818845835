import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ContatoComponent implements OnInit {
  //Info interessado
  email: any;
  nome: any;
  mensagem: any;
  constructor(
    private toastr: ToastrService,
    private _helperService: HelperService) { }

  ngOnInit(): void {
  }
  enviar() {
    if (this.email == "" || this.email == null) {
      this.toastr.error('Email obrigatorio!');
      return;
    }
    if (this.nome == "" || this.nome == null) {
      this.toastr.error('Nome obrigatorio!');
      return;
    }
    if (this.mensagem == "" || this.mensagem == null) {
      this.toastr.error('Mensagem obrigatorio!');
      return;
    }
    this._helperService.emailContato({ "email": this.email, "nome": this.nome, "mensagem": this.mensagem }).then(data => {
      this.toastr.success('Mensagem enviado com sucesso!');
    })
  }
}
