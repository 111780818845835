<div style="text-align: center;" id="logologo">
    <div>
        <img src="./assets/img/logo transp.png" style="width: 50%; " alt="" class="img-fluid">
    </div>

</div>

<section id="ajuda-pontos" style="margin-top: 10px;">
    <div class="moda-body info" style="margin: 10px; margin-bottom: 10px;">
        <div class="modal-body pontos" style="margin: 10px;">
            <br>
            <h2 style="color: #f5a4c7;">
                <mat-icon>loyalty</mat-icon>Na pipooh você acumula pontos e tem direito a produtos exclusivos em
                promoção.
            </h2>
            <div class="line-pont"></div>
            <h2>Quantos produtos tenho direito</h2>
            <p>Confira na tabela abaixo qual a sua categoria e quantos produtos terá direito de compra no mês
                não é cumulativo todo dia 01 o valor é zerado podendo adquirir novamente
            </p>
            <div class="line-pont"></div>

            <table class="table table-striped text-left">
                <thead>
                    <tr>
                        <th scope="col">Categoria</th>
                        <th scope="col">Pontos</th>
                        <th scope="col">Produtos</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cat of this.categorias; let i = index;">
                        <td>{{cat.categoria}}</td>
                        <td *ngIf="cat.pontos == 0">-</td>
                        <td *ngIf="cat.pontos > 0">{{formatMilhares(cat.pontos)}}</td>
                        <td *ngIf="cat.produtos != 1000">{{cat.produtos}}</td>
                        <td *ngIf="cat.produtos == 1000">Ilimitado</td>
                        <td *ngIf="i == 0"><img style="height: 36px; color: #01b1af;"
                                src="https://img.icons8.com/ios/50/000000/babys-room.png" />
                        <td *ngIf="i == 1"><img style="height: 36px; color: #01b1af;"
                                src="https://img.icons8.com/pastel-glyph/64/000000/medal--v2.png" />
                        <td *ngIf="i == 2"><img style="height: 36px; color: #01b1af;"
                                src="https://img.icons8.com/ios/50/000000/medal.png" />
                        <td *ngIf="i == 3"><img style="height: 36px; color: #01b1af;"
                                src="https://img.icons8.com/ios-glyphs/30/000000/diamond--v1.png" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="line-pont"></div>
            <h2>Como acumular pontos? </h2>
            <p>A cada 1 real ganho em presente ou em compras de crédito no site será ganho 50 pontos e a cada 1
                real ganho em indicação será revertido em 25 pontos na plataforma.</p>

            <div class="line-pont"></div>
            <h2>Promoção de produtos</h2>
            <p>Toda sexta-feira produtos em promoção são liberados com limitação de estoque dos quais são
                adquiridos por ordem de compra.</p>

            <div class="line-pont"></div>

            <h2>Meus pontos vencem?</h2>
            <p>Sim, no mês subsequente ao mês de aniversário da sua conta (data de abertura da conta), os pontos zeram e
                sua categoria regride para uma anterior. <br>
                Ex: a abertura da sua conta é janeiro, o seus pontos e categoria são zerados/ regredido no dia 01 de
                fevereiro.</p>
        </div>

    </div>
    <br>
</section>
