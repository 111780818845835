import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentInit,
} from '@angular/core';
import { environment } from './../../environments/environment.prod';
import { ConvidadoService } from '../_services/convidado.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../_services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrinhoService } from '../_services/carrinho.service';
import { RifaService } from '../_services/rifa.service';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { SEOService } from '../_services/seo.service';
import * as moment from 'moment';
import { MatRadioButton } from '@angular/material/radio';
import { data } from 'jquery';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';


@Component({
  selector: 'app-evento-pipooh',
  templateUrl: './evento-pipooh.component.html',
  styleUrls: ['./evento-pipooh.component.css'],
})
export class EventoPipoohComponent implements OnInit {
  public URL = `${environment.domain}`;
  public URLNOVA = 'https://img.pipooh.com.br/';
  infoMamae: any = [];
  closeResult = '';
  products: any = [];
  infoUser: any = [];
  categorys: any = '';
  chooseSelect: any;
  page = 1;
  pageSize = 12;
  extrato: any = [];
  carrinho: any = [];
  product: any = [];
  more: boolean = false;
  quantidade: any = 1;
  options: any = [];
  option: any;
  optionID: any;
  total = 0;
  imagens: any;
  chosenImg: any;
  prodLoad: any = 1;
  carrinhoTotal: any = [];
  premiosRifa: any;
  bilhetesValor: any;
  presencaConfirmacao: any;
  presencaEmail: any;
  presencaDataNascimento: any;
  presencaTelefone: any;
  presencaIdade: any;
  presencaCrianca: any = 0;

  presencaAdulto: any = 1;
  infoEvento: any = [];
  presentearValido: any;
  acompanhantes: string = '2';
  
  //Revelação
  premiosRevelacao: any = [];
  votosRevelacao: any = [];
  //
  termos: any;
  politica: any;
  //loading
  loading: any = false;
  loadingCarrinho: any;
  // filtros
  generoSelecionado: any = '';
  lojaSelecionada: any = '';
  busca: any;
  categoriaSelecionada: any = '';
  ordemSelecionada: any = '';
  idadeSelecionada: any = '';
  idades: any = ["De 0 à 12 meses", "De 1 à 2 anos", "De 3 à 4 anos", "De 5 à 7 anos", "De 8 à 11 anos", "A partir de 12 anos"];
  dependentes: any = [];
  dependentesQtd: any = 0;

  constructor(
    private _convidado: ConvidadoService,
    private modalService: NgbModal,
    private _prodService: ProductsService,
    private route: ActivatedRoute,
    private _router: Router,
    private _carrinho: CarrinhoService,
    private _rifa: RifaService,
    private toastr: ToastrService,
    private seoService: SEOService,
  ) {
  }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.get('id');
    var myParam2 = urlParams.get('evento');

    if (
      this.route.snapshot.params['id'] &&
      this.route.snapshot.params['evento']
    ) {
      myParam = this.route.snapshot.params['id'];
      myParam2 = this.route.snapshot.params['evento'];
    }
    if (myParam != localStorage.getItem('pipoohMamae')) {
      localStorage.setItem('pipoohMamae', myParam);
      localStorage.setItem('pipoohEvento', myParam2);

      localStorage.removeItem('carrinho');
      localStorage.removeItem('frete');
    } else {
      localStorage.setItem('pipoohMamae', myParam);
      localStorage.setItem('pipoohEvento', myParam2);
    }
    this.getInfoUser();
    this.getInfoEvento();
    this.imagens = JSON.parse(
      '{"payload":[{"id":"1"}, {"id":"2"}, {"id":"3"}]}'
    );
    this.carrinho = this._carrinho.getLocalCarrinho();

    this.getTotal();
  }

  ancora(tag) {
    const queryParams = { ...this.route.snapshot.queryParams, '#produtos': true };
  this._router.navigate(['evento-pipooh'], { queryParamsHandling: 'merge', queryParams, fragment: tag });
    // this._router.navigate(['evento-pipooh', { id: this.infoMamae.UserID }], {
    //   fragment: tag,
    // });
  }

  addCarrinho(product) {
    // this.infoMamae.UserName
    product.userName = this.infoMamae.UserName;
    product.userID = this.infoMamae.UserID;
    product.quantidade = this.quantidade;
    console.log("product", product)
    this._carrinho.addCarrinho(product);
    this.carrinho = this._carrinho.getItems();
    console.log("carrinho", this.carrinho)
    this.getTotal();
  }
  changeImg(i) {
    this.chosenImg = i;
  }
  changeQtd(kind) {
    if (kind == 'up') {
      this.quantidade++;
    } else {
      if (this.quantidade > 1) {
        this.quantidade--;
      }
    }
  }
  changeQtdPresencaAdulto(kind) {
    if (kind == 'up') {
      this.presencaAdulto++;
    } else {
      if (this.quantidade > 0) {
        this.presencaAdulto--;
      }
    }
  }
  changeQtdDependentes(kind) {
    if (kind == 'up') {
      this.dependentes.push({ "nome": "", "dataNascimento": "" });
      this.dependentesQtd++;
    } else {
      if (this.quantidade > 0) {
        this.dependentes.pop();
        this.dependentesQtd--;
      }
    }
  }
  changeQtdPresencaCrianca(kind) {
    if (kind == 'up') {
      this.presencaCrianca++;
    } else {
      if (this.quantidade > 0) {
        this.presencaCrianca--;
      }
    }
  }
  formatDecimal(string) {
    if (string != '') {
      return parseFloat(string).toFixed(2);
    }
  }

  getInfoUser() {
    let mamae = localStorage.getItem('pipoohMamae');
    this._convidado.getMamae({ UserID: mamae }).then((data) => {
      this.infoMamae = data;
      this.infoMamae = this.infoMamae.payload[0];
      this.seoService.updateMetaInfo(
        'og:image',
        'https://img.pipooh.com.br/' + this.infoEvento.imagem
      );
    });
  }
  formatDate(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).add(0, 'days').format('YYYY-MM-DD');
      return startTime;
    }
  }
  formatDateBR(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).add(0, 'days').format('DD/MM/YYYY');
      return startTime;
    }
  }

  compararDatas(vencimento) {
    let dataVencimento = this.formatDate(vencimento);
    let dataArray = dataVencimento.split('-');
    
    
    let dateVencimento = new Date(
      parseInt(dataArray[0]),
      parseInt(dataArray[1]),
      parseInt(dataArray[2])
    );

    let dataHoje = new Date()
      .toISOString()
      .replace('-', '/')
      .split('T')[0]
      .replace('-', '/');
    let dataArrayHoje = dataHoje.split('/');

    let dateHoje = new Date(
      parseInt(dataArrayHoje[0]),
      parseInt(dataArrayHoje[1]),
      parseInt(dataArrayHoje[2])
    );
    if (dateVencimento >= dateHoje) {
      this.presentearValido = true;
  }
}

comparaData(dataLimiteConfirmacao: Date): boolean {
  const dataAtual = this.formatDate(new Date());
  const dataLimite = this.formatDate(dataLimiteConfirmacao);
  const dataLMTConfirma = dataAtual <= dataLimite;
  return dataLMTConfirma
}



  getInfoEvento() {
    let mamae = localStorage.getItem('pipoohMamae');
    let evento = localStorage.getItem('pipoohEvento');

    this._convidado
      .getEventoPorID({ UserID: mamae, eventoID: evento })
      .then((data) => {
        this.infoEvento = data;
        this.infoEvento = this.infoEvento.payload[0];
        if(this.infoEvento.Ativo == null){
          this._router.navigate(['/'])
        }
        console.log("infoEvento", this.infoEvento)
        console.log("comparaDt", this.infoEvento.dataLMTConfirma)
        
        this.getCategorias(evento);
        this.compararDatas(this.infoEvento.vencimento);

        if (this.infoEvento.rifaAtiva == 1) {
          this._rifa
            .getRifaPremiosPorEvento({
              UserID: localStorage.getItem('pipoohMamae'),
              eventoID: localStorage.getItem('pipoohEvento'),
            })
            .then((data) => {
              this.premiosRifa = data.payload;
            });
          this.bilhetesValor = this.infoEvento.valorBilhete;
        }
        if (this.infoEvento.statusRevelacao == true) {
          this.getVotosRevelacao();
        }  
        if (this.infoEvento.festaTipo == '""' || this.infoEvento.festaTipo == undefined) {
          this.infoEvento.festaTipo = false
        }
        this.seoService.updateTitle(
          'Participe do chá do(a) ' + this.infoEvento.nome
        );
      });
  }

  open(content) {
    console.log(content);
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title,',
        size: 'lg',
        windowClass: 'modal',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  openMD(content) {
    console.log(content);
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title,',
        size: 'md',
        windowClass: 'modal',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  getProducts() {
    let evento = localStorage.getItem('pipoohEvento');
    this._prodService
      .getProductsListaEvento({
        pFkEvento: evento,
        busca: this.busca,
        categoria: this.categoriaSelecionada,
        genero: this.generoSelecionado,
        loja: this.lojaSelecionada,
        idade: this.idadeSelecionada,
        ordem: this.ordemSelecionada
      })
      .then((data) => {
        var prod: any = data;
        this.products = prod.payload;
        console.log("produtos", this.products)
        this._prodService.getProductsImagem({ fkProduto: '' }).then((data2) => {
          var imgs: any = data2;
          this.imagens = imgs.payload;
          for (var i = 0; i < this.products.length; i++) {
            this.products[i].imagens = [];
            this.products[i].imagens.push(this.products[i].ProductsImage);
            for (var h = 0; h < this.imagens.length; h++) {
              if (this.products[i].ProductsID == this.imagens[h].fkProduto) {
                this.products[i].imagens.push(this.imagens[h].produtoImagem);
              }
            }
          }
        });
      });
  }
  getCategorias(evento) {
    this._prodService.getCategorias({ "fkEvento": evento }).then((data) => {
      var cat: any = data;
      this.categorys = cat.payload;
      if (this.route.snapshot.params['filtro']) {
        this.categoriaSelecionada = this.route.snapshot.params['filtro'];
      }
      this.getProducts();
    });
  }

  confirmarPresenca() {
    this.loading = true;
    let mamae = localStorage.getItem('pipoohMamae');
    console.log("this.presencaDataNascimento", this.presencaDataNascimento)
    if (this.presencaTelefone == null || this.presencaTelefone == undefined) {
      this.toastr.error('Preencha o telefone');
      this.loading = false;
      return;
    }
    if (this.presencaTelefone.length < 11) {
      this.toastr.error('Preencha o telefone');
      this.loading = false;
      return;
    }
    if (
      this.presencaConfirmacao == null ||
      this.presencaConfirmacao == undefined
    ) {
      this.toastr.error('Preencha o seu nome');
      this.loading = false;
      return;
    }
    if (this.presencaEmail == null || this.presencaEmail == undefined) {
      this.presencaEmail = ' ';
    }
    if (this.presencaAdulto == null || this.presencaAdulto == undefined) {
      this.presencaAdulto = 0;
    }
    if (this.presencaDataNascimento == null || this.presencaDataNascimento == undefined) {
      this.toastr.error('Preencha a data de nascimento');
      this.loading = false;
      return;
    }
    if (!this.presencaDataNascimento || this.presencaDataNascimento.length < 10) {
      this.loading = false;
      this.toastr.error("Forneça uma data válida")
      return;
    } 

    if (this.termos !== true) {
      this.toastr.error("Concorde com os Termos e condições de uso.");
      this.loading = false;
      return;
    }
    if (this.politica !== true) {
      this.toastr.error("Concorde com as Política de privacidade.");
      this.loading = false;
      return;
    }  
    var preenchido = true;
    this.dependentes.forEach(dependente => {
      if ((!dependente.nome || dependente.nome == "") || (!dependente.dataNascimento || dependente.dataNascimento == "")) {
        preenchido = false;
      }
      if (!dependente.dataNascimento || dependente.dataNascimento.length < 10) {
        preenchido = false;
      } 
    });
    
    if (!preenchido) {
      this.toastr.error("Preencha os dados dos dependentes corretamente");

      this.loading = false;
      return;
    } else {
      if (
        this.presencaConfirmacao != null &&
        this.presencaConfirmacao != undefined
      ) {
        
        this._convidado
          .confirmarPresenca({
            UserID: mamae,
            ConvidadoNome: this.presencaConfirmacao,
            ConvidadoEmail: this.presencaEmail,
            eventoID: localStorage.getItem('pipoohEvento'),
            ConvidadoTelefone: this.presencaTelefone,
            ConvidadoDataNascimento: this.presencaDataNascimento,
            dependentes: this.dependentes,
            listaPresentes: this.infoEvento.listaPresentes,
          })
          .then((data) => {
            // this.infoMamae = data;
            // this.infoMamae = this.infoMamae.payload[0];
            this.toastr.success('Presença registrada com sucesso');
            this.loading = false;
            this.modalService.dismissAll();
          }).catch((e) => {
            // this.infoMamae = data;
            // this.infoMamae = this.infoMamae.payload[0];
            this.toastr.success('Presença registrada com sucesso');
            this.loading = false;
            this.modalService.dismissAll();
          });
      } else {
        this.loading = false;
        this.toastr.error('Preencha o seu nome!');
      }
    }
    

    this.loading = false;
  }
  getTotal() {
    this.total = 0;
    this.carrinhoTotal = JSON.parse(localStorage.getItem('carrinho'));
    var car = this.carrinhoTotal;
    if (this.carrinhoTotal == null || this.carrinhoTotal == undefined) {
      return;
    }
    this.carrinhoTotal.forEach((elemento) => {
      this.total =
        this.total +
        parseFloat(elemento['ProductsPrice']) *
        parseFloat(elemento['quantidade']);
    });
  }

  openProduto(prod) {
    this.prodLoad = 0;
    this.options = [];
    this.option = '';
    this.optionID = '';
    this.chosenImg = 0;
    this.product.obs = '';
    this.more = false;
    this.quantidade = 1;
    document.getElementById('openModalProduto').click();
    this.product = prod;
    this.prodLoad = 1;
  }
  removeCarrinho(item) {
    this._carrinho.removeCarrinho(item);
    this.carrinho = this._carrinho.getItems();
    this.getTotal();
  }
  urlImage(image) {
    return this.URLNOVA + image;
  }
  urlImageLoja(image) {
    return image;
  }

  getPremiosRevelacao() {
    this._convidado.getPremiosRevelacao({ revelacaoID: this.infoEvento.fkRevelacao, eventoID: this.infoEvento.fkEvento }).then((data) => {
      this.premiosRevelacao = data.payload;
      console.log(this.premiosRevelacao)
    });
  }

  getVotosRevelacao() {
    this._convidado.getVotosRevelacao({ revelacaoID: this.infoEvento.fkRevelacao, eventoID: this.infoEvento.fkEvento }).then((data) => {
      this.votosRevelacao = data.payload;
      console.log(this.votosRevelacao)
    });
  }  

  
}
