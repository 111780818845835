<app-header></app-header>


<div class="row justify-content-center" id="comoFunciona">
    <div class="col-12" style="text-align: center; margin-top: 40px;">
        <div class="section-title">
            <h2 style="color:#01B1AF ; font-weight: 700; font-size: 40px; ">Deseja sair da lista de lembretes?</h2>
        </div>
    </div>
    <div class="col-lg-10">
        <div class="row justify-content-center"
            style="padding: 70px; text-align: center;     border: 1px solid #01B1AF; border-radius: 20px; margin-bottom: 10px;margin: 20px;  ">

            <div class="col-lg-6">
                <p>
                    {{convidadoDados.nome}}, deseja deletar seu número dos disparos de lembrete do evento {{convidadoDados.nomeEvento}}?
                </p>
            </div>

            <div class="col-12" style="text-align: center; margin-top: 40px;">

                <button class=" btn " (click)="deleteConvidado()"
                    style="color: white; padding: 10px; background-color: #01B1AF;border-radius: 25px; margin-bottom: 35px; font-size: 15px !important;">
                    Excluir meu número da lista
                </button>
            </div>
        </div>
    </div>



</div>

<app-footer></app-footer>