<app-header></app-header>

<div class="row justify-content-center" style="margin-top: 25px;">
    <div class="col-lg-4">
    </div>
    <div class="col-lg-4" style="text-align: center;">
        <div class="section-title">
            <h2>Sextou Pipooh</h2>
        </div>
        <p>Toda sexta-feira produtos novos na promoção. 
        </p>
    </div>
    <div class="col-lg-4" style="padding: 25px;">
    </div>
</div>

<div class="row justify-content-center" style="padding: 25px; ">

    <div class="col-xs-12 col-md-8 p-xs-1">
        <div class="row" *ngIf="this.products">
            <div class="col-xs-12 col-md-4"
                *ngFor="let prod of products | slice: (page-1) * pageSize : page * pageSize">

                <div class="row" style="text-align: center;">
                    <div class="col-md-12 col-5" style="margin-bottom: 10px;">
                        <img (click)="openProduto(prod)" src="{{this.urlImage(prod.ProductsImage)}}" class="" alt=""
                            style="max-height: 250px; max-width: 100%; border-radius: 25px;box-shadow: 0px 3px 6px #00000029;" />
                    </div>

                    <div class="col-md-12 col-7" style="text-align: center;">
                        <div style="min-height: 70px; max-height: 90px;">
                            <h6 class="" style="color:#6A6A6A;">{{prod.ProductsName}}</h6>

                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12" style="text-align: center;">
                                <h6 class="mt-1" style="color: red; text-decoration: line-through;">R$ {{this.formatDecimal(prod.precoAnterior)}}
                                </h6>
                                <h4 class="mt-1" style="color: #01B1AF;">R$ {{this.formatDecimal(prod.ProductsPrice)}}
                                </h4>

                            </div>
                            <!-- <div class="col-6 btn btn-primary" (click)="openProduto(prod)"
                                style="text-align: center; margin-bottom: 10px; border-radius: 25px; color: white;">
                                Visualizar
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row py-3" *ngIf="!this.products">
            <div class="col-xs-12 col-md-4 py-2">
                <div class="media">
                    <h3>Produto não encontrado</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 justify-content-center">
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [collectionSize]="products.length"
                    [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>




<!-- Modais a partir daqui -->

<ng-template #modalProduto let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="container-fluid ">
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="let img of product.imagens ; let i=index;let firstItem = first;"
                        class="tab-pane active" id="imagem{{i}}" aria-expanded="true">
                        <img [hidden]="this.chosenImg != i" src="{{this.urlImage(img)}}" width="100%"
                            style="border-radius: 25px;" />
                    </div>

                    <ul *ngIf="product"
                        class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
                        role="tablist">
                        <li *ngFor="let prod of product.imagens ; let i=index;let firstItem = first;"
                                    class="nav-item m-tabs__item">
                                    <a class="nav-link m-tabs__link" [ngClass]="{ active: firstItem }" data-toggle="tab"
                                        (click)="changeImg(i)" role="tab">{{i+1}}</a>
                                </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h5>{{this.product.ProductsName}}</h5>
                    <div *ngIf="this.more">
                        <div [innerHTML]="this.product.ProductsDescription">
                        </div>
                    </div>

                    <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
                    <h4 class="mt-3" style="color: #01b1af;">R$ {{this.formatDecimal(this.product.ProductsPrice)}}</h4>
                    <br>
                    <div class="row">
                        <div class="col-12 justify-content-center" style="text-align: center;">
                            <div class="alert alert-danger" role="alert">
                                Você precisa escolher uma mamãe/papai para realizar a compra. Procure-os clicando <a href="escolher-mamae">aqui.</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
    data-target="#openModalProduto">Launch demo modal</button>
    <app-footer></app-footer>