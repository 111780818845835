import { Component, OnInit } from '@angular/core';
import { CategoriaPontuacaoService } from '../../_services/categoria-pontuacao.service';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-ajuda-pontos',
  templateUrl: './ajuda-pontos.component.html',
  styleUrls: ['./ajuda-pontos.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AjudaPontosComponent implements OnInit {

  constructor(private _categoriaPontuacaoService: CategoriaPontuacaoService) { }
  categorias: any = [];
  ngOnInit(): void {
    this.buscarCategoriaPontuacao();
  }
  buscarCategoriaPontuacao() {
    this._categoriaPontuacaoService.buscarCategorias({}).then(data => {
      this.categorias = data.payload;
    });
  }
  formatMilhares(string){
    if(string != ""){
      return string.toLocaleString('pt-BR');
    }
  }

}
