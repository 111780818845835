import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { environment } from './../../environments/environment.prod';
import { ConvidadoService } from '../_services/convidado.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../_services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrinhoService } from '../_services/carrinho.service';
import { RifaService } from '../_services/rifa.service';







@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})


export class ProdutosComponent implements OnInit {
  public URL = `${environment.domain}`;
  public URLNOVA = "https://img.pipooh.com.br/"
  infoMamae: any = [];
  userVencimento: any;
  closeResult = '';
  products: any = [];
  infoUser: any = [];
  productsCount: any = [];
  productFranquia: any = "";
  categorys: any = "";
  category: any = "";
  description: any = "";
  search: any = "";
  chooseSelect: any;
  page = 1;
  pageSize = 12;
  extrato: any = [];
  carrinho: any = [];
  product: any = [];
  more: boolean = false;
  quantidade: any = 1;
  options: any = [];
  option: any;
  optionID: any;
  total = 0;
  alertSize: boolean = false;
  imagens: any;
  chosenImg: any;
  prodLoad: any = 1;
  franquiaID: any;
  recomendacao: any;
  carrinhoRecomendacao: any = [];
  pedido: any = [];
  tokenPedido: any;
  recomendacoes: any = [];
  recomendacaoTotal: any;
  recomendacaoTitulo: any;
  recomendacaoDescricao: any;
  recomendacaoTituloArr: any = [];
  selectedCategory: any = "";
  loadingCarrinho: any;
  valor: any;
  produtoIndisponivel: any;
  carrinhoTotal: any = [];
  premiosRifa: any;
  bilhetesValor: any;




  constructor(
    private _convidado: ConvidadoService,
    private modalService: NgbModal,
    private _prodService: ProductsService,
    private route: ActivatedRoute,
    private _carrinho: CarrinhoService,
    private _rifa: RifaService,


  ) { }

  ngOnInit(): void {
    this.getCategorias();
    this.imagens = JSON.parse('{"payload":[{"id":"1"}, {"id":"2"}, {"id":"3"}]}');
  }

  changeImg(i) {
    this.chosenImg = i;
  }
  
  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getSearch() {
    this.products = [];
    this.category = "Descricao";
    this.description = this.search;
    if (this.search == "") { this.description = ''; this.category = ''; }
    this.getProducts(this.category, this.description);
  }
  getProducts(productFilter, productDescription) {
    this._prodService.getProductsListAll({ "pProductsFiltro": productFilter, "pProductsDescricao": productDescription, "pFranquia": "1" }).then(data => {
      var prod: any = data;
      this.products = prod.payload;
      this._prodService.getProductsImagem({ "fkProduto": "" }).then(data2 => {
        var imgs: any = data2;
        this.imagens = imgs.payload;
        for (var i = 0; i < this.products.length; i++) {
          this.products[i].imagens = [];
          this.products[i].imagens.push(this.products[i].ProductsImage);
          for (var h = 0; h < this.imagens.length; h++) {
            if (this.products[i].ProductsID == this.imagens[h].fkProduto) {
              this.products[i].imagens.push(this.imagens[h].produtoImagem);
            }
          }
        }
      })
    })
  }
  getCategorias() {
    this._prodService.getCategorias({}).then(data => {

      var cat: any = data;
      this.categorys = cat.payload;
      if (this.route.snapshot.params['filtro']) {
        this.selectedCategory = this.route.snapshot.params['filtro'];
      }

      this.onSelect();
    })

  }

  onSelect() {

    this.products = [];
    this.category = "Categoria";
    this.description = this.selectedCategory;
    if (this.selectedCategory == "") { this.description = ''; this.category = ''; }

    this.getProducts(this.category, this.description);
  }

  openProduto(prod) {
    this.produtoIndisponivel = null;
    this.prodLoad = 0;
    this.options = [];
    this.option = '';
    this.optionID = '';
    this.chosenImg = 0;
    this.product.obs = "";
    this.more = false;
    this.alertSize = false;
    this.quantidade = 1;
    document.getElementById("openModalProduto").click();
    this.product = prod;
    this.prodLoad = 1;
  }

  urlImage(image) {
    return this.URLNOVA + image;
  }
  urlImageLoja(image) {
    return image;
  }

}
