import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PedidoService } from 'src/app/_services/pedido.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { Subscription, interval } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pre-lancamento',
  templateUrl: './pre-lancamento.component.html',
  styleUrls: ['./pre-lancamento.component.css']
})
export class PreLancamentoComponent implements OnInit {
  closeResult = '';
  private subscription: Subscription;

  public dateNow = new Date();
  public dDay = new Date('Apr 25 2022 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  email: any;
  plano: any;
  termos: any;
  categoriaAssinatura

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;


  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _pedidoService: PedidoService,
    private _usuarioService: UsuarioService,
    private modalService: NgbModal,
    private toastr: ToastrService,
  ) { }
  id: any;
  valor: any = 10;
  ngOnInit(): void {
    // this.id = this._route.snapshot.queryParams['id'];
    // console.log("ud", this.id);
    this.subscription = interval(1000)
      .subscribe(x => { this.getTimeDifference(); });
    // this.inserirPedido();
  }

  inserirPedido() {
    if(this.termos === true){
    this._usuarioService.selecionarEmail({ "email": this.email }).then(data => {
      if (data.payload.length == 0) {
        this.toastr.error("E-mail não cadastrado na Pipooh", "E-mail inválido");
      } else {
        this.id = data.payload[0].UserID;
        this._pedidoService.inserirPedidoAssinatura({ "valor": this.valor, "fkUsuario": this.id,  "fkCategoriaAssinatura": this.plano}).then(data => {
          var aux = data.payload[0];
          window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];
          // if (this.plano == "Baby") {
          //   window.location.href = "http://pag.ae/7YaKx32hP";
          // } else if( this.plano == "Infant") {
          //   window.location.href = "http://pag.ae/7YaKyf-b4";
          // } else if(this.plano == "Kids") {
          //   window.location.href = "http://pag.ae/7YaKzaV9p";
          // } else if(this.plano == "Child"){
          //   window.location.href = "http://pag.ae/7YaKAaF9s";
          // }
        });
      }
    });
  }else {
    this.toastr.error("Concorde com os termos e condições de uso!");
  }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ancora(tag) {
    this._router.navigate(['/pre-lancamento'], { fragment: tag });
  }


  open(content) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass: 'modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
