import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PedidoService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;



  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

  inserirPedidoAssinatura(formData) {
    return this.postData(`${this.URLAspNet}pedido/landing/insert/assinatura`, formData);
  }
  inserirPedidoLivelo(formData) {
    return this.postData(`${this.URLAspNet}pedido/web/insert/credito/livelo`, formData);
  }
  inserirPedidoUsuarioLivelo(formData) {
    return this.postData(`${this.URLAspNet}pedido/web/insert/user/credito/livelo`, formData);
  }
  inserirPedidoCartaoPresente(formData) {
    return this.postData(`${this.URLAspNet}pedido/web/insert/cartaoPresente`, formData);
  }
  selectPedidoToken(formData) {
    return this.postData(`${this.URLAspNet}pedido/web/select/token`, formData);
  }

}
