import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ServicoGiftService {
  private URLAspNet = `${environmentAspNet.api}`;
  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

    buscarServicos(formData) {
      return this.postData(`${this.URLAspNet}servicoGift/app/select/lista`, formData);
  }

}
