<app-header></app-header>
<div class="row justify-content-center" style="margin: 25px;">
    <div class="col-lg-2"></div>
    <div class="col-lg-8" style="text-align: center;">
        <div class="section-title">
            <h2>Quero trocar fraldas por créditos</h2>
        </div>
        <div class="">
            <p>Você tem sobras de tamanhos, de marcas que seu bebê tem alergia ou não se adaptou?</p>
            <p>Está cansada de postar em grupos e redes sociais para ir fazer trocas no metrô?</p>
        </div>
        <div class="">
            <p>A Pipooh compra as suas fraldas. </p>
            <p>Aqui suas fraldas viram créditos para uso em nosso sistema e podem gastar como desejar. </p>
            <p>Com os créditos compre em nosso e-commerce ou loja física:  <a href="produtos">https://web.pipooh.com.br/produtos</a></p>
            <p>Ou em lojas parceiras ganhando Cashback.</p>
        </div>
        <div class="row">
            <carousel style="width: 100%;">

                <div class="carousel-cell">
                    <div class="carousel-cell" style="background-color: white;border-radius: 100px; padding: 10px;">
                        <img style="width: 100%;" src="/assets/img/gravidicas.png" alt="">
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="carousel-cell" style="background-color: white;border-radius: 100px; padding: 10px;">
                        <img style="width: 100%;" src="/assets/img/brasileirinha-logo.png" alt="">
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="carousel-cell" style="background-color: white;border-radius: 100px; padding: 10px;">
                        <img style="width: 100%;" src="/assets/img/loja-bebe.png" alt="">
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="carousel-cell" style="background-color: white;border-radius: 100px; padding: 10px;">
                        <img style="width: 100%;" src="/assets/img/Picorruchos.png" alt="">
                    </div>
                </div>
                <div class="carousel-cell">
                    <div class="carousel-cell" style="background-color: white;border-radius: 100px; padding: 10px;">
                        <img style="width: 100%;" src="/assets/img/limara.png" alt="">
                    </div>
                </div>
            </carousel>
            <div class="col-12" style="text-align: center; color: white; margin: 20px; padding: 20px;">
                <a href="https://app.pipooh.com.br/?refRemarketing=site-principal&ref=site-principal" class="btn"
                    style="background-color: #09B4B2; color: white ; border-radius: 25px;">Confira mais parceiros aqui!</a>
    
            </div>
        </div>
        
        <div class="section-title">
            <h2>Solicite seu orçamento</h2>
        </div>
        <div class="row">
            <div class="col-lg-4">
                1.Preencha os formulários abaixo.
            </div>
            <div class="col-lg-4">
                2.Aguarde a equipe entrar em contato em até 48horas.
            </div>
            <div class="col-lg-4">
                3.Suas fraldas viram crédito para usar na carteira Pipooh.
            </div>
        </div>
        
        <br>
        <div class="section-title">
            <h2>Formulário</h2>
        </div>
        <div class="form">
            <div class="form-group row">
                
            </div>
            <div class="form-group row">
                <div class="col forms">
                    <input type="text" class="form-control" [(ngModel)]="nome" placeholder="Nome completo*">
                </div>
                <div class="col forms">
                    <input type="text" class="form-control" [(ngModel)]="telefone" mask="(00)00000-0000" placeholder="Telefone / Whatsapp*">
                </div>
            </div>
            <div class="form-group row">
                <div class="col forms">
                    <input type="text" class="form-control" [(ngModel)]="email" placeholder="Email*">
                </div>
                <div class="col forms">
                    <input type="text" class="form-control" [(ngModel)]="cep" mask="00000-000" placeholder="CEP">
                </div>
            </div>
            
            <!-- <br>
            <div class="" style="text-align: left;">
                <p>Descrição dos campos:</p>
                <p>1.Marca</p>
                <p>2.Quantidade de pacotes a serem trocados</p>
                <p>3.Tamanho dos pacotes</p>
                <p>4.Quantidade de fraldas por pacotes</p>
                <p>5.Validade mais próxima dos pacotes</p>
            </div>
            <br>
            <div class="row">
                <table class="table table-striped text-left">
                    <tbody>
                        <tr *ngFor="let prod of this.produtos">
                            <td><select class="form-control" [(ngModel)]="prod.marca">
                                <option value="">1.Marca</option>
                                <option value="Pampers ConfortSec">Pampers ConfortSec</option>
                                <option value="Pampers SuperSec">Pampers SuperSec</option>
                                <option value="Pampers Premium Care">Pampers Premium Care</option>
                                <option value="Huggies Supreme">Huggies Supreme</option>
                                <option value="Huggies Tripla Ação">Huggies Tripla Ação</option>
                                <option value="Pom Pom">Pom Pom</option>
                                <option value="Babysec">Babysec</option>
                            </select></td>
                            <td><input mask="0*" type="text" [(ngModel)]="prod.pacotes" class="form-control" placeholder="2.Qtd pacotes"></td>
                            <td><input type="text" [(ngModel)]="prod.tamanho" class="form-control" placeholder="3.Tamanho"></td>
                            <td><input mask="0*" type="text" [(ngModel)]="prod.quantidade" class="form-control" placeholder="4.Fraldas p/pacote"></td>
                            <td><input mask="00/00/00" type="text" [(ngModel)]="prod.validade" class="form-control" placeholder="5.Prox. validade"></td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </div>
        <!-- <div class="col-12">
            <a style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;" (click)="adicionarProduto()">Adicionar uma opção</a>
        </div> -->
        <div class="col-12">
            <a style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;" (click)="enviar()">Enviar</a>
        </div>
       
    </div>
    <div class="col-lg-2"></div>
</div>
<app-footer></app-footer>