<app-header></app-header>
<div class="row justify-content-center" style="margin: 25px;">
    <div class="col-lg-6" >
        <div class="section-title" style="text-align: center;">
            <h2>Afiliado</h2>
        </div>
        <p style="text-align: center;">O Afiliado é um representante que obtém ganhos financeiros com comissionamento vitalício de tudo que as mães indicadas e cadastradas com o seu link. </p>
        <p style="text-align: center;">A empresa recompensa o afiliado por cada cliente trazido pelos próprios esforços de marketing/divulgação para o site da empresa e convertidos em vendas.
        </p>
        <p style="margin-left: 10px;"><strong>Ganhos:</strong></p>
        <ul>
            <li>Kit de boas vindas. </li>
            <li>5% de tudo o que a conta consumir no e-commerce Pipooh.
            </li>
            <li>1,5% de tudo o que a conta consumir como meio de pagamento em lojas parceiras. </li>
            <li>Ranking com premiações semestrais. </li>
        </ul>
        <p style="text-align: center;"><i>Os pagamentos das comissões são realizados 1 vez por mês em sua conta bancária.</i>  </p>
        
        <div class="dados" style="text-align: left;">
            <h5 style="color: #01B1AF;">Se cadastre e conheça mais sobre o programa no link:</h5>
            <div class="row" style="margin-right: 15px;">
                <!-- <div class="col-6">
                    <input type="text" [(ngModel)]="nome" placeholder="Nome">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="email" placeholder="Email">
                </div>
                <br>
                <div class="col-6">
                    <input type="text" [(ngModel)]="telefone" placeholder="Telefone">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="redes" placeholder="Redes sociais">
                </div> -->
                <div class="col-12">
                    <a href="https://afiliados.pipooh.com.br" style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;">Cadastrar agora</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 hidden-xs">
        <img src="../../assets/img/afiliado.png" width="90%" alt="">
    </div>
</div>
<app-footer></app-footer>