import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../_services/helper.service';
@Component({
  selector: 'app-empresarial',
  templateUrl: './empresarial.component.html',
  styleUrls: ['./empresarial.component.css']
})
export class EmpresarialComponent implements OnInit {
  empresa: any;
  nome: any;
  telefone: any;
  email: any;
  servico: any = "";
  volume: any;
  constructor( private toastr: ToastrService,
    private _helperService: HelperService) { }

  ngOnInit(): void {
  }

  enviar(){
    if (this.email == "" || this.email == null) {
      this.toastr.error('Email obrigatorio!');
      return;
    }
    if (this.telefone == "" || this.telefone == null) {
      this.toastr.error('Telefone obrigatorio!');
      return;
    }
    this.toastr.success('Solicitação enviada com sucesso!');
    this._helperService.emailEmpresarial({ "email": this.email, "nome": this.nome, "telefone": this.telefone, "empresa": this.empresa, "servico": this.servico, "volume": this.volume }).then(data => {
    })
  }

}
