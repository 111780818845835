<app-header></app-header>
<div class="modal-body" style=" margin: 15px; margin-top: 20px;">

  <div class="row d-flex justify-content-center">
    <div class="col-md-12 col-lg-6">
      <!-- <div class="alert alert-success" role="alert" style="text-align: center; text-transform: none;">
        <span
          style="color: #04cdcd; font-weight: 900; padding-left: 3px; padding-right: 3px; padding-bottom: 20px;">Você
          tem direito a
          {{this.bilhetesQuantidade}} bilhetes, selecione os números abaixo e finalize a compra</span>
      </div> -->
      <div class="row" style="padding: 8px; border: 10px solid #04cdcd;">
        <div class="row" style="width: 100%; display: block;">
          <h5 class="modal-title"
            style="text-align: center; color: #04cdcd; font-weight: 900; font-family: testfont; font-size: 25px;">CHÁ
            RIFA :</h5>
          <h5 class="modal-title"
            style="text-align: center; color: #04cdcd; font-weight: 900; font-family: testfont; font-size: 25px;">
            {{this.infoEvento.nomeFilho}}</h5>
        </div>
        <br>
        <br>
        <div class="row" style="padding-top: 20px; padding-bottom: 20px; width: 100%">
          <a onclick="document.getElementById('openModalPremio').click();" class="btn btn-primary"
            style="border-radius: 20px; color: white; background-color: #ea85de; border: 0; margin: auto; font-weight: 700;">VER
            OS PRÊMIOS DA RIFA</a><br>
        </div>
        <br>
        <div class="row" style="display: block; padding-left: 10px; font-size: 14px; font-weight: 700; width: 100%">
          Valor do Presente: <span
            style="color: #04cdcd; font-weight: 900; padding-left: 3px; padding-right: 3px;">R${{this.pedido.PedidoValor}}<span
              id="ContentPlaceHolder1_phValBilhete"></span></span>
        </div>
        <div class="row" style="display: block; padding-left: 10px; font-size: 14px; font-weight: 700; width: 100%">
          Valor do Bilhete: <span
            style="color: #04cdcd; font-weight: 900; padding-left: 3px; padding-right: 3px;">R${{this.infoEvento.valorBilhete}}<span
              id="ContentPlaceHolder1_phValBilhete"></span></span>
        </div>
        <div class="row" style="display: block; padding-left: 10px; font-size: 14px;  width: 100%; color: red;">
          Qtd de bilhetes que tenho direito: <span
            style="color: red; padding-left: 3px; padding-right: 3px;">{{bilhetesQuantidadeTotal}}<span
              id="ContentPlaceHolder1_phValBilhete"></span></span>
        </div>
        <br>
        <div class="row d-flex justify-content-center">
          <div *ngFor="let bilhete of this.rifa" class="col-2 col-lg-1" style="padding: 5px;margin: 2px 2px 2px;">
            <div *ngIf="bilhete.Status=='Livre' && this.bilhete.Carrinho!='Ativo'"
              (click)="this.bilhetesQuantidade==0 ? null :this.bilhete.Carrinho='Ativo'; this.bilhetesQuantidade==0 ? null : addBilhete(bilhete); "
              class="livre" style="text-align: center; border-radius: 100px; ">
              <p>{{bilhete.numBilhete}}</p>
            </div>
            <div *ngIf="bilhete.Status=='Livre'&&this.bilhete.Carrinho=='Ativo'"
              (click)="this.bilhete.Carrinho='Desativo'; removeBilhete(bilhete); " class="selecionado"
              style="text-align: center;border-radius: 100px; ">
              <p>{{bilhete.numBilhete}}</p>
            </div>
            <div *ngIf="bilhete.Status=='Bloqueado'" class="bloqueado"
              style="text-align: center;border-radius: 100px; ">
              <p>X</p>
            </div>

          </div>
        </div>
      </div>
      <div class="row" style="margin-bottom: 15px;">
        <div class="col">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-3" style="margin-left: -15px; margin-top: auto; margin-bottom: auto; max-width: 100px;">
              <img width="100%" src="https://img.pipooh.com.br/imagens/icons/warning_frete_carrinho.jpg">
            </div>
            <div class="col-8"
              style="color: gray; font-size: 13px; font-weight: 600; margin-left: 0px; text-align: left; line-height: 1.6; margin-bottom: 10px; margin-left: -7px">
              Após selecionar os bilhetes e gerar a compra, reservaremos seus bilhetes por até 4 horas. Após isso, caso
              não
              tenha efetuado o pagamento por cartão ou gerado o boleto, você perderá o direito aos seus bilhetes.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <a class="btn btn-secondary" style="color: white" href="/carrinho">Voltar</a>
        </div>
        <div class="col">
          <div class="btn btn-primary" style="float:right" (click)="this.finalizarRifa()">Finalizar</div>
        </div>
      </div>
    </div>
  </div>

</div>


<!-- Modais a partir daqui -->
<ng-template #modalPremio let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div *ngFor="let item of this.premiosRifa"
      style="background-color: #cc61ce00; border-radius: 12px; border: 1px solid #006dcba1; margin-top: 20px; padding-bottom: 6px;">
      <p style="margin-top: 5px; margin-bottom: 0px; margin-left: 10px;">
        Premio :
        <span id="ContentPlaceHolder1_Premio1" style="margin-left: 10px;">{{item.premio}}</span>
      </p>
    </div>



    <p id="rifaMsgOut" style="text-align: center;">
      <br>
      (Clique fora da caixa para fechar)
    </p>
  </div>
</ng-template>

<button id="openModalPremio" class="btn btn-lg btn-outline-primary" hidden="true" (click)="open(modalPremio)"
  data-target="#openModalPremio"></button>

<ng-template #modalErro let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="padding: 20px;text-align: center;">

    
      <h3>Você ganhou {{bilhetesQuantidadeTotal}} bilhetes <br> e faltam escolher {{bilhetesQuantidade}} bilhetes.</h3>
      <button class="btn btn-success2" (click)="modal.dismiss('Cross click')">Escolher agora</button>
      <button class="btn btn-success2" (click)="finalizarMesmoAssim()">Seguir desta forma</button>
  </div>
</ng-template>

<button id="openModalErro" class="btn btn-lg btn-outline-primary" hidden="true" (click)="open(modalErro)"
  data-target="#openModalErro"></button>



<ng-template #modalPremio let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div *ngFor="let item of this.premiosRifa"
      style="background-color: #cc61ce00; border-radius: 12px; border: 1px solid #006dcba1; margin-top: 20px; padding-bottom: 6px;">
      <p style="margin-top: 5px; margin-bottom: 0px; margin-left: 10px;">
        Premio :
        <span id="ContentPlaceHolder1_Premio1" style="margin-left: 10px;">{{item.premio}}</span>
      </p>
    </div>



    <p id="rifaMsgOut" style="text-align: center;">
      <br>
      (Clique fora da caixa para fechar)
    </p>
  </div>
</ng-template>

<button id="openModalPremio" class="btn btn-lg btn-outline-primary" hidden="true" (click)="open(modalPremio)"
  data-target="#openModalPremio"></button>

<app-footer></app-footer>