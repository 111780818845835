import { Injectable } from '@angular/core';
import { environmentAspNet } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private URLAspNet = `${environmentAspNet.api}`;



  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData)).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

  emailTroca(formData) {
    return this.postData(`${this.URLAspNet}helper/web/solicitacao/troca`, formData);
  }
  emailContato(formData) {
    return this.postData(`${this.URLAspNet}helper/web/solicitacao/contato`, formData);
  }
  emailParceiro(formData) {
    return this.postData(`${this.URLAspNet}helper/web/solicitacao/parceiro`, formData);
  }
  emailAfiliado(formData) {
    return this.postData(`${this.URLAspNet}helper/web/solicitacao/afiliado`, formData);
  }
  emailEmpresarial(formData) {
    return this.postData(`${this.URLAspNet}helper/web/solicitacao/empresarial`, formData);
  }
}
