import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  convidar(){
    var whatsTextPreview =
    "Olá! Estamos muito felizes e animados com o " + "Aniversário" + " de " + "Mariana" + "." +
    "\n\nNós estamos muito felizes, e queremos compartilhar da nossa alegria com você!" +
    "\n\nÉ dia *31/12/2023* às *18:00*, Buffet Cata Vento Park " +
    "\nSerá um dia muito especial." +
    "\n\n*Confirme sua presença, e caso queira presentear(opcional) Mariana, segue o link:*" +
    "\n\nhttps://pipooh.com.br/evento-pipooh?id=5544&evento=83459";
    // var whatsTextPreview =
    // "Olá! Estamos muito felizes e animados com o " + "Aniversário" + " de " + "Mariana" + "." +
    // "\n\nVocê foi convidado para o " + "Aniversário" + " através da Pipooh! Nós Mamãe e Papai estamos muito felizes, e queremos compartilhar da nossa alegria com você!" +
    // "\n\nVocê poderá presentear nossa " + "Mariana" + " até: " +
    // "31/12/2023" +
    // "\n\nO " + "Aniversário" + " será realizado no dia " +
    // "31/12/2023" + " " + "18:00" +
    // "\n\nO presente e a confirmação de presença estão disponíveis através do link:" +
    // "\n\nhttps://pipooh.com.br/evento-pipooh?id=5544&evento=83459";
    var whatsapp_url = "";
    // console.log(whatsapp_url);
    // console.log(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      //whatsapp_url = "whatsapp://send?text=" + whatsapp_url
      whatsapp_url =
        "whatsapp://send?text=" +
        encodeURIComponent(
          whatsTextPreview
        );

    } else {
      whatsapp_url =
        "https://web.whatsapp.com/send?text=" +
        encodeURIComponent(
          whatsTextPreview +
          " \n\n"

        );
    }
    window.open(whatsapp_url, "_blank");
  }
  navLink(link) {
    window.open(
      link,
      '_blank'
    );
  }

}
