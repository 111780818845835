import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PedidoIngressoService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;



  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

  inserirPedido(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/insert/pedido`, formData);
  }
  inserirPedidoUsuario(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/insert/pedido/usuario`, formData);
  }
  inserirPedidoSimplificado(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/insert/pedido/simplificado`, formData);
  }
  inserirPedidoUsuarioSimplificado(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/insert/pedido/usuario/simplificado`, formData);
  }
  inserirPedidoResgate(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/insert/pedido/resgate`, formData);
  }
  buscarIngresso(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/select/ingresso`, formData);
  }
  buscarIngressoPorCpf(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/select/ingresso/cpf`, formData);
  }
  buscarIngressoPorCpfSimplificado(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/select/ingresso/cpf/simplificado`, formData);
  }
  cancelarIngresso(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/update/ingresso/cancelar`, formData);
  }
  aCaminho(formData) {
    return this.postData(`${this.URLAspNet}pedidoIngresso/web/update/ingresso/aCaminho`, formData);
  }

}
