import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LembreteService } from 'src/app/_services/lembrete.service';

@Component({
  selector: 'app-lembrete-cancelado',
  templateUrl: './lembrete-cancelado.component.html',
  styleUrls: ['./lembrete-cancelado.component.css']
})
export class LembreteCanceladoComponent implements OnInit {

  constructor(
    private _lembreteService : LembreteService,
    private toastr : ToastrService,
  ) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.get('id');
    this._lembreteService.cancelarLembrete({"fkLembrete": myParam}).then(data =>{
      this.toastr.success("Lembrete cancelado, você será redirecionado em 5s")
      setTimeout(function () {
        window.location.href = "https://pipooh.com.br/"
    }, 5000);
    });
  }

}
