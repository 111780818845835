import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  items = [];

  constructor() { }

  addCarrinho(product) {
    this.items.push(product);
    localStorage.setItem('carrinho', JSON.stringify(this.items));

  }
  getLocalCarrinho() {
    if (JSON.parse(localStorage.getItem('carrinho')) == null) {
      return this.items = [];
    }
    return this.items = JSON.parse(localStorage.getItem('carrinho'));
  }

  getItems() {
    return this.items;
  }
  removeCarrinho(product) {
    this.items.forEach((item, index) => {
      if (item === product) this.items.splice(index, 1);
    });
    if (this.items.length == 0) {
      window.location.href = "/evento-pipooh?id=" + localStorage.getItem('pipoohMamae') + "&evento=" + localStorage.getItem('pipoohEvento');
    }
    localStorage.setItem('carrinho', JSON.stringify(this.items));
  }
  clearCart() {
    this.items = [];
    return this.items;
  }
}
