<img src="https://img.pipooh.com.br/{{ evento.bannerDesktop }}" class="hidden-xs" *ngIf="evento.bannerDesktop"
    width="100%" alt="">
<img src="https://img.pipooh.com.br/{{ evento.bannerMobile }}" class="visible-xs" *ngIf="evento.bannerDesktop"
    width="100%" alt="">
<div class="row justify-content-center" style=" margin-top: 10px; padding: 20px;" *ngIf="!evento.bannerDesktop">
    <div class="col-lg-6" style="padding: 50px; text-align: center;">
        <div class="hidden-xs">
            <h2 class="titulo">
                {{ evento.nome }}
            </h2>
            <div [innerHTML]="evento.descricao" class="bannerParagraph"></div>
            <div class="row">
                <div class="col-lg-12" style="margin-bottom: 10px">
                    <a class="btn btn-yellow" (click)="open(modalRegulamento)">Saiba
                        mais</a>
                </div>
            </div>
        </div>
        <img style="max-width: 100%; max-height: 400px; min-width: 50%" class="imagemPerfil visible-xs"
            src="https://img.pipooh.com.br/{{ evento.imagem }}" alt="" />

    </div>
    <div class="col-lg-6" style="padding: 25px; text-align: center">
        <img style="max-width: 100%; max-height: 400px; min-width: 50%" class="imagemPerfil hidden-xs"
            src="https://img.pipooh.com.br/{{ evento.imagem }}" alt="" />
        <div class="visible-xs">
            <h2 class="titulo">
                {{ evento.nome }}
            </h2>
            <div [innerHTML]="evento.descricao" class="bannerParagraph"></div>
            <div class="row">
                <div class="col-lg-12" style="margin-bottom: 10px">
                    <a class="btn btn-yellow" (click)="open(modalRegulamento)">Saiba
                        mais</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row justify-content-center" style="margin-bottom: 15px;  padding: 20px;">
    <div class="col-lg-6" style=" text-align: center; ">
        <div class="visible-xs" *ngIf="evento.bannerDesktop">
            <h2 class="titulo">
                {{ evento.nome }}
            </h2>
            <div [innerHTML]="evento.descricao" class="bannerParagraph"></div>
            <div class="row">
                <div class="col-lg-12" style="margin-bottom: 10px; color: #01b1af;">
                    <a (click)="open(modalRegulamento)">Saiba
                        mais</a>
                </div>
            </div>
        </div>
        <div class="hidden-xs" *ngIf="evento.bannerDesktop">
            <h2 class="titulo">
                {{ evento.nome }}
            </h2>
            <div [innerHTML]="evento.descricao" class="bannerParagraph"></div>
            <div class="row">
                <div class="col-lg-12" style="margin-bottom: 10px">
                    <a class="btn btn-yellow" (click)="open(modalRegulamento)">Saiba
                        mais</a>
                </div>
            </div>
        </div>
        <br>
        <h2 class="titulo hidden-xs">
            Organizador
        </h2>
        <p class="bannerParagraph hidden-xs">
            {{evento.nomeOrganizador}} <br>
            {{evento.emailOrganizador}}
        </p>
        <div class="row" style="text-align: center;margin-top: 5px;">
            <div class="col-lg-6">
                <p *ngIf="evento.facebook" class="hidden-xs"><i class="fab fa-facebook"
                        style="color: #1877F2; font-size: 30px;" aria-hidden="true"></i> <a
                        href="https://facebook.com/{{evento.facebook}}" target="_blank">
                        {{evento.facebook}}</a></p>
            </div>
            <div class="col-lg-6">
                <p *ngIf="evento.instagram" class="hidden-xs"><i class="fab fa-instagram"
                        style="font-size: 30px; color: #D37245" aria-hidden="true"></i> <a
                        href="https://instagram.com/{{evento.instagram}}" target="_blank">
                        {{evento.instagram}}</a></p>
            </div>
            <div class="col-lg-12">
                <p *ngIf="evento.site" class="hidden-xs"><i class="flaticon-link" style="color: #a1ff03;"></i>
                    <a href="{{evento.site}}" target="_blank">
                        {{evento.site}}</a>
            </div>
        </div>



    </div>
    <div class="col-lg-1 hidden-xs" style="padding: 25px; text-align: center"></div>
    <div class="col-lg-3 col-md-12"
        style="padding: 25px; border: 1px solid #01b1af; border-radius: 20px; margin-left: 15px; margin-right: 15px;">


        <div class="row" style="text-align: center;">
            <div class="col-12" style="margin-bottom: 15px;">
                <select name="" class="form-control" id="" [(ngModel)]="dataSelecionada" (change)="alterandoData()">
                    <option value="">Datas</option>
                    <option value="{{formatDateBD(data.data)}}" *ngFor="let data of datas">{{formatDate(data.data)}}</option>
                </select>
            </div>
            <div class="col-12" style="margin-bottom: 15px;" *ngIf="temas.length > 0">
                <select name="" class="form-control" id="" [(ngModel)]="temaSelecionada" (change)="alterandoTema()">
                    <option value="">Sessão</option>
                    <option value="{{tema.tema}}" *ngFor="let tema of temas">{{tema.tema}}</option>
                </select>
            </div>
            <div class="col-12" *ngIf="sessoes.length > 0">
                <select name="" class="form-control" id="" (change)="alterandoSessao()" [(ngModel)]="sessaoSelecionada">
                    <option value="">Horário</option>
                    <option value="{{sessao.id}}" *ngFor="let sessao of sessoes">{{
                        sessao.horaInicio.substr(0,5) }} às {{sessao.horaFim.substr(0,5)}}</option>
                </select>
            </div>

        </div>
        <br>
        <p *ngIf="sessaoSelecionada" class="bannerParagraph" style="font-size: 13px;">Local:
            {{sessoesSelecionada[0].local}}</p>

        <div class="row" style="padding: 10px;margin-top: 15px;">
            <div class="col-6" style="text-align: center;">
                <label class="titulo" for="" *ngIf="evento.cobranca == true">{{evento.id == 6 ? 'Criança' : 'Inteira'}}
                    <br>
                    R${{formatDecimal(evento.valor)}}</label>
                <label class="titulo" for="" *ngIf="evento.cobranca == false">Ingresso <br>
                </label>
            </div>
            <div class="col-6">
                <div class="pro-qty" style="border-radius: 25px">
                    <span  (click)="changeQtdIngresso('down', 'inteira')" class="dec qtybtn">-</span>
                    <input [ngStyle]="{background: cor}" disabled [(ngModel)]="inteira" type="text" />
                    <span  (click)="changeQtdIngresso('up', 'inteira')" class="inc qtybtn">+</span>
                </div>
            </div>
        </div>
        <div class="row" style="padding: 10px;" *ngIf="evento.cobranca == true && evento.meia_entrada == 1">
            <div class="col-6" style="text-align: center;">
                <label class="titulo" *ngIf="evento.meia_entrada == 1" for="">{{evento.id == 6 ? 'Adulto' :
                    'Meia-entrada'}}
                    R${{formatDecimal(evento.valor_meia)}}</label>
            </div>
            <div class="col-6">
                <div class="pro-qty" style="border-radius: 25px">
                    <span (click)="changeQtdIngresso('down', 'meia')" class="dec qtybtn">-</span>
                    <input [ngStyle]="{background: cor}" disabled [(ngModel)]="meia" type="text" />
                    <span (click)="changeQtdIngresso('up', 'meia')" class="inc qtybtn">+</span>
                </div>
            </div>
        </div>
        <p *ngIf="sessaoSelecionada != '' && (sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos > 0) && fkEvento != 5 "
            class="bannerParagraph" style="font-size: 13px;">
            {{sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos >
            0 ?
            sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos > 1 ?
            sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos + ' ingressos disponíveis' :
            sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos + ' ingresso disponível'
            : "Nenhum ingresso disponível"}}
        </p>
        <div *ngIf="sessaoSelecionada != '' &&
        (sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos <= 0) ">
            <h6 *ngIf="sessoesSelecionada[0].fila == true" style="color: red; text-align: center;">
                INGRESSOS ESGOTADOS. <br>
                <span style="font-size: 13px; color: black">
                    Você entrará na fila de espera, caso alguém desista seu ingresso ficará disponível.
                </span>
                <br>

                <span style="font-size: 13px; color: black">
                    Total de ingressos na fila: {{sessoesSelecionada[0].ingressosFila ?
                    sessoesSelecionada[0].ingressosFila : '0'}}
                    <br></span>
            </h6>
            <h5 class="bannerParagraph" style="color: red" *ngIf="sessoesSelecionada[0].fila != true ">
                Nenhum ingresso disponível
            </h5>

        </div>
        <!-- <p  >
            {{sessoesSelecionada[0].ingressos}}
            {{sessoesSelecionada[0].fila == true ? "Fila de espera" : "Nenhum ingresso disponível"}}
        </p> -->

        <p style="color: rgb(255, 120, 120);"
            *ngIf="sessoesSelecionada[0].limitadoCpf == true && sessoesSelecionada[0].quantidadeCpf > 0">
            Evento limitado à {{sessoesSelecionada[0].quantidadeCpf > 1 ? sessoesSelecionada[0].quantidadeCpf + "
            ingressos" : sessoesSelecionada[0].quantidadeCpf + " ingresso"}} por CPF
        </p>
        <h2 class="titulo" *ngIf="evento.cobranca == true">Total R${{total != 0 ? formatDecimal(total) : '0.00'}}</h2>
        <h2 class="titulo" *ngIf="evento.cobranca == false">Entrada Gratuita</h2>

        <!-- <button class="btn btn-success2" *ngIf="ingressos.length == 0 && sessoesSelecionada.length == 0" disabled (click)="open(modalCpfDescontoPipooh)" style="width: 100%;">Comprar</button> -->
        <button class="btn btn-success2" *ngIf="sessaoSelecionada != '' && (inteira > 0 || meia > 0)"
            (click)="open(modalCpfDescontoPipooh); this.usuario = []; this.cpfValido = false;"
            style="width: 100%;">{{evento.botao}}</button>
        <button class="btn btn-success2" disabled *ngIf="sessaoSelecionada == '' || (inteira == 0 && meia == 0)"
            (click)="open(modalCpfDescontoPipooh); this.usuario = []; this.cpfValido = false;"
            style="width: 100%;">{{evento.botao}}</button>
        <div style="text-align: center; margin-top: 15px; cursor: pointer;"
            *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true">
            <p>Descontos clientes pipooh <i class="far fa-question-circle"
                    style="color: #01b1af; text-align: center"></i></p>
            <img style="text-align: center;" src="assets/img/logopipooh.png" width="50%" alt="">
        </div>

    </div>
    <div class="col-lg-1" style="padding: 25px; text-align: center">
        <br>
        <h2 class="titulo visible-xs">
            Organizador
        </h2>
        <p class="bannerParagraph visible-xs">
            {{evento.nomeOrganizador}} <br>
            {{evento.emailOrganizador}}
        </p>

        <p *ngIf="evento.facebook" class="visible-xs"><i class="fab fa-facebook"
                style="color: #1877F2; font-size: 30px;" aria-hidden="true"></i> <a
                href="https://facebook.com/{{evento.facebook}}" target="_blank">
                {{evento.facebook}}</a></p>
        <p *ngIf="evento.instagram" class="visible-xs"><i class="fab fa-instagram"
                style="font-size: 30px; color: #D37245" aria-hidden="true"></i> <a
                href="https://instagram.com/{{evento.instagram}}" target="_blank">
                {{evento.instagram}}</a></p>
        <p *ngIf="evento.site" class="visible-xs"><i class="flaticon-link" style="color: #a1ff03;"></i>
            <a href="{{evento.site}}" target="_blank">
                {{evento.site}}</a>
        </p>
    </div>
</div>

<!-- modais -->

<ng-template #modalLogin let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center; padding: 20px;">
        <form (ngSubmit)="onSubmitFormLogin()" [formGroup]="formLogin">
            <label for="">Entrar {{evento.cadastro == 'Pipooh' ? 'na PIPOOH' : ''}}</label>
            <mat-form-field style="width: 100%;" class="example-full-width">
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" id="email" [errorStateMatcher]="matcher"
                    placeholder="Ex. pat@example.com">
                <mat-error *ngIf="formSimples.get('email').errors?.email && formSimples.get('email')?.touched">
                    Por favor digite um e-mail válido
                </mat-error>
                <mat-error *ngIf="formSimples.get('email')?.errors  && (formSimples.get('email')?.touched )">
                    E-mail <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field style="width: 100%;" class="example-full-width">
                <mat-label>Senha</mat-label>
                <input type="password" matInput formControlName="senha" id="senha" [errorStateMatcher]="matcher"
                    placeholder="Senha">
                <mat-error *ngIf="formSimples.get('senha')?.errors  && (formSimples.get('senha')?.touched )">
                    Senha <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>
            <button class="btn btn-success2" type="submit" [disabled]="loading"
                [ngClass]="{'m-loader m-loader--right m-loader--light': loading }"
                style="margin-top: 20px;width: 100%;">Entrar</button>
        </form>
        <div class="" *ngIf="evento.cadastro == 'Pipooh'">
            <p>Esqueceu a senha? <a href=""> Clique aqui</a></p>
            <p>Não possuí conta? <a style="color:#01b1af" (click)="modal.dismiss('Cross click');open(modalCadastrar)">
                    Clique aqui</a></p>
        </div>

    </div>
</ng-template>
<ng-template #modalRegulamento let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center; padding: 20px;">
        <!-- <p>{{evento.regulamento}}</p> -->
        <div [innerHTML]="evento.regulamento"></div>
    </div>
</ng-template>
<ng-template #modalCompraRealizada let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center; padding: 20px;">
        <img src="https://img.pipooh.com.br/{{ evento.imgInfo }}" style="width: 100%;" *ngIf="evento.imgInfo" alt="">
        <div [innerHTML]="sessoesSelecionada[0].obs" *ngIf="sessoesSelecionada[0].obs"></div>
        <h3>Parabéns, ingressos gerados com sucesso!</h3>
        <p *ngIf="evento.cadastro == 'Pipooh'">Confira agora os ingressos no seu e-mail cadastrado ou pelo botão abaixo
        </p>
        <p *ngIf="evento.cadastro != 'Pipooh'">Confira agora os ingressos no seu e-mail cadastrado ou pelo botão abaixo:
        </p>
        <a *ngIf="evento.cadastro != 'Pipooh'" class="btn btn-success2"
            href="https://sistema.zull.com.br/ingresso-estabelecimento?id={{ingressosComprados[0].codigo_pedido}}">Ver
            Ingressos</a>
        <a *ngIf="evento.cadastro == 'Pipooh'" class="btn btn-success2"
            href="https://pipooh.com.br/ingresso-estabelecimento?id={{ingressosComprados[0].codigo_pedido}}">Ver
            Ingressos</a>
        <!-- <div *ngIf="sessaoSelecionada != '' &&
        (sessoesSelecionada[0].ingressos - sessoesSelecionada[0].ingressosVendidos <= 0) ">
            <h6 *ngIf="sessoesSelecionada[0].fila == true" style="color: red; text-align: center;">
                
                <span style="color: black">
                    Você está na <b style="color: red">fila de espera</b> fila de espera, caso alguém desista seu ingresso ficará disponível.
                </span>
                <br>

                <span style="color: black">
                    Sua posição na fila: {{sessoesSelecionada[0].ingressosFila  ? sessoesSelecionada[0].ingressosFila + 1  : 1}}
                    <br></span>
            </h6>
        </div>
        <div *ngFor="let ingresso of ingressosComprados" style="margin-bottom: 10px;">
            <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                [value]="ingresso.codigo"></ngx-qrcode>
            <br>
            <p>{{ingresso.nome}} - {{ingresso.codigo}}</p>
            <hr>
        </div> -->
    </div>
</ng-template>

<ng-template #modalCpfDescontoPipooh let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center; padding: 20px;">
        <mat-form-field style="width: 90%;" class="example-form-field">
            <mat-label>CPF</mat-label>
            <input matInput [(ngModel)]="cpf" (keyup.enter)="buscarCpf()" type="text" mask="000.000.000-00">
            <mat-spinner *ngIf="loading" matSuffix diameter="16" style="display: inline-flex"></mat-spinner>
            <button *ngIf="!loading" matSuffix mat-icon-button aria-label="Clear" (click)="buscarCpf()">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </mat-form-field>
        <div class="ingressoDisponivel" *ngIf="msgIngressoDisponivel == true">
            <p style="color: rgb(255, 120, 120);">
                Evento limitado à {{sessoesSelecionada[0].quantidadeCpf > 1 ? sessoesSelecionada[0].quantidadeCpf + "
                ingressos" : sessoesSelecionada[0].quantidadeCpf + " ingresso"}} por CPF
            </p>
            <p style="color: rgb(255, 120, 120);">
                {{ingressosDisponiveis > 1 ? ingressosDisponiveis + " ingressos disponíveis" : ingressosDisponiveis + "
                ingresso disponível"}} para o CPF selecionado
            </p>
        </div>
        <div class="comUsuario" *ngIf="usuario.length > 0 && evento.cadastro == 'Pipooh'">
            <p style="color: rgb(255, 120, 120);" *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true">
                Parabéns, você é cliente PIPOOH
                {{usuario[0].status == 1 &&
                usuario.length > 0 ? 'Assinante' : usuario[0].categoria}}.
                <b *ngIf="usuario[0].categoria == 'Baby' && usuario[0].status != 1">{{evento.baby}}%OFF</b>
                <b *ngIf="usuario[0].categoria == 'Top' && usuario[0].status != 1">{{evento.top}}%OFF</b>
                <b *ngIf="usuario[0].categoria == 'Super' && usuario[0].status != 1">{{evento.super}}%OFF</b>
                <b *ngIf="usuario[0].categoria == 'Vip' || usuario[0].status == 1">{{evento.vip_assinante}}%OFF</b>
            </p>
            <h4 class="titulo" style="text-decoration: line-through"
                *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true">R$
                {{formatDecimal(total)}}</h4>
            <h4 class="titulo" *ngIf="evento.desconto_pipooh == 0 && evento.cobranca == true">R$
                {{formatDecimal(total)}}</h4>
            <h2 class="titulo" *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true">R$ {{formatDecimal(total -
                (total * desconto ))}}</h2>
            <h2 class="titulo" *ngIf="evento.cobranca == false">Entrada Gratuita</h2>
            <button class="btn btn-success2" (click)="modal.dismiss('Cross click');open(modalLogin)"
                style="width: 100%;">Próximo</button>
        </div>
        <div class="comUsuario" *ngIf="usuario.length > 0 && evento.cadastro == 'Simplificado'">
            <h2 class="titulo" *ngIf="evento.cobranca == false">Entrada Gratuita</h2>
            <button class="btn btn-success2" (click)="modal.dismiss('Cross click');open(modalLogin)"
                style="width: 100%;">Próximo</button>
        </div>
        <div class="semUsuario" *ngIf="usuario.length == 0 && cpfValido == true">
            <p style="color: rgb(255, 120, 120);"
                *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true && evento.cadastro == 'Pipooh'">Ahhh
                você ainda não é cliente
                Pipooh.</p>
            <h2 class="titulo" *ngIf="evento.cobranca == true">R$ {{formatDecimal(total)}}</h2>
            <h2 class="titulo" *ngIf="evento.cobranca == false">Entrada Gratuita</h2>
            <p style="color: rgb(255, 120, 120);"
                *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true && evento.cadastro == 'Pipooh'">Pague
                R$ {{formatDecimal(total -
                (total * descontoFake ))}} sendo um
                cliente PIPOOH .</p>

            <!-- <button class="btn btn-success2" (click)="modal.dismiss('Cross click');open(modalComprar)"
                style="width: 100%;">Próximo</button> -->
            <button class="btn btn-success2"
                *ngIf="evento.desconto_pipooh == 1 && evento.cobranca == true && evento.cadastro == 'Pipooh'"
                (click)="modal.dismiss('Cross click');open(modalCadastrar)" style="width: 100%;">Próximo com
                desconto</button>
            <button class="btn btn-success2" *ngIf="evento.cobranca == false && evento.cadastro == 'Pipooh'"
                (click)="modal.dismiss('Cross click');open(modalCadastrar)" style="width: 100%;">Próximo</button>
            <button class="btn btn-success2" *ngIf="evento.cobranca == false && evento.cadastro == 'Simplificado'"
                (click)="modal.dismiss('Cross click');open(modalCadastrarSimplificado)"
                style="width: 100%;">Próximo</button>
        </div>

    </div>
</ng-template>

<ng-template #modalComprar let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center; padding: 20px;">
        <form class="example-form" (ngSubmit)="onSubmitFormSimples()" [formGroup]="formSimples">
            <label for="">Dados do comprador</label>
            <p>Os ingressos serão enviados ao e-mail do comprador.</p>
            <div class="row" style="margin-bottom: 25px;">
                <div class="col-lg-4">
                    <mat-form-field style="width: 100%;" class="example-full-width">
                        <mat-label>Nome</mat-label>
                        <input matInput formControlName="nome">
                        <mat-error *ngIf="formSimples.get('nome')?.errors  && (formSimples.get('nome')?.touched )">
                            Nome <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <mat-form-field style="width: 100%;" class="example-full-width">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" id="email" [errorStateMatcher]="matcher"
                            placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="formSimples.get('email').errors?.email && formSimples.get('email')?.touched">
                            Por favor digite um e-mail válido
                        </mat-error>
                        <mat-error *ngIf="formSimples.get('email')?.errors  && (formSimples.get('email')?.touched )">
                            E-mail <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4">
                    <mat-form-field style="width: 100%;" class="example-full-width">
                        <mat-label>Celular</mat-label>
                        <input type="tel" matInput formControlName="celular" id="celular" placeholder="00000000000">
                        <mat-error
                            *ngIf="formSimples.get('celular')?.errors  && (formSimples.get('celular')?.touched )">
                            Celular <strong>inválido</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-accordion *ngFor="let ingresso of ingressos; let i = index;">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Ingresso {{i + 1}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-form-field style="width: 100%;" class="example-full-width">
                        <mat-label>Nome</mat-label>
                        <input matInput [(ngModel)]="ingresso.nome" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;" class="example-full-width">
                        <mat-label>Data de nascimento*</mat-label>
                        <!-- <input type="text" matInput [(ngModel)]="ingresso.dataNascimento"
                            [ngModelOptions]="{standalone: true}" [dropSpecialCharacters]="false" mask="00/00/0000"
                            placeholder="DD/MM/AAAA"> -->
                        <input type="date" class="form-control" matInput [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="ingresso.dataNascimento">
                    </mat-form-field>
                </mat-expansion-panel>
            </mat-accordion>

            <button class="btn btn-success2" type="submit" [disabled]="loading"
                [ngClass]="{'m-loader m-loader--right m-loader--light': loading }"
                style="margin-top: 20px;width: 100%;">{{evento.botao}}</button>
            <button class="btn btn-success2" type="button" (click)="comprarComCreditosPipooh()" [disabled]="loading"
                [ngClass]="{'m-loader m-loader--right m-loader--light': loading }" *ngIf="evento.cobranca == 1"
                style="margin-top: 20px;width: 100%;">Comprar com créditos Pipooh</button>
        </form>

    </div>
</ng-template>

<ng-template #modalCadastrar let-modal>
    <div class="modal-header">
        <h3 style="float: left;color: red;" *ngIf="fila == true">Fila de espera</h3>
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <p>Você não é cliente Pipooh. Abra sua conta:</p>
            <form (ngSubmit)="onSubmitFormCadastrar()" [formGroup]="formCadastrar">
                <select class="form-control" formControlName="sexo" id="sexo">
                    <option value="mamãe">Mamãe</option>
                    <option value="papai">Papai</option>
                </select>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('sexo')?.errors  && (formCadastrar.get('sexo')?.touched  || submitted)">
                    Sexo obrigatório
                </div>
                <br>

                <input type="text" class="form-control" placeholder="Nome Completo*" formControlName="nome" id="nome">
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('nome')?.errors  && (formCadastrar.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>
                <input type="text" class="form-control" placeholder="E-mail*" formControlName="email" id="email">
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('email').errors?.required  && (formCadastrar.get('email')?.touched  || submitted)">
                    E-mail obrigatório
                </div>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('email').errors?.email && formCadastrar.get('email')?.touched">
                    E-mail inválido
                </div>
                <br>
                <input type="text" mask="000.000.000-00" class="form-control" placeholder="CPF *" formControlName="cpf"
                    id="cpf">
                <div class="error" style="color: red;"
                    *ngIf="(formCadastrar.get('cpf')?.errors && !formCadastrar.get('cpf').errors?.minlength) && submitted">
                    CPF obrigatório
                </div>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('cpf').errors?.minlength && (formCadastrar.get('cpf')?.touched  || submitted)">
                    CPF precisa conter 11 dígitos
                </div>
                <br>
                <input type="text" class="form-control" placeholder="Celular*" formControlName="celular" id="celular">
                <div class="error" style="color: red;"
                    *ngIf="(formCadastrar.get('celular')?.errors && !formCadastrar.get('celular')?.errors?.minlength  )  &&  submitted">
                    Celular inválido
                </div>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('celular')?.errors?.minlength  && (formCadastrar.get('celular')?.touched || submitted)">
                    Celular precisa conter 11 dígitos
                </div>
                <br>
                <input type="password" class="form-control" placeholder="Senha*" formControlName="senha" id="senha">
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('senha')?.errors  && (formCadastrar.get('senha')?.touched || submitted)">
                    Senha obrigatório
                </div>
                <br>
                <input type="password" class="form-control" formControlName="senhaR" placeholder="Confirmar senha*"
                    id="senhaR">
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('senhaR')?.errors  && (formCadastrar.get('senhaR')?.touched || submitted)">
                    Confirmar Senha obrigatório
                </div>
                <br>
                <mat-radio-group aria-label="Select an option" formControlName="tipo" id="tipo">
                    <mat-radio-button value="gestante">Gestante &nbsp;</mat-radio-button>
                    <mat-radio-button value="criancaNasceu">Criança já nasceu</mat-radio-button>
                </mat-radio-group>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrar.get('tipo')?.errors  && (formCadastrar.get('tipo')?.touched  || submitted)">
                    Tipo obrigatório
                </div>
                <p style="margin-bottom:0px;" *ngIf="formCadastrar.value.tipo == 'gestante'"><strong>
                        Quantas semanas está:
                    </strong>
                </p>
                <div class="" *ngIf="formCadastrar.value.tipo == 'gestante'">
                    <select class="form-control" formControlName="semana" id="semana">
                        <option>Selecione a semana</option>
                        <option *ngFor="let sem of semanas" value="{{sem}}">{{sem}}</option>
                    </select>
                </div>
                <p style="margin-bottom:0px;" *ngIf="formCadastrar.value.tipo == 'criancaNasceu'">Data de
                    nascimento filho mais novo:</p>
                <div class="form-group m-form__group" *ngIf="formCadastrar.value.tipo == 'criancaNasceu'">
                    <input class="form-control m-input" type="date" (change)="dataNascimentoIngresso()"
                        formControlName="dataNascimentoCrianca" id="dataNascimentoCrianca" />
                </div>
                <br>
                <br>
                <mat-accordion *ngFor="let ingresso of ingressos; let i = index;">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Ingresso {{i + 1}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field style="width: 100%;" class="example-full-width">
                            <mat-label>Nome</mat-label>
                            <input matInput [(ngModel)]="ingresso.nome" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field style="width: 100%;" class="example-full-width">
                            <mat-label>Data de nascimento*</mat-label>
                            <!-- <input type="text" matInput [(ngModel)]="ingresso.dataNascimento"
                                [ngModelOptions]="{standalone: true}" [dropSpecialCharacters]="false" mask="00/00/0000"
                                placeholder="DD/MM/AAAA"> -->
                            <input type="date" class="form-control" matInput [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="ingresso.dataNascimento">
                        </mat-form-field>
                    </mat-expansion-panel>
                </mat-accordion>
                <br><br>
                <label class="m-checkbox m-checkbox--focus">
                    <input type="checkbox" formControlName="termos" id="termos" /> Eu concordo com os
                    <a href="https://img.pipooh.com.br/imagens/pdf/5e04cf7bbfb6de5a8d3255bc94f2885c.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Termos de uso e condições.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrar.get('termos')?.errors  && submitted">
                    Termos de uso obrigatório
                </div>
                <br>
                <label class="m-checkbox m-checkbox--focus">
                    <input type="checkbox" formControlName="politica" id="politica" /> Eu
                    concordo com as
                    <a href="https://img.pipooh.com.br/imagens/pdf/da192f01bd138c4df9338dd70b02f1bf.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Política de privacidade.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrar.get('politica')?.errors  &&  submitted">
                    Política de privacidade obrigatório
                </div>
                <div style="text-align: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="loading"
                        [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        {{evento.botao}}
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>

<ng-template #modalEnquete let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center; padding: 20px;">
        <h6>Responda à enquete para finalizar</h6>
        <div *ngFor="let enquete of enquetes; let i = index" style="margin-bottom: 10px;">
            <label for="">{{enquete.pergunta}}</label>

            <select name="" *ngIf="enquete.tipo == 'Múltipla'" class="form-control" id=""
                [(ngModel)]="enquete.resposta">


                <option value="{{op.opcao}}" *ngFor="let op of enquete.opcoes">{{op.opcao}}</option>
            </select>
            <ul class="categorias" style="list-style: none;" *ngIf="enquete.tipo == 'Caixa'">
                <li class="mb-1" *ngFor="let op of enquete.opcoes">
                    <mat-checkbox [(ngModel)]="op.check">
                    </mat-checkbox><span style="color: #01b1af;"> {{op.opcao}}</span>
                </li>

            </ul>
            <input type="text" class="form-control" *ngIf="enquete.tipo == 'Parágrafo'" [(ngModel)]="enquete.resposta">
        </div>
        <button class="btn btn-primary" type="submit" [disabled]="loading" (click)="finalizarComprarIngressos()"
            [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
            Finalizar
        </button>
    </div>
</ng-template>

<ng-template #modalCadastrarSimplificado let-modal>
    <div class="modal-header">
        <h3 style="float: left;color: red;" *ngIf="fila == true">Fila de espera</h3>
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <p>Finalizar cadastro:</p>
            <form (ngSubmit)="onSubmitFormCadastrarSimplificado()" [formGroup]="formCadastrarSimplificado">

                <input type="text" class="form-control" placeholder="Nome Completo*" formControlName="nome" id="nome">
                <div class="error" style="color: red;"
                    *ngIf="formCadastrarSimplificado.get('nome')?.errors  && (formCadastrarSimplificado.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>
                <input type="text" class="form-control" placeholder="E-mail*" formControlName="email" id="email">
                <div class="error" style="color: red;"
                    *ngIf="formCadastrarSimplificado.get('email').errors?.required  && (formCadastrarSimplificado.get('email')?.touched  || submitted)">
                    E-mail obrigatório
                </div>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrarSimplificado.get('email').errors?.email && formCadastrarSimplificado.get('email')?.touched">
                    E-mail inválido
                </div>
                <br>
                <input type="text" mask="000.000.000-00" class="form-control" placeholder="CPF *" formControlName="cpf"
                    id="cpf">
                <div class="error" style="color: red;"
                    *ngIf="(formCadastrarSimplificado.get('cpf')?.errors && !formCadastrarSimplificado.get('cpf').errors?.minlength) && submitted">
                    CPF obrigatório
                </div>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrarSimplificado.get('cpf').errors?.minlength && (formCadastrarSimplificado.get('cpf')?.touched  || submitted)">
                    CPF precisa conter 11 dígitos
                </div>
                <br>
                <input type="text" class="form-control" placeholder="Celular*" formControlName="celular" id="celular">
                <div class="error" style="color: red;"
                    *ngIf="(formCadastrarSimplificado.get('celular')?.errors && !formCadastrarSimplificado.get('celular')?.errors?.minlength  )  &&  submitted">
                    Celular inválido
                </div>
                <div class="error" style="color: red;"
                    *ngIf="formCadastrarSimplificado.get('celular')?.errors?.minlength  && (formCadastrarSimplificado.get('celular')?.touched || submitted)">
                    Celular precisa conter 11 dígitos
                </div>
                <br>
                <mat-accordion *ngFor="let ingresso of ingressos; let i = index;">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Ingresso {{i + 1}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field style="width: 100%;" class="example-full-width">
                            <mat-label>Nome</mat-label>
                            <input matInput [(ngModel)]="ingresso.nome" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field style="width: 100%;" class="example-full-width">
                            <mat-label>Data de nascimento*</mat-label>
                            <!-- <input type="text" matInput [(ngModel)]="ingresso.dataNascimento"
                                [ngModelOptions]="{standalone: true}" [dropSpecialCharacters]="false" mask="00/00/0000"
                                placeholder="DD/MM/AAAA"> -->
                            <input type="date" class="form-control" matInput [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="ingresso.dataNascimento">
                        </mat-form-field>
                    </mat-expansion-panel>
                </mat-accordion>
                <br><br>
                <label class="m-checkbox m-checkbox--focus" *ngIf="evento.cadastro == 'Pipooh'">
                    <input type="checkbox" formControlName="termos" id="termos" /> Eu concordo com os
                    <a href="https://img.pipooh.com.br/imagens/pdf/5e04cf7bbfb6de5a8d3255bc94f2885c.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Termos de uso e condições.
                    </a>
                    <span></span>
                </label>
                <label class="m-checkbox m-checkbox--focus" *ngIf="evento.cadastro == 'Simplificado' && evento.termos">
                    <input type="checkbox" formControlName="termos" id="termos" /> Eu concordo com os
                    <a href="{{evento.termos}}" target="_blank" class="m-link m-link--focus">
                        Termos de uso e condições.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrarSimplificado.get('termos')?.errors  && submitted">
                    Termos de uso obrigatório
                </div>
                <br>
                <label class="m-checkbox m-checkbox--focus" *ngIf="evento.cadastro == 'Pipooh'">
                    <input type="checkbox" formControlName="politica" id="politica" /> Eu
                    concordo com as
                    <a href="https://img.pipooh.com.br/imagens/pdf/da192f01bd138c4df9338dd70b02f1bf.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Política de privacidade.
                    </a>
                    <span></span>
                </label>
                <label class="m-checkbox m-checkbox--focus"
                    *ngIf="evento.cadastro == 'Simplificado' && evento.politica">
                    <input type="checkbox" formControlName="politica" id="politica" /> Eu
                    concordo com as
                    <a href="{{evento.politica}}" target="_blank" class="m-link m-link--focus">
                        Política de privacidade.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrarSimplificado.get('politica')?.errors  &&  submitted">
                    Política de privacidade obrigatório
                </div>
                <div style="text-align: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="loading"
                        [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        {{evento.botao}}
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>