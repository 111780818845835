import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { TrackService } from './_services/track.service';

declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PipoohAngularWeb';

  globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

  idMae: any;
  linkUrl: any;
  constructor(private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _trackService: TrackService,
  ) {
    _router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        fbq('track', 'PageView');
      }
    })
  }

  ngOnInit() {
    this.trackingpage();
    
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        //Helpers.setLoading(true);
        //Helpers.bodyClass(this.globalBodyClass);
      }
      if (route instanceof NavigationEnd) {
        //Helpers.setLoading(false);
      }
    });
  }

  trackingpage() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.idMae = params['id'];
      this.linkUrl = params['url'];

      if (this.idMae != undefined && this.linkUrl != undefined) {

       var a= this._trackService.trackUrl({ "idMae": this.idMae, "linkUrl": this.linkUrl }).then(data => {
        })
      }
    });
  }
}
