import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../_services/helper.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.css']
})
export class ParceiroComponent implements OnInit {
  //Modais
  @ViewChild('modalConfirmacao') modalConfirmacao;
  closeResult = '';
  //
  nome: any;
  segmento: any;
  telefone: any;
  email: any;
  contato: any;
  constructor(  private toastr: ToastrService,
    private _helperService: HelperService,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  enviar(content){
    if (this.email == "" || this.email == null) {
      this.toastr.error('Email obrigatorio!');
      return;
    }
    if (this.telefone == "" || this.telefone == null) {
      this.toastr.error('Telefone obrigatorio!');
      return;
    }
    this.toastr.success('Solicitação enviada com sucesso!');
    this.modalService.open(content, { centered :true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this._helperService.emailParceiro({ "email": this.email, "nome": this.nome, "telefone": this.telefone, "segmento": this.segmento, "contato": this.contato }).then(data => {
    })
  }

}
