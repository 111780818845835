import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compra-convidado',
  templateUrl: './compra-convidado.component.html',
  styleUrls: ['./compra-convidado.component.css']
})
export class CompraConvidadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
