import { BrowserModule,Meta } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {MatIconModule} from '@angular/material/icon';
import { NgxMaskModule, IConfig } from 'ngx-mask';


//landings
import { RemarketingLandingComponent } from './landing/remarketing-landing/remarketing-landing.component';
import { PipoohMaisBaratoReComponent } from './landing/pipooh-mais-barato-re/pipooh-mais-barato-re.component';
import { ArmazenamentoFraldaReComponent } from './landing/armazenamento-fralda-re/armazenamento-fralda-re.component';
import { BilhetesRifaReComponent } from './landing/bilhetes-rifa-re/bilhetes-rifa-re.component';
import { PerderProdutosReComponent } from './landing/perder-produtos-re/perder-produtos-re.component';
import { ChaRifaCadComponent } from './landing/cha-rifa-cad/cha-rifa-cad.component';
import { ChaFraldaCadComponent } from './landing/cha-fralda-cad/cha-fralda-cad.component';
import { PerderFraldasCadComponent } from './landing/perder-fraldas-cad/perder-fraldas-cad.component';
import { MaisBilhetesCadComponent } from './landing/mais-bilhetes-cad/mais-bilhetes-cad.component';
import { AjudaPontosComponent } from './landing/ajuda-pontos/ajuda-pontos.component';

//Fluxo Convidados
import { PresentearMamaeComponent } from './presentear-mamae/presentear-mamae.component';
import { PrincipalComponent } from './principal/principal.component';
import { FormsModule } from '@angular/forms';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { CarrinhoRifaComponent } from './carrinho-rifa/carrinho-rifa.component';
import { EscolherMamaeComponent } from './escolher-mamae/escolher-mamae.component';
import { ContatoComponent } from './contato/contato.component';
import { TrocaComponent } from './troca/troca.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { FaqComponent } from './faq/faq.component';
import { ParceiroComponent } from './parceiro/parceiro.component';
import { AfiliadoComponent } from './afiliado/afiliado.component';
import { EmpresarialComponent } from './empresarial/empresarial.component';
import { RedirectComponent } from './redirect/redirect.component';
import { EventoPipoohComponent } from './evento-pipooh/evento-pipooh.component';
import { ConvidadoListaComponent } from './convidado-lista/convidado-lista.component';
import { PreLancamentoComponent } from './landing/pre-lancamento/pre-lancamento.component';
import { ClubePipoohPlanosebeneficiosComponent } from './landing/clube-pipooh-planosebeneficios/clube-pipooh-planosebeneficios.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MaterialModule } from './material/material.module';
import { CarrinhoRevelacaoComponent } from './carrinho-revelacao/carrinho-revelacao.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EstabelecimentosComponent } from './landing/estabelecimentos/estabelecimentos.component';
import { ProdutosPromoComponent } from './landing/produtos-promo/produtos-promo.component';
import { EventoComponent } from './landing/evento/evento.component';
import { LembreteCanceladoComponent } from './landing/lembrete-cancelado/lembrete-cancelado.component';
import { ContatosLembreteComponent } from './landing/contatos-lembrete/contatos-lembrete.component';
import { ComprePontueComponent } from './landing/compre-pontue/compre-pontue.component';
import { CadastroLiveloComponent } from './landing/cadastro-livelo/cadastro-livelo.component';
import { ComprePontueRegulamentoComponent } from './landing/compre-pontue-regulamento/compre-pontue-regulamento.component';
import { CadastroLiveloRegulamentoComponent } from './landing/cadastro-livelo-regulamento/cadastro-livelo-regulamento.component';
import { VoucherRhComponent } from './landing/voucher-rh/voucher-rh.component';
import { CompraConvidadoComponent } from './compra-convidado/compra-convidado.component';
import { CartaoPresenteComponent } from './landing/cartao-presente/cartao-presente.component';
import { EventosComponent } from './eventos/eventos.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { EventoEstabelecimentoComponent } from './evento-estabelecimento/evento-estabelecimento.component';
import { EventosEstabelecimentoComponent } from './eventos-estabelecimento/eventos-estabelecimento.component';
import { IngressoEstabelecimentoComponent } from './ingresso-estabelecimento/ingresso-estabelecimento.component';
import { VisualizacaoCartaopresenteComponent } from './landing/visualizacao-cartaopresente/visualizacao-cartaopresente.component';
import { PesquisaNpsComponent } from './landing/pesquisa-nps/pesquisa-nps.component';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    RemarketingLandingComponent,
    PipoohMaisBaratoReComponent,
    ArmazenamentoFraldaReComponent,
    BilhetesRifaReComponent,
    PerderProdutosReComponent,
    ChaRifaCadComponent,
    ChaFraldaCadComponent,
    PerderFraldasCadComponent,
    MaisBilhetesCadComponent,
    PresentearMamaeComponent,
    PrincipalComponent,
    CarrinhoComponent,
    CarrinhoRifaComponent,
    AjudaPontosComponent,
    EscolherMamaeComponent,
    ContatoComponent,
    TrocaComponent,
    ProdutosComponent,
    FaqComponent,
    ParceiroComponent,
    AfiliadoComponent,
    EmpresarialComponent,
    RedirectComponent,
    EventoPipoohComponent,
    ConvidadoListaComponent,
    PreLancamentoComponent,
    ClubePipoohPlanosebeneficiosComponent,
    CarrinhoRevelacaoComponent,
    ConfirmDialogComponent,
    EstabelecimentosComponent,
    ProdutosPromoComponent,
    EventoComponent,
    LembreteCanceladoComponent,
    ContatosLembreteComponent,
    ComprePontueComponent,
    CadastroLiveloComponent,
    ComprePontueRegulamentoComponent,
    CadastroLiveloRegulamentoComponent,
    VoucherRhComponent,
    CompraConvidadoComponent,
    CartaoPresenteComponent,
    EventosComponent,
    BeneficiosComponent,
    EventoEstabelecimentoComponent,
    EventosEstabelecimentoComponent,
    IngressoEstabelecimentoComponent,
    VisualizacaoCartaopresenteComponent,
    PesquisaNpsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    IvyCarouselModule,
    HttpClientModule,
    MatIconModule,
    ToastrModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    PdfViewerModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxQRCodeModule,

  ],
  providers: [Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
