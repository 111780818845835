import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CadastroEventoService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;


  constructor(private http: HttpClient,
    private toastr: ToastrService) { }
  postData(url, formData): Promise<any> {

    return lastValueFrom(this.http.post(url, formData, { responseType: 'json' })).then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });
  }
  cadastroInsert(formData) {
    return this.postData(`${this.URLAspNet}cadastroEventoEstabelecimento/web/insert`, formData);
  }
  selecionarCpf(formData) {
    return this.postData(`${this.URLAspNet}cadastroEventoEstabelecimento/web/select/cpf`, formData);
  }
  selecionarEmail(formData) {
    return this.postData(`${this.URLAspNet}cadastroEventoEstabelecimento/web/select/email`, formData);
  }
  login(formData) {
    return this.postData(`${this.URLAspNet}cadastroEventoEstabelecimento/web/login`, formData);
  }

}
