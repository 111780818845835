<div style="background: #fff !important;">


     <p class="headerTitulo">Confira agora o <a
            href="https://img.pipooh.com.br/imagens/pdf/RegulamentoComprePontueFinal.pdf"
            target="_blank">regulamento</a> </p> 
   <!-- <p class="headerTitulo">Confira agora o <a
            href="https://img.pipooh.com.br/imagens/pdf/RegulamentoComprePontueFinalDiaDasMaes.pdf"
            target="_blank">regulamento</a> </p>  -->

     <img src="../../../assets/img/banners/PIPOOH_NA_LIVELO_PC.png" class="d-none d-lg-block" width="100%" alt="">
    <img src="../../../assets/img/banners/ComprePontueMobile1.jpeg" class="d-lg-none" width="100%" alt=""> 
    <!-- <img src="../../../assets/img/banners/PIPOOH_JUNHO.png" class="d-none d-lg-block" width="100%" alt=""> -->
    <!-- <img src="../../../assets/img/banners/livelo-desktop-maes.jpg" class="d-none d-lg-block" width="100%" alt="">
    <img src="../../../assets/img/banners/livelo-dia-das-maes-mobile.png" class="d-lg-none" width="100%" alt=""> -->
    <div class="container">

        <div class="row justify-content-center">
            <!-- <div class="col-12"> -->
            <div class="row justify-content-center">
                <h3 style=" color:#01b1af; text-align: center; margin-top: 10px;">Quantos "Créditos PIPOOH" deseja
                    comprar:
                </h3>
                <br>
                <h6 style=" text-align: center;">E use como e onde quiser</h6>
                <div class="col-lg-2 valor" (click)="valor = 50">
                    <button class="btn btn-primary2"
                        style="font-size: 20px !important;color: black; background-color: #f1f1f1;">R$50,00</button>
                    <!-- <p style="margin-top: 15px;" class="form-control">R$50,00</p> -->
                </div>
                <div class="col-lg-2 valor" (click)="valor = 100">
                    <button class="btn btn-primary2"
                        style="font-size: 20px !important;color: black; background-color: #f1f1f1;">R$100,00</button>
                    <!-- <p style="margin-top: 15px;" class="form-control">R$100,00</p> -->
                </div>
                <div class="col-lg-2 valor" (click)="valor = 250">
                    <button class="btn btn-primary2"
                        style="font-size: 20px !important;color: black; background-color: #f1f1f1;">R$250,00</button>
                    <!-- <p style="margin-top: 15px;" class="form-control">R$500,00</p> -->
                </div>
                <div class="col-lg-2 valor" (click)="valor = 500">
                    <button class="btn btn-primary2"
                        style="font-size: 20px !important;color: black; background-color: #f1f1f1;">R$500,00</button>
                    <!-- <p style="margin-top: 15px;" class="form-control">R$500,00</p> -->
                </div>
                <!-- <div class="col-lg-2 valor">
                <p>Outro Valor:</p>
                <input type="text" style="width: 50%;" class="form-control" placeholder="R$0.00"
                    [dropSpecialCharacters]="false" mask="0*.00" [(ngModel)]="valor">
            </div> -->

            </div>
            <br>
            <p *ngIf="valor" style="text-align: center; margin-top: 5px;"><strong>PIX</strong> ou
                <strong>Boleto</strong> :
                {{valor * 4 }} pontos Livelo
            </p>
            <p *ngIf="valor" style="text-align: center;"><strong>Cartão</strong> : {{valor * 3 }} pontos Livelo </p>
            <!-- <p *ngIf="valor" style="text-align: center;"><strong>Não Assinante Livelo</strong> : {{valor * 4 }} pontos
                Livelo </p> -->
            <!-- <p *ngIf="valor" style="text-align: center; color: red;">**Máximo R$500,00 pontuáveis por CPF</p> -->
            <div class="row" style="text-align: center;">
                <div class="col-lg-6">

                    <p style="text-align: right; margin-top: 25px;">
                        <a class="btn btn-primary2 hidden-xs" target="_blank" style="font-size: 20px;"
                            href="https://pipooh.com.br/assinatura">Ser assinante</a>
                        <button class="btn btn-primary d-lg-none" style="width: 100%;     height: 100%; padding: 10px ;
            font-size: 20px;" (click)="abrirInfoCompra(modalInfoConta)">Comprar créditos</button>
                    </p>
                </div>
                <div class="col-lg-6">
                    <p style="text-align: left;">
                        <button class="btn btn-primary hidden-xs"
                            style="font-size: 20px;padding: 10px ;  margin-top: 25px;"
                            (click)="abrirInfoCompra(modalInfoConta)">Comprar
                            créditos</button>
                        <!-- <a class="btn btn-primary2 d-lg-none" style="width: 100%;     height: 100%;
        font-size: 20px;" href="https://app.pipooh.com.br/?ref=area-livelo">Abrir conta gratuitamente</a> -->
                        <a class="btn btn-primary2 d-lg-none" style="width: 100%;     height: 100%;  
        font-size: 20px;" target="_blank" href="https://pipooh.com.br/assinatura">Ser assinante</a>
                    </p>
                </div>
            </div>


        </div>
    </div>

    <div style="background-color: #F9E291;">
        <div class="row" style="padding: 20px;">
            <div class="col-lg-6" style="padding: 25px; text-align: center;">
                <div class="visible-xs">
                    <iframe width="100%"  src="https://www.youtube.com/embed/XrIarRCPYPs"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                </div>
                <iframe width="560" class="hidden-xs" height="315" src="https://www.youtube.com/embed/XrIarRCPYPs"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
            <div class="col-lg-6" style="text-align: center; padding: 25px;">
                <a class="btn btn-primary d-lg-none" style="background-color: #EF7300; color: white;border-color: #EF7300;
        font-size: 20px;" href="https://app.pipooh.com.br/?ref=area-livelo">Abrir conta gratuitamente</a>
                <a class="btn btn-primary2 hidden-xs" style="background-color: #EF7300; color: white; border-color: #EF7300;
        font-size: 20px;" href="https://app.pipooh.com.br/?ref=area-livelo">Abrir conta gratuitamente</a>
        <br>
            <a href="https://pipooh.com.br/beneficios" target="_blank">Programa de pontos PIPOOH</a>    
        </div>
        </div>

    </div>
    <div class="container">
        <div class="row justify-content-center" *ngIf="estabelecimentoImagem.length > 0">
            <div class="col-xs-12 col-md-10 p-xs-1" style="margin-top: 15px">
                <h3 style=" color:#01b1af;  text-align: center;">O que comprar com os créditos:</h3>
            </div>
        </div>

        <div class="m-content profile" style="background-color: #fff; border-radius: 15px;">
            <br>
            <div class="row">
                <div class="input-group mb-3" style="border-radius: 25px">
                    <input style="border-radius: 25px;background-color: #f7f7f7 !important;margin-right: 10px;"
                        type="text" class="form-control" (keyup.enter)="search()" [(ngModel)]="buscar"
                        placeholder="Buscar produtos, serviços ou lojas..." aria-label="Recipient's username"
                        aria-describedby="button-addon2" />
                    <div class="input-group-append mr-md-5">
                        <button style="border-radius: 25px; background-color: #F8B3CF; color: #fff;"
                            class="btn btn-outline-secondary" (click)="search()" type="button"
                            [disabled]="loadingProdutos || loadingLojas || loadingAnuncios "
                            [ngClass]="{'m-loader m-loader--right m-loader--light': (loadingProdutos || loadingLojas || loadingAnuncios ) }"
                            id="button-addon2">
                            Buscar <i class="fa fa-search" style="color: #fff;" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <br>
            <mat-tab-group [selectedIndex]="selected.value" class="demo-tab-group">
                <mat-tab label="Produtos">
                    <ng-template mat-tab-label>
                        Produtos
                    </ng-template>
                    <mat-spinner style="margin-left: 50%;" *ngIf="loadingProdutos == true;"></mat-spinner>

                    <div class="row py-3 produtcs-resgate pr-4" *ngIf="this.products && loadingProdutos == false">
                        <div class="col-12 filtro" style="float: right">
                            <a href="javascript:$('#modalFiltroProduto').modal('show');">Filtrar </a>
                        </div>
                        <br>
                        <div class="col-xs-12 col-md-4"
                            *ngFor="let prod of products | slice: (pageProdutos-1) * pageSize : pageProdutos * pageSize">

                            <div class="row" style="text-align: center;">
                                <div class="col-md-12 col-5" style="margin-bottom: 10px;">

                                    <img (click)="openProduto(prod)"
                                        src="{{prod.fkProdutos_LojaIntegrada ? this.urlImage(prod.ProductsImage, prod.fkLojaIntegrada) : urlImage(prod.ProductsImage, 0)}}"
                                        class="" alt="" style="
                    max-height: 150px;
                    max-width: 100%;
                    border-radius: 25px;
                    box-shadow: 0px 3px 6px #00000029;
                  " />

                                </div>

                                <div class="col-md-12 col-7" style="text-align: center;">
                                    <div style="min-height: 70px; max-height: 90px;">
                                        <h6 class="" style="color:#6A6A6A;">{{prod.ProductsName}}</h6>

                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-12" style="text-align: center;">
                                            <h6 class="mt-1" *ngIf="prod.CategoryID == 25"
                                                style="color: red; text-decoration: line-through;">R$
                                                {{this.formatDecimal(prod.precoAnterior)}}
                                            </h6>
                                            <h4 class="mt-1" style="color: #01B1AF;">R$
                                                {{this.formatDecimal(prod.ProductsPrice)}}
                                            </h4>

                                        </div>
                                        <div class="col-6 btn btn-primary" (click)="openProduto(prod)">
                                            Visualizar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row py-3" *ngIf="!this.products">
                        <div class="col-xs-12 col-md-4 py-2">
                            <div class="media">
                                <h3>Produto não encontrado</h3>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-12 justify-content-center">
                            <ngb-pagination [(page)]="pageProdutos" [pageSize]="pageSize" [maxSize]="1"
                                (click)="changePage(pageProdutos)" [collectionSize]="totalProdutos" [rotate]="true">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- <div class="row py-3 produtcs-resgate" *ngIf="this.products.length == 0 && loadingProdutos == false">
                    <div class="col-xs-12 col-md-4 py-2">
                        <div class="media">
                            <h3>Produto não encontrado</h3>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.products.length > 0 && loadingProdutos == false">
                    <div class="col-12 justify-content-center">
                        <ngb-pagination [(page)]="pageProdutos" [pageSize]="pageSize" [maxSize]="5"
                            [collectionSize]="products.length" [rotate]="true"></ngb-pagination>
                    </div>
                </div> -->
                </mat-tab>
                <mat-tab label="Lojas">
                    <ng-template mat-tab-label>
                        Lojas
                    </ng-template>
                    <br>
                    <mat-spinner style="margin-left: 50%;" *ngIf="loadingLojas == true;"></mat-spinner>
                    <div class="col-xs-12 col-md-3" style="float: right">
                        <div class="input-group mb-3">
                            <select class="form-control" style="border-radius: 25px"
                                [(ngModel)]="this.categoriaSelecionada" style="width: 100%;"
                                (change)="buscarEstabelecimentoLista()">
                                <option value="">Todos</option>
                                <option *ngFor="let cat of categoriasEstabelecimento; let i = index"
                                    value="{{cat.categoria}}">
                                    {{cat.categoria}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <table class="table table-striped text-left"
                        *ngIf="estabelecimentos.length > 0 && loadingLojas == false">
                        <thead>
                            <tr>
                                <th scope="col">Imagem</th>
                                <th scope="col" class="hidden-xs">Nome da loja</th>
                                <th scope="col" class="hidden-xs">Endereço</th>
                                <th scope="col">Categoria</th>
                                <th scope="col">Cashback</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="this.estabelecimentos">
                            <tr
                                *ngFor="let est of this.estabelecimentos | slice: (pageLojas-1) * pageSize : pageLojas * pageSize">
                                <td><img src="https://img.pipooh.com.br/{{est.foto}}" alt="" style="max-width:70px" />
                                </td>
                                <td class="hidden-xs">{{ est.nomeFantasia }}</td>
                                <td class="hidden-xs" *ngIf="est.modalidade == 'Físico'">{{ est.endereco}}, {{
                                    est.numero}} - {{est.cidade}}/{{est.uf}}
                                </td>
                                <td class="hidden-xs" *ngIf="est.modalidade == 'Físico e Ecommerce'">{{
                                    est.endereco}}, {{ est.numero}} - {{est.cidade}}/{{est.uf}} - Ecommerce
                                </td>
                                <td class="hidden-xs"
                                    *ngIf="est.modalidade == 'Ecommerce' || est.modalidade == 'Servico-Gift'">
                                    Ecommerce
                                </td>
                                <td class="hidden-xs"
                                    *ngIf="est.modalidade == 'Venda direta' || est.modalidade == 'Franquia'">
                                </td>
                                <td>{{ est.categoria}}</td>
                                <td *ngIf="est.cashback || est.cashback > 0.00">{{ est.cashback}} %</td>
                                <td *ngIf="!est.cashback || est.cashback == 0.00"></td>

                            </tr>

                        </tbody>
                    </table>
                    <div class="row py-3 produtcs-resgate"
                        *ngIf="this.estabelecimentos.length == 0 && loadingLojas == false">
                        <div class="col-xs-12 col-md-4 py-2">
                            <div class="media">
                                <h3>Loja não encontrada</h3>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between p-2"
                        *ngIf="estabelecimentos.length > 0 && loadingLojas == false">
                        <ngb-pagination [collectionSize]="estabelecimentos.length" [(page)]="pageLojas" [maxSize]="5"
                            [pageSize]="pageSize">
                        </ngb-pagination>
                    </div>
                </mat-tab>
                <mat-tab label="Usados">
                    <ng-template mat-tab-label>
                        Usados
                    </ng-template>
                    <br>
                    <mat-spinner style="margin-left: 50%;" *ngIf="loadingAnuncios == true;"></mat-spinner>
                    <div class="col-12" style="float: right">
                        <a href="javascript:$('#modalFiltroAnuncio').modal('show');" style="float: right">Filtrar <i
                                class="fa fa-filter" aria-hidden="true"></i></a>
                    </div>
                    <div class="row py-3 produtcs-resgate"
                        *ngIf="this.anuncios.length == 0 && loadingAnuncios == false">
                        <div class="col-xs-12 col-md-4 py-2">
                            <div class="media">
                                <h3>Nenhum anúncio encontrado!</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.anuncios.length > 0 && loadingAnuncios == false">
                        <div *ngFor="
          let i = index;
          let prod;
          of: anuncios | slice: (pageAnuncios - 1) * pageSize:pageAnuncios * pageSize
        " class="col-lg-4" style="margin-top: 10px;">
                            <mat-card class="example-card" style="margin-top: 10px;">
                                <mat-card-header>
                                    <div mat-card-avatar class="example-header-image">
                                        <img src="https://img.pipooh.com.br/{{ prod.UserFoto }}" width="100%" alt=""
                                            style="border-radius: 25px" />
                                    </div>
                                    <mat-card-title>{{ prod.titulo }}</mat-card-title>
                                    <mat-card-subtitle> {{ prod.UserName }}</mat-card-subtitle>
                                </mat-card-header>
                                <img mat-card-image *ngIf="prod.link"
                                    style="width: 100% !important; margin: 0 !important"
                                    src="https://img.pipooh.com.br/{{ prod.link }}" alt="" />
                                <img mat-card-image *ngIf="!prod.link"
                                    style="width: 100% !important; margin: 0 !important"
                                    src="https://img.pipooh.com.br/imagens/marketplace/img-padrao.jpg" alt="" />
                                <mat-card-content style="text-align: center;">

                                    <p *ngIf="prod.tipo == 'Venda'" style="color: #01b1af;">
                                        R${{formatDecimal(prod.valor)}}
                                    </p>
                                    <p *ngIf="prod.tipo == 'Doação'" style="color: #01b1af;">
                                        Doação
                                    </p>
                                </mat-card-content>
                                <!-- <mat-card-actions style="text-align: center;">
                                <a class="btn btn-primary text-white" routerLink="/marketplace"
                                    [queryParams]="{ id: prod.id}">Comprar</a>
                            </mat-card-actions> -->
                            </mat-card>
                        </div>
                        <div class="col-12" style="margin-top: 25px;">
                            <div class="row">
                                <div class="col-12 justify-content-center">
                                    <ngb-pagination [(page)]="pageAnuncios" [pageSize]="pageSize" [maxSize]="5"
                                        [collectionSize]="anuncios.length" [rotate]="true">
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Serviços de saúde">
                    <ng-template mat-tab-label>
                        Serviços de saúde
                    </ng-template>
                    <br>
                    <mat-spinner style="margin-left: 50%;" *ngIf="loadingServicos == true;"></mat-spinner>

                    <div class="row py-3 produtcs-resgate pr-4" *ngIf="this.servicos && loadingServicos == false">

                        <br>
                        <div class="col-xs-12 col-md-4 py-2"
                            *ngFor="let i = index; let servico of servicos | slice: (pageServicos-1) * pageSize : pageServicos * pageSize">
                            <div class="media">
                                <img [src]="servico.imagem" class="mr-3" alt="" style="width: 30%" />
                                <div class="media-body">
                                    <h5 class="mt-0 mb-0 mb-0">{{servico.nome}}</h5>
                                    <h5 class="mt-1">R${{servico.valor}}
                                        <!-- <a (click)="comprarServico(servico)"
                                        class="btn btn-primary">Comprar</a> -->
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row py-3 produtcs-resgate"
                        *ngIf="this.servicos.length == 0 && loadingServicos == false">
                        <div class="col-xs-12 col-md-4 py-2">
                            <div class="media">
                                <h3>Serviço não encontrado</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.servicos.length > 0 && loadingServicos == false">
                        <div class="col-12 justify-content-center">
                            <ngb-pagination [(page)]="pageServicos" [pageSize]="pageSize" [maxSize]="5"
                                [collectionSize]="servicos.length" [rotate]="true"></ngb-pagination>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>

        </div>


    </div>
</div>

<!-- Modais a partir daqui -->

<ng-template #modalProduto let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="container-fluid ">
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="
                        let img of product.imagens;
                        let i = index;
                        let firstItem = first
                      " class="tab-pane active" id="imagem{{ i }}" aria-expanded="true">
                        <img [hidden]="this.chosenImg != i"
                            src="{{product.fkProdutos_LojaIntegrada ? this.urlImage(product.ProductsImage, product.fkLojaIntegrada) : urlImage(product.ProductsImage, 0)}}"
                            width="100%" style="border-radius: 25px" />
                    </div>

                    <ul *ngIf="product"
                        class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
                        role="tablist">
                        <li *ngFor="let prod of product.imagens ; let i=index;let firstItem = first;"
                            class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link" [ngClass]="{ active: firstItem }" data-toggle="tab"
                                (click)="changeImg(i)" role="tab">{{i+1}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h5>{{this.product.ProductsName}}</h5>
                    <div *ngIf="this.more">
                        <div [innerHTML]="this.product.ProductsDescription">
                        </div>
                    </div>

                    <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
                    <h4 class="mt-3" style="color: #01b1af;">R$ {{this.formatDecimal(this.product.ProductsPrice)}}</h4>
                    <br>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
    data-target="#openModalProduto">Launch demo modal</button>

<ng-template #modalInfoConta let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row justify-content-center" style="text-align: center; padding: 10px;">
            <div class="col-lg-6 ">
                <button class="btn btn-primary" (click)="modal.dismiss('Cross click'); open(modalCadastrado)"
                    style="width: 100%;">Já tenho conta Pipooh</button>

            </div>
            <div class="col-lg-6">
                <button class="btn btn-primary" (click)="modal.dismiss('Cross click'); open(modalCadastrar)"
                    style="width: 100%;">Cadastrar</button>

            </div>
            <br>
        </div>
    </div>
</ng-template>

<ng-template #modalCadastrado let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <form (ngSubmit)="onSubmitFormCadastrado()" [formGroup]="formCadastrado">
                <input type="text" class="form-control" placeholder="Nome  Completo*" formControlName="nome" id="nome">
                <div class="error"
                    *ngIf="formCadastrado.get('nome')?.errors  && (formCadastrado.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>
                <input type="text" class="form-control" placeholder="E-mail*" formControlName="email" id="email">
                <div class="error"
                    *ngIf="formCadastrado.get('email').errors?.required  && (formCadastrado.get('email')?.touched  || submitted)">
                    E-mail obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrado.get('email').errors?.email && formCadastrado.get('email')?.touched">
                    E-mail inválido
                </div>
                <br>
                <input type="text" mask="000.000.000-00" class="form-control" placeholder="CPF*" formControlName="cpf"
                    id="cpf">

                <div class="error"
                    *ngIf="formCadastrado.get('cpf').errors?.minlength && (formCadastrado.get('cpf')?.touched  || submitted)">
                    CPF precisa conter 11 dígitos
                </div>
                <br>
                <input type="text" class="form-control" placeholder="Celular*" mask="(00)00000-0000"
                    formControlName="celular" id="celular">
                <div class="error"
                    *ngIf="formCadastrado.get('celular').errors?.minlength  && (formCadastrado.get('celular')?.touched || submitted)">
                    Celular precisa conter 11 dígitos
                </div>
                <br>
                <div class="cupom">
                    <p>Cupom</p>
                    <h3>LIVELO</h3>
                </div>

                <p style="text-align: center;"><strong>PIX</strong> ou <strong>Boleto</strong> : {{valor * 4}} pontos Livelo
                </p>
                <p style="text-align: center;"><strong>Cartão</strong> : {{valor * 3}} pontos Livelo</p>
                <!-- <p  style="text-align: center;"><strong>Não Assinante Livelo</strong> : {{valor * 4 }} pontos
                    Livelo </p> -->
                <!-- <p  style="text-align: center; color: red;">**Máximo R$500,00 pontuáveis por CPF</p> -->
                <div style="text-align: center;">
                    <button style="font-size: 20px; width: 50%;" class="btn btn-primary" type="submit"
                        [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        Pagar
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>

<ng-template #modalCadastrar let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="row" style=" padding: 10px;">
            <form (ngSubmit)="onSubmitFormCadastrar()" [formGroup]="formCadastrar">
                <select class="form-control" formControlName="sexo" id="sexo">
                    <option value="mamãe">Mamãe</option>
                    <option value="papai">Papai</option>
                </select>
                <div class="error"
                    *ngIf="formCadastrar.get('sexo')?.errors  && (formCadastrar.get('sexo')?.touched  || submitted)">
                    Sexo obrigatório
                </div>
                <br>

                <input type="text" class="form-control" placeholder="Nome Completo*" formControlName="nome" id="nome">
                <div class="error"
                    *ngIf="formCadastrar.get('nome')?.errors  && (formCadastrar.get('nome')?.touched  || submitted)">
                    Nome obrigatório
                </div>
                <br>
                <input type="text" class="form-control" placeholder="E-mail*" formControlName="email" id="email">
                <div class="error"
                    *ngIf="formCadastrar.get('email').errors?.required  && (formCadastrar.get('email')?.touched  || submitted)">
                    E-mail obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrar.get('email').errors?.email && formCadastrar.get('email')?.touched">
                    E-mail inválido
                </div>
                <br>
                <input type="text" mask="000.000.000-00" class="form-control" placeholder="CPF *" formControlName="cpf"
                    id="cpf">
                <div class="error"
                    *ngIf="(formCadastrar.get('cpf')?.errors && !formCadastrar.get('cpf').errors?.minlength) && submitted">
                    CPF obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrar.get('cpf').errors?.minlength && (formCadastrar.get('cpf')?.touched  || submitted)">
                    CPF precisa conter 11 dígitos
                </div>
                <br>
                <input type="text" class="form-control" placeholder="Celular*" mask="(00)00000-0000"
                    formControlName="celular" id="celular">
                <div class="error"
                    *ngIf="(formCadastrar.get('celular')?.errors && !formCadastrar.get('celular')?.errors?.minlength  )  &&  submitted">
                    Celular obrigatório
                </div>
                <div class="error"
                    *ngIf="formCadastrar.get('celular')?.errors?.minlength  && (formCadastrar.get('celular')?.touched || submitted)">
                    Celular precisa conter 11 dígitos
                </div>
                <br>
                <input type="password" class="form-control" placeholder="Senha*" formControlName="senha" id="senha">
                <div class="error"
                    *ngIf="formCadastrar.get('senha')?.errors  && (formCadastrar.get('senha')?.touched || submitted)">
                    Senha obrigatório
                </div>
                <br>
                <input type="password" class="form-control" formControlName="senhaR" placeholder="Confirmar senha*"
                    id="senhaR">
                <div class="error"
                    *ngIf="formCadastrar.get('senhaR')?.errors  && (formCadastrar.get('senhaR')?.touched || submitted)">
                    Confirmar Senha obrigatório
                </div>
                <br>
                <mat-radio-group aria-label="Select an option" formControlName="tipo" id="tipo">
                    <mat-radio-button value="gestante">Gestante &nbsp;</mat-radio-button>
                    <mat-radio-button value="criancaNasceu">Criança já nasceu</mat-radio-button>
                </mat-radio-group>
                <div class="error"
                    *ngIf="formCadastrar.get('tipo')?.errors  && (formCadastrar.get('tipo')?.touched  || submitted)">
                    Tipo obrigatório
                </div>
                <p style="margin-bottom:0px;" *ngIf="formCadastrar.value.tipo == 'gestante'"><strong>
                        Quantas semanas está:
                    </strong>
                </p>
                <div class="" *ngIf="formCadastrar.value.tipo == 'gestante'">
                    <select class="form-control" formControlName="semana" id="semana">
                        <option>Selecione a semana</option>
                        <option *ngFor="let sem of semanas" value="{{sem}}">{{sem}}</option>
                    </select>
                </div>
                <p style="margin-bottom:0px;" *ngIf="formCadastrar.value.tipo == 'criancaNasceu'">Data de
                    nascimento filho mais novo:</p>
                <div class="form-group m-form__group" *ngIf="formCadastrar.value.tipo == 'criancaNasceu'">
                    <input class="form-control m-input" type="date" placeholder="Data de nascimento"
                        formControlName="dataNascimentoCrianca" id="dataNascimentoCrianca" />
                </div>
                <br>
                <br>
                <label class="m-checkbox m-checkbox--focus">
                    <input type="checkbox" formControlName="termos" id="termos" /> Eu concordo com os
                    <a href="https://img.pipooh.com.br/imagens/pdf/5e04cf7bbfb6de5a8d3255bc94f2885c.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Termos de uso e condições.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrar.get('termos')?.errors  && submitted">
                    Termos de uso obrigatório
                </div>
                <br>
                <label class="m-checkbox m-checkbox--focus">
                    <input type="checkbox" formControlName="politica" id="politica" /> Eu
                    concordo com as
                    <a href="https://img.pipooh.com.br/imagens/pdf/da192f01bd138c4df9338dd70b02f1bf.pdf" target="_blank"
                        class="m-link m-link--focus">
                        Política de privacidade.
                    </a>
                    <span></span>
                </label>
                <div class="error" *ngIf="formCadastrar.get('politica')?.errors  &&  submitted">
                    Política de privacidade obrigatório
                </div>
                <div class="cupom">
                    <p>Cupom</p>
                    <h3>LIVELO</h3>
                </div>
                <div style="text-align: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="loading"
                        [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">
                        Cadastrar
                    </button>
                </div>

            </form>
            <br>
        </div>
    </div>
</ng-template>

<button id="openModalInfoConta" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalInfoConta)"
    data-target="#modalInfoConta">Launch demo modal</button>

<div id="modalFiltroProduto" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Informações do resgate #{{this.resgateDetail.ResgateID}}</h5> -->
                <a href="javascript:$('#modalFiltroProduto').modal('hide');" class="close-modal"><i class="fas fa-times"
                        data-bs-dismiss="modal" aria-label="Close"></i></a>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row pt-md-5">
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.categoria" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="" style="border-radius: 25px">Categorias</option>
                                <option *ngFor="let cat of categorys" style="border-radius: 25px"
                                    value="{{ cat.CategoryID }}">
                                    {{ cat.CategoriaName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.loja" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="">Lojas</option>
                                <option value="0">Pipooh</option>
                                <option value="1">Toymania</option>
                                <option value="2">Limara</option>
                                <!-- <option value="3">Chicco</option> -->
                                <option value="4">BabyStock</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.genero" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="">Gênero</option>
                                <option value="UNISSEX">Unissex</option>
                                <option value="MENINO">Menino</option>
                                <option value="MENINA">Menina</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.ordem" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="">Ordenar</option>
                                <option value="ASC">Menor preço</option>
                                <option value="DESC">Maior preço</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<button id="openModalFiltroProduto" [hidden]="true" data-toggle="modal" data-target="#modalFiltroProduto">Open
    Modal</button>
<div id="modalFiltroAnuncio" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Informações do resgate #{{this.resgateDetail.ResgateID}}</h5> -->
                <a href="javascript:$('#modalFiltroAnuncio').modal('hide');" class="close-modal"><i class="fas fa-times"
                        data-bs-dismiss="modal" aria-label="Close"></i></a>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row pt-md-5">
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.uf" (change)="getAnuncios()">
                                <option value="">Selecione o estado</option>
                                <option value="{{uf.uf}}" *ngFor="let uf of ufs">{{uf.uf}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.cidade" (change)="getAnuncios()">
                                <option value="">Selecione a cidade</option>
                                <option value="{{cidade.cidade}}" *ngFor="let cidade of cidades">{{cidade.cidade}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.categoria" (change)="getAnuncios()">
                                <option value="">Selecione a categoria</option>
                                <option value="{{categoria.CategoryID}}" *ngFor="let categoria of categoriasAnuncio">
                                    {{categoria.CategoriaName}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.tipo" (change)="getAnuncios()">
                                <option value="">Selecione o tipo</option>
                                <option value="Venda">Venda</option>
                                <option value="Doação">Doação</option>


                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<button id="openModalFiltroAnuncio" [hidden]="true" data-toggle="modal" data-target="#modalFiltroAnuncio">Open
    Modal</button>