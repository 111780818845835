import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  um: any = 0;
  dois: any = 0;
  tres: any = 0;
  quatro: any = 0;
  cinco: any = 0;
  seis: any = 0;
  sete: any = 0;
  oito: any = 0;
  nove: any = 0;
  dez: any = 0;
  onze: any = 0;
  doze: any = 0;
  treze: any = 0;
  quatorze: any = 0;
  quinze: any = 0;
  dezeseis: any = 0;
  dezesete: any = 0;
  dezoito: any = 0;
  deznove: any = 0;
  constructor() { }

  ngOnInit(): void {
  }

}
