import { Component, OnInit } from '@angular/core';
import { EventoEstabelecimentoService } from '../_services/evento-estabelecimento.service';

@Component({
  selector: 'app-eventos-estabelecimento',
  templateUrl: './eventos-estabelecimento.component.html',
  styleUrls: ['./eventos-estabelecimento.component.css']
})
export class EventosEstabelecimentoComponent implements OnInit {
  //eventos
  eventos: any = [];
  constructor(private _eventoEstabelecimentoService: EventoEstabelecimentoService,) { }

  ngOnInit(): void {
    this.buscarEventos();
  }
  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }
  buscarEventos(){
    this._eventoEstabelecimentoService.buscarEventos({}).then(data => {
      this.eventos = data.payload;
    })
  }

}
