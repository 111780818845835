<h1 mat-dialog-title>
  {{title}}
  </h1>

  <div mat-dialog-content>
  <p>{{message}}</p>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="onDelete()">Cancelar</button>
    <button mat-raised-button (click)="onCommand()">Votar</button>
    </div>
    <app-footer></app-footer>