<img src="https://img.pipooh.com.br/{{ ingressos[0].imgInfo }}" style="width: 100%;" *ngIf="ingressos[0].imgInfo" alt="">
<img src="https://img.pipooh.com.br/{{ evento[0].imagem }}" style="width: 100%;" *ngIf="!ingressos[0].imgInfo" alt="">
<div *ngIf="ingressos[0].status == 'Fila'"  style="margin-bottom: 0px;text-align: center; padding: 20px;">
    <h5>
        <span style="color:red">Você está na fila de espera. </span> 
        <br>
        
        <!-- caso outros participantes desistam ou não compareçam seu ingresso ficará disponível. -->
       
        
    </h5>

</div>
<div class="row">
    <div class="col-lg-12" style="margin-top: 10px; margin-bottom: 10px; padding: 20px; margin-left: 20px;padding-left: 10px;padding-top: 0;">
        <p  style="margin-bottom: 5px;font-size: 16px;"><i style="padding: 0;margin: 0;" class="fa fa-university" aria-hidden="true"></i>   {{evento[0].nomeFantasia}}</p>
        <p  style="margin-bottom: 5px;font-size: 16px;"><i style="padding: 0;margin: 0;" class="fa fa-calendar-o" aria-hidden="true"></i>   {{formatDate(ingressos[0].data)}}</p>
        <p style="margin-bottom: 5px;font-size: 16px;"><i style="padding: 0;margin: 0;"  class="fa fa-clock-o" aria-hidden="true"></i>  {{ingressos[0].horaInicio.substr(0,5) }} às {{ingressos[0].horaFim.substr(0,5)}}</p>
        <p style="margin-bottom: 5px;font-size: 16px;"><i style="padding: 0;margin: 0;" class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; {{ingressos[0].local}}</p>
        <p style="margin-bottom: 5px;font-size: 16px;" *ngIf="ingressos[0].status == 'Fila'" > <i style="padding: 0;margin: 0;"  class="fa fa-server" aria-hidden="true"></i> Sua posição na fila: {{posicaoFila > 0 ? posicaoFila : 1}} </p>
        <p style="margin-bottom: 5px;font-size: 16px;" *ngIf="ingressos[0].status == 'Fila'" > <i style="padding: 0;margin: 0;"  class="fa fa-question" aria-hidden="true"></i> Seu ingresso ficará disponível  em <br> caso de desistência. </p>
        <p  *ngIf="ingressos[0].aCaminho == 1 && ingressos[0].tolerancia && ingressos[0].status == 'Disponível'">Haverá uma tolerância máxima de {{ingressos[0].tolerancia.substr(3,5) }} minutos após o horário de início para a sua entrada. Após este período, a entrada não será mais permitida, pois desejamos garantir o bom andamento do evento e o respeito aos demais convidados.</p>
    </div>
    <div class="col-lg-12" style="margin-bottom: 10px;text-align: center; padding: 20px;">
        
        <button class="btn btn-success2" *ngIf="ingressos[0].disparo_minutoAntes && ingressos[0].aCaminho == 0" (click)="aCaminho();">Estou à Caminho</button>
        <button class="btn btn-danger" *ngIf="ingressos[0].status == 'Disponível' || ingressos[0].status == 'Fila'" (click)="cancelarIngresso();" style="border-radius: 20px;">Cancelar Ingressos</button>
        
    </div>
    <div class="col-lg-12">
        <div *ngFor="let ingresso of ingressos" style="margin-bottom: 10px;text-align: center; padding: 20px;">
            <div class="eventoCard" style="
                    text-align: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    border-radius: 5px;
                    box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
                    padding: 30px;
                    margin: 10px;
                  ">
              <h1 style="color: #1bc5bd;"> {{evento[0].nome}} </h1>

                  <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                  [value]="ingresso.codigo"></ngx-qrcode>
              <p>{{ingresso.codigo}}</p>

              <h3><i class="fa fa-user"></i> {{ingresso.nome}} </h3>
              
              <p *ngIf="ingresso.status == 'Fila'">Status: <span class="label-light-warning"
                  *ngIf="ingresso.status == 'Fila'">{{
                      ingresso.status }}</span>
              <p *ngIf="ingresso.status == 'Disponível'">Status: <span class="label-light-success"
                  *ngIf="ingresso.status == 'Disponível'">{{
                      ingresso.status }}</span>
              <p *ngIf="ingresso.status == 'Usado'">Status: <span class="label-light-primary"
                  *ngIf="ingresso.status == 'Usado'">{{
                      ingresso.status }}</span>
              <p *ngIf="ingresso.status == 'Expirado'">Status: <span class="label-light-danger"
                  *ngIf="ingresso.status == 'Expirado'">{{
                      ingresso.status }}</span>
              <p *ngIf="ingresso.status == 'Cancelado'">Status: <span class="label-light-danger"
                  *ngIf="ingresso.status == 'Cancelado'">{{
                      ingresso.status }}</span>
              </p>
                </div>
        </div>
    </div>
    <hr>
    <div style="margin-bottom: 10px; padding: 40px;" [innerHTML]="ingressos[0].obs" *ngIf="ingressos[0].obs"></div>

</div>