import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriaEstabelecimentoService {
  private URLAspNet = `${environmentAspNet.api}`;
  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

    selecionarCategoria(formData) {
      return this.postData(`${this.URLAspNet}categoriaEstabelecimento/web/select/lista`, formData);
    }

}
