import { Component, OnInit } from '@angular/core';
import { ConvidadoService } from '../_services/convidado.service';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-convidado-lista',
  templateUrl: './convidado-lista.component.html',
  styleUrls: ['./convidado-lista.component.css']
})
export class ConvidadoListaComponent implements OnInit {

  convidadoDados: any;
  constructor(
    private _convidado: ConvidadoService,
    private toastr: ToastrService,


  ) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.get('id');
    var myParam2 = urlParams.get('evento');
    localStorage.setItem('pipoohEvento', myParam2);
    localStorage.setItem('pipoohConvidado', myParam);

    this.infoConvidado();



  }
  infoConvidado() {
    this._convidado.getConvidadoPorID({ id: localStorage.getItem('pipoohConvidado'), eventoID: localStorage.getItem('pipoohEvento') }).then(data => {
        this.convidadoDados = data.payload[0];
        console.log(this.convidadoDados)
    });
  }

  deleteConvidado(){
    this._convidado.deleteConvidadoPorID({ id: localStorage.getItem('pipoohConvidado'), eventoID: localStorage.getItem('pipoohEvento') }).then(data => {
      console.log(this.convidadoDados)
      this.toastr.success("Número excluído, você será redirecionado em 5s")
      setTimeout(function () {
        window.location.href = "https://pipooh.com.br/"
    }, 5000);
  });
  }

}
