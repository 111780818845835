<app-header></app-header>
<div class="content-body">
    <ngb-carousel *ngIf="imagesDesktop" class="hidden-xs" [showNavigationArrows]="false" style="margin-bottom: 10px;">
        <ng-template ngbSlide *ngFor="let img of imagesDesktop; index as i;let first = first;">
            <a *ngIf="!first" href="javascript:$('#modalComoFunciona').modal('hide');"
                rel="nofollow noopener noreferrer">
                <div class="picsum-img-wrapper">
                    <img [src]="img" style="width: 100%;" alt="Random first slide">

                </div>
            </a>
            <a *ngIf="first" href="javascript:$('#modalComoFunciona').modal('show');"
                rel="nofollow noopener noreferrer">
                <div class="picsum-img-wrapper" (click)="console.log('aqui')">
                    <img [src]="img" style="width: 100%;" alt="Random first slide">

                </div>
            </a>

            <div class="carousel-caption" (click)="open(modalComoFunciona)" style="cursor: pointer;" *ngIf="first">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <div
                            style="background-color: white; padding: 25px; margin: 150px;border-radius: 20px; width: 100%;margin-bottom: 70px;">
                            <h6 class="hidden-xs" style=" color: #000; text-align: center;">
                                A MELHOR PLATAFORMA PARA GERENCIAR
                                <br>
                                OS PRESENTES E EVENTOS DOS SEUS FILHOS
                            </h6>
                            <div style="text-align: center;">
                                <button mat-stroked-button style="border-radius: 25px;background-color: #01B1AF; color: #fdfafa;height: 50px;
                    width: 65%;
                    margin-top: 20px;" color="primary">CADASTRE-SE GRATUITAMENTE</button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-caption" *ngIf="!first">
                <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <div
                            style="background-color: white; padding: 25px; margin: 150px;border-radius: 20px; width: 100%;margin-bottom: 70px;">
                            <h6 class="hidden-xs" style=" color: #000; text-align: center;"> A MELHOR PLATAFORMA PARA
                                GERENCIAR
                                <br>
                                OS PRESENTES E EVENTOS DOS SEUS FILHOS
                            </h6>
                            <div style="text-align: center;">
                                <!-- <button mat-stroked-button style="border-radius: 25px;background-color: #01B1AF; color: #fdfafa;height: 50px;
                                width: 65%;
                                margin-top: 20px;" color="primary">CADASTRE-SE GRATUITAMENTE</button>
             -->
                                <a href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
                                    mat-stroked-button style="border-radius: 25px;background-color: #01B1AF; color: #fdfafa;
                           
                                margin-top: 20px;" color="primary">CADASTRE-SE GRATUITAMENTE</a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-carousel>
    <ngb-carousel *ngIf="imagesMobile" class="visible-xs" style="margin-bottom: 10px;">
        <ng-template ngbSlide *ngFor="let img of imagesMobile; index as i;let first = first;">
            <a *ngIf="!first" href="javascript:$('#modalComoFunciona').modal('hide');"
                rel="nofollow noopener noreferrer">
                <div class="picsum-img-wrapper">
                    <img [src]="img" style="width: 100%;" alt="Random first slide">

                </div>
            </a>
            <a *ngIf="first" href="javascript:$('#modalComoFunciona').modal('show');"
                rel="nofollow noopener noreferrer">
                <div class="picsum-img-wrapper">
                    <img [src]="img" style="width: 100%;" alt="Random first slide">

                </div>
            </a>
            <div class="carousel-caption" (click)="open(modalComoFunciona)" style="cursor: pointer;" *ngIf="first">
                <div class="row ">
                    <div class="col-6"></div>
                    <div class="col-6">
                        <div
                            style=" padding: 25px; margin: 150px;border-radius: 20px; width: 100%;margin-bottom: 70px;">
                            <h6 class="hidden-xs" style=" color: #000; text-align: center;">PROGRAMA DE BENEFÍCIOS PARA
                                PAIS
                                <br>
                                QUE PENSAM NO FUTURO DOS FILHOS
                            </h6>
                            <div style="text-align: center;">


                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
    </ngb-carousel>



    <div class="row" style="text-align: center;" class="visible-xs">

        <h3 style="font-size: 18px !important; margin-top: 20px; margin-bottom: 15px;">
            A MELHOR PLATAFORMA PARA GERENCIAR
            <br>
            OS PRESENTES E EVENTOS DOS SEUS FILHOS
        </h3>

        <a class="btn" mat-stroked-button href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae"
            style="border-radius: 25px;background-color: #01B1AF; color: #fdfafa;" color="primary">CADESTRE-SE
            GRATUITAMENTE</a>
    </div>
</div>

<div class="card" style="border-top-left-radius: 30px;
border-top-right-radius: 30px;margin-bottom: 120px; border: 1px solid #000;">
    <div style="border-top-left-radius: 30px;
    border-top-right-radius: 30px; background-color: #fff; text-align: center;">
        <br>
        <h5 class="mb-0" class="visible-xs" style="color:#645a5a">COMPRE COM A PIPOOH:</h5>
        <h5 class="mb-0" class="hidden-xs">COMPRE COM A PIPOOH:</h5>
        <p class="visible-xs" style="font-size: 14px !important;color:#645a5a">Consumo e presentes = previdência ou
            pontos LIVELO.</p>
        <p class="hidden-xs">Todo consumo e presentes do seu filho acumula pontos, que viram poupança ou pontos
            LIVELO.<br><strong>R$1 = 50
                pontos PIPOOH</strong></p>
        <div class="row" style="padding: 20px;">
            <div class="hidden-xs col-lg-6"></div>
            <div class="input-group col-md-10 col-lg-4" style="border-radius: 25px; margin-right: 5px;">
                <input style="border-radius: 25px;background-color: #f7f7f7 !important;margin-right: 10px;" type="text"
                    class="form-control" (keyup.enter)="search()" [(ngModel)]="buscar"
                    placeholder="Produtos, serviços ou lojas..." aria-label="Recipient's username"
                    aria-describedby="button-addon2" />
                <div class="input-group-append col-2">
                    <button style="border-radius: 25px; background-color: #F8B3CF; "
                        class="btn btn-outline-secondary visible-xs" (click)="search()" type="button"
                        [disabled]="loadingProdutos || loadingLojas || loadingAnuncios "
                        [ngClass]="{'m-loader m-loader--right m-loader--light': (loadingProdutos || loadingLojas || loadingAnuncios ) }"
                        id="button-addon2">
                        <i class="fa fa-search" style="color: #01B1AF;" aria-hidden="true"></i>
                    </button>
                    <button style="border-radius: 25px; background-color: #F8B3CF; color: #fff;"
                        class="btn btn-outline-secondary hidden-xs" (click)="search()" type="button"
                        [disabled]="loadingProdutos || loadingLojas || loadingAnuncios "
                        [ngClass]="{'m-loader m-loader--right m-loader--light': (loadingProdutos || loadingLojas || loadingAnuncios ) }"
                        id="button-addon2">
                        Buscar <i class="fa fa-search" style="color: #fff;" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
        <br>
        <mat-tab-group [selectedIndex]="selected.value" class="demo-tab-group">
            <mat-tab label="Produtos">
                <ng-template mat-tab-label>
                    Produtos
                </ng-template>
                <mat-spinner style="margin-left: 50%;" *ngIf="loadingProdutos == true;"></mat-spinner>


                <div class="row py-3 produtcs-resgate pr-4" *ngIf="this.products && loadingProdutos == false">
                    <div class="col-12 filtro">
                        <a href="javascript:$('#modalFiltroProduto').modal('show');">Filtrar </a>
                    </div>
                    <div class="col-xs-12 col-md-3"
                        *ngFor="let prod of products | slice: (pageProdutos-1) * pageSize : pageProdutos * pageSize">

                        <div class="row" style="text-align: center;">
                            <div class="col-md-12 col-5" style="margin-bottom: 10px;">

                                <img (click)="openProduto(prod)"
                                    src="{{prod.fkProdutos_LojaIntegrada ? this.urlImage(prod.ProductsImage, prod.fkLojaIntegrada) : urlImage(prod.ProductsImage, 0)}}"
                                    class="" alt="" style="
                    max-height: 150px;
                    max-width: 100%;
                    border-radius: 25px;
                    box-shadow: 0px 3px 6px #00000029;
                  " />

                            </div>

                            <div class="col-md-12 col-7" style="text-align: center;">
                                <div style="min-height: 70px; max-height: 90px;">
                                    <h6 class="" style="color:#6A6A6A;">{{prod.ProductsName}}</h6>

                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12" style="text-align: center;">
                                        <h6 class="mt-1" *ngIf="prod.CategoryID == 25"
                                            style="color: red; text-decoration: line-through;">R$
                                            {{this.formatDecimal(prod.precoAnterior)}}
                                        </h6>
                                        <h4 class="mt-1" style="color: #01B1AF;">R$
                                            {{this.formatDecimal(prod.ProductsPrice)}}
                                        </h4>

                                    </div>
                                    <div class="col-6 btn btn-primary" (click)="openProduto(prod)">
                                        Comprar
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row py-3" *ngIf="!this.products">
                    <div class="col-xs-12 col-md-4 py-2">
                        <div class="media">
                            <h3>Produto não encontrado</h3>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12 justify-content-center">
                        <ngb-pagination [(page)]="pageProdutos" [pageSize]="pageSize" [maxSize]="1"
                            (click)="changePage(pageProdutos)" [collectionSize]="totalProdutos" [rotate]="true">
                        </ngb-pagination>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Lojas">
                <ng-template mat-tab-label>
                    Lojas
                </ng-template>
                <br>
                <mat-spinner style="margin-left: 50%;" *ngIf="loadingLojas == true;"></mat-spinner>
                <div class="col-xs-12 col-md-3" style="float: right">
                    <div class="input-group mb-3">
                        <select class="form-control" style="border-radius: 25px" [(ngModel)]="this.categoriaSelecionada"
                            style="width: 100%;" (change)="buscarEstabelecimentoLista()">
                            <option value="">Todos</option>
                            <option *ngFor="let cat of categoriasEstabelecimento; let i = index"
                                value="{{cat.categoria}}">
                                {{cat.categoria}}
                            </option>

                        </select>
                    </div>
                </div>
                <table class="table table-striped text-left"
                    *ngIf="estabelecimentos.length > 0 && loadingLojas == false">
                    <thead>
                        <tr>
                            <th scope="col">Imagem</th>
                            <th scope="col" class="hidden-xs">Nome da loja</th>
                            <th scope="col" class="hidden-xs">Endereço</th>
                            <th scope="col">Categoria</th>
                            <th scope="col">Cashback</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="this.estabelecimentos">
                        <tr
                            *ngFor="let est of this.estabelecimentos | slice: (pageLojas-1) * pageSize : pageLojas * pageSize">
                            <td><img src="https://img.pipooh.com.br/{{est.foto}}" alt="" style="max-width:70px" /></td>
                            <td class="hidden-xs">{{ est.nomeFantasia }}</td>
                            <td class="hidden-xs" *ngIf="est.modalidade == 'Físico'">{{ est.endereco}}, {{
                                est.numero}} - {{est.cidade}}/{{est.uf}}
                            </td>
                            <td class="hidden-xs" *ngIf="est.modalidade == 'Físico e Ecommerce'">{{
                                est.endereco}}, {{ est.numero}} - {{est.cidade}}/{{est.uf}} - Ecommerce
                            </td>
                            <td class="hidden-xs"
                                *ngIf="est.modalidade == 'Ecommerce' || est.modalidade == 'Servico-Gift'">
                                Ecommerce
                            </td>
                            <td class="hidden-xs"
                                *ngIf="est.modalidade == 'Venda direta' || est.modalidade == 'Franquia' || est.modalidade == 'Servico-Gift'">
                            </td>
                            <td>{{ est.categoria}}</td>
                            <td *ngIf="est.cashback || est.cashback > 0.00">{{ est.cashback}} %</td>
                            <td *ngIf="!est.cashback || est.cashback == 0.00"></td>

                        </tr>

                    </tbody>
                </table>
                <div class="row py-3 produtcs-resgate"
                    *ngIf="this.estabelecimentos.length == 0 && loadingLojas == false">
                    <div class="col-xs-12 col-md-4 py-2">
                        <div class="media">
                            <h3>Loja não encontrada</h3>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between p-2"
                    *ngIf="estabelecimentos.length > 0 && loadingLojas == false">
                    <ngb-pagination [collectionSize]="estabelecimentos.length" [(page)]="pageLojas" [maxSize]="5"
                        [pageSize]="pageSize">
                    </ngb-pagination>
                </div>
            </mat-tab>
            <mat-tab label="Usados">
                <ng-template mat-tab-label>
                    Usados
                </ng-template>
                <br>
                <mat-spinner style="margin-left: 50%;" *ngIf="loadingAnuncios == true;"></mat-spinner>
                <div class="col-12 filtro">
                    <a href="javascript:$('#modalFiltroAnuncio').modal('show');">Filtrar </a>
                </div>
                <!-- <div class="col-12" style="text-align: end;">
                    <a href="javascript:$('#modalFiltroAnuncio').modal('show');">Filtrar <i class="fa fa-filter"
                            aria-hidden="true"></i></a>
                </div> -->
                <div class="row py-3 produtcs-resgate" *ngIf="this.anuncios.length == 0 && loadingAnuncios == false">
                    <div class="col-xs-12 col-md-4 py-2">
                        <div class="media">
                            <h3>Nenhum anúncio encontrado!</h3>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="this.anuncios.length > 0 && loadingAnuncios == false">
                    <div *ngFor="
          let i = index;
          let prod;
          of: anuncios | slice: (pageAnuncios - 1) * pageSize:pageAnuncios * pageSize
        " class="col-lg-4" style="margin-top: 10px;">
                        <mat-card class="example-card" style="margin-top: 10px;">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image">
                                    <img src="https://img.pipooh.com.br/{{ prod.UserFoto }}" width="100%" alt=""
                                        style="border-radius: 25px" />
                                </div>
                                <mat-card-title>{{ prod.titulo }}</mat-card-title>
                                <mat-card-subtitle> {{ prod.UserName }}</mat-card-subtitle>
                            </mat-card-header>
                            <img mat-card-image *ngIf="prod.link" style="width: 100% !important; margin: 0 !important"
                                src="https://img.pipooh.com.br/{{ prod.link }}" alt="" />
                            <img mat-card-image *ngIf="!prod.link" style="width: 100% !important; margin: 0 !important"
                                src="https://img.pipooh.com.br/imagens/marketplace/img-padrao.jpg" alt="" />
                            <mat-card-content style="text-align: center;">

                                <p *ngIf="prod.tipo == 'Venda'" style="color: #01b1af;">
                                    R${{formatDecimal(prod.valor)}}
                                </p>
                                <p *ngIf="prod.tipo == 'Doação'" style="color: #01b1af;">
                                    Doação
                                </p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-12" style="margin-top: 25px;">
                        <div class="row">
                            <div class="col-12 justify-content-center">
                                <ngb-pagination [(page)]="pageAnuncios" [pageSize]="pageSize" [maxSize]="5"
                                    [collectionSize]="anuncios.length" [rotate]="true">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Serviços de saúde">
                <ng-template mat-tab-label>
                    Serviços de saúde
                </ng-template>
                <br>
                <mat-spinner style="margin-left: 50%;" *ngIf="loadingServicos == true;"></mat-spinner>

                <div class="row" style="margin-top: 15px;margin-bottom: 15px;">
                    <div class="col-5" style="text-align: center;">
                        <img class="hidden-xs" style="width: 50%;" src="../../assets/img/conecta.png" alt="">
                        <img class="visible-xs" style="width: 100%;" src="../../assets/img/conecta.png" alt="">

                    </div>
                    <div class="col-7">
                        <p style="font-size: 14px;margin-top: 20px">Telemedicina</p>
                    </div>

                </div>
                <div class="row" style="margin-top: 25px;margin-bottom: 15px; background-color: #f4f5f8; padding: 5px;">
                    <div class="col-5" style="text-align: center;">
                        <img class="hidden-xs" style="width: 30%;" src="../../assets/img/2.png" alt="">
                        <img class="visible-xs" style="width: 100%;" src="../../assets/img/2.png" alt="">

                    </div>
                    <div class="col-7">
                        <p style="font-size: 14px;margin-top: 20px">Exames/vacinas à domicílio</p>
                    </div>

                </div>
                <div class="row" style="margin-top: 15px;margin-bottom: 15px;">
                    <div class="col-5" style="text-align: center;">
                        <img class="hidden-xs" style="width: 40%;" src="../../assets/img/3.png" alt="">
                        <img class="visible-xs" style="width: 100%;" src="../../assets/img/3.png" alt="">

                    </div>
                    <div class="col-7">
                        <p style="font-size: 14px;margin-top: 20px">Médicos</p>
                    </div>

                </div>
                <div class="row" style="margin-top: 15px;margin-bottom: 15px; background-color: #f4f5f8; padding: 5px;">
                    <div class="col-5" style="text-align: center;">
                        <img class="hidden-xs" style="width: 40%;" src="../../assets/img/4.png" alt="">
                        <img class="visible-xs" style="width: 100%;" src="../../assets/img/4.png" alt="">

                    </div>
                    <div class="col-7">
                        <p style="font-size: 14px;margin-top: 20px;">Dentistas</p>
                    </div>

                </div>

            </mat-tab>
        </mat-tab-group>

    </div>
</div>


<div class="footer-principal" style="
bottom:0;  position: fixed;
left: 0; right: 0; width: 100%; z-index: 15;">
    <div class="accordion" id="accordionExample">
        <div class="card" style="border-top-left-radius: 30px; 
    border-top-right-radius: 30px; text-align: center;">
            <div class="card-header" id="headingOne" style="background-color: #b5fffe; border-top-left-radius: 30px; 
            border-top-right-radius: 30px;">
                <h5 class="mb-0" style="text-align: center;">
                    <button class="btn " type="button" style="color: #645a5a" data-toggle="collapse"
                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        ENTENDA A <b style="color: #01b1af;">PIPOOH</b>
                    </button>
                </h5>
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                    <h5><strong>CARTEIRA DIGITAL DOS FILHOS</strong> </h5>

                    <p>Utilize a <b style="color: #01B1AF;">PIPOOH</b> para ganhar tempo, dinheiro e dar um <b
                            style="color: #01B1AF;">futuro melhor</b> para o seu filho.</p>
                    <div class="row">
                        <div class="col-4">
                            <img width="100%" class="visible-xs" src="../../assets/img/10.png" alt="">
                            <img width="50%" class="hidden-xs" src="../../assets/img/10.png" alt="">
                        </div>
                        <div class="col-4">
                            <img width="100%" style="cursor: pointer;" class="visible-xs" src="../../assets/img/11.png"
                                alt="">
                            <img width="50%" style="cursor: pointer;" class="hidden-xs" src="../../assets/img/11.png"
                                alt="">
                        </div>
                        <div class="col-4">
                            <img width="100%" class="visible-xs" src="../../assets/img/12.png" alt="">
                            <img width="50%" class="hidden-xs" src="../../assets/img/12.png" alt="">
                        </div>
                    </div>
                    <br>
                    <p>
                        A PIPOOH centraliza o mundo dos filhos em um só lugar e transforma parte de tudo que paga ou
                        compra em previdência ou viagens (LIVELO).
                    </p>
                </div>
            </div>
        </div>
        <div class="card" style="background-color: #b5fffe;">
            <div class="card-header" id="headingTwo" style="border-top-left-radius: 30px;
            border-top-right-radius: 30px; background-color: #7000cc;">
                <h5 class="mb-0" style="text-align: center;">
                    <button class="btn  collapsed" style="color: #fff" type="button" data-toggle="collapse"
                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        PODCAST PARA MÃES
                    </button>
                </h5>
            </div>
            <div style=" background-color: #7000cc;" id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                data-parent="#accordionExample">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4" style="text-align: center;">
                            <img width="100%" class="visible-xs" class="visible-xs"
                                src="../../assets/img/logoPodMae.jpeg" alt="">
                            <img width="50%" class="visible-xs" class="hidden-xs" src="../../assets/img/logoPodMae.jpeg"
                                alt="">
                        </div>
                        <div class="col-8" style="color: white;">
                            <p>Um bate papo muito divertido e descontraído de mães.Mães reais, sem romantismo, mas com
                                muito amor💜</p>
                            <p>Apresentadoras: <br>
                                @gabecardoso | @robertalasnaux | @robssanto</p>
                            <p>Toda segunda feira <br>
                                Temp 1: 42 programas <br>
                                Temp 2: Fevereiro/2023 <br></p>
                            <p class="visible-xs">Idealização: PIPOOH</p>
                        </div>

                    </div>
                    <div class="row visible-xs" style="background-color: #141414;">
                        <div class="col-12">
                            <img width="10%" (click)="navLink('https://www.instagram.com/podmae_oficial/')"
                                style="margin: 5px;cursor: pointer;" src="../../assets/img/14.png" alt="">
                            <img width="10%" (click)="navLink('https://www.youtube.com/@PodMae/videos')"
                                style="margin: 5px;cursor: pointer;" src="../../assets/img/15.png" alt="">
                            <img width="10%" (click)="navLink('https://open.spotify.com/show/75RWttMAk8ToVOKlKgQQM0')"
                                style="margin: 5px;cursor: pointer;" src="../../assets/img/16.png" alt="">
                        </div>

                    </div>
                    <div class="row hidden-xs" style="background-color: #141414; margin-top: 10px;">
                        <div class="col-4" style="text-align: center;">
                            <img width="10%" style="margin: 5px;cursor: pointer;"
                                (click)="navLink('https://www.instagram.com/podmae_oficial/')"
                                src="../../assets/img/14.png" alt="">
                            <img width="10%" (click)="navLink('https://www.youtube.com/@PodMae/videos')"
                                style="margin: 5px;cursor: pointer;" src="../../assets/img/15.png" alt="">
                            <img width="10%" style="margin: 5px;cursor: pointer;"
                                (click)="navLink('https://open.spotify.com/show/75RWttMAk8ToVOKlKgQQM0')"
                                src="../../assets/img/16.png" alt="">

                        </div>
                        <div class="col-8" style="color: #fff; text-align: center;">
                            <div class="row justify-content-between">
                                <div class="col-12">
                                    <p>Idealização:</p>
                                </div>
                                <div class="col-12">
                                    <p>PIPOOH</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- modais -->

<ng-template #modalComoFunciona let-modal>
    <div class="modal-header">

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <a href="https://app.pipooh.com.br/?refRemarketing=area-mamae&ref=area-mamae" mat-stroked-button style="border-radius: 25px;background-color: #01B1AF; color: #fdfafa;
        margin-top: 20px; margin-bottom: 20px;" color="primary">CADASTRE-SE GRATUITAMENTE</a>
        <iframe width="560" class="hidden-xs" height="315"
            src="https://www.youtube.com/embed/SWs4cVfjBtg?si=HJ1PvIUi1PtLeMRZ" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        <div class="visible-xs">
            <iframe width="100%" src="https://www.youtube.com/embed/SWs4cVfjBtg?si=HJ1PvIUi1PtLeMRZ"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        </div>


    </div>
</ng-template>

<div id="modalFiltroProduto" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Informações do resgate #{{this.resgateDetail.ResgateID}}</h5> -->
                <a href="javascript:$('#modalFiltroProduto').modal('hide');" class="close-modal"><i class="fas fa-times"
                        data-bs-dismiss="modal" aria-label="Close"></i></a>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row pt-md-5">
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.categoria" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="" style="border-radius: 25px">Categorias</option>
                                <option *ngFor="let cat of categorys" style="border-radius: 25px"
                                    value="{{ cat.CategoryID }}">
                                    {{ cat.CategoriaName }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.loja" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="">Lojas</option>
                                <option value="0">Pipooh</option>
                                <option value="1">Toymania</option>
                                <option value="2">Limara</option>
                                <option value="3">Chicco</option>
                                <option value="4">BabyStock</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.genero" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="">Gênero</option>
                                <option value="UNISSEX">Unissex</option>
                                <option value="MENINO">Menino</option>
                                <option value="MENINA">Menina</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" [(ngModel)]="buscaProduto.ordem" style="width: 100%"
                                (change)="getProducts()" style="border-radius: 25px">
                                <option value="">Ordenar</option>
                                <option value="ASC">Menor preço</option>
                                <option value="DESC">Maior preço</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<button id="openModalFiltroProduto" [hidden]="true" data-toggle="modal" data-target="#modalFiltroProduto">Open
    Modal</button>
<div id="modalFiltroAnuncio" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Informações do resgate #{{this.resgateDetail.ResgateID}}</h5> -->
                <a href="javascript:$('#modalFiltroAnuncio').modal('hide');" class="close-modal"><i class="fas fa-times"
                        data-bs-dismiss="modal" aria-label="Close"></i></a>
            </div>
            <div class="modal-body" style="padding: 5px;">
                <div class="row pt-md-5">
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.uf" (change)="getAnuncios()">
                                <option value="">Selecione o estado</option>
                                <option value="{{uf.uf}}" *ngFor="let uf of ufs">{{uf.uf}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.cidade" (change)="getAnuncios()">
                                <option value="">Selecione a cidade</option>
                                <option value="{{cidade.cidade}}" *ngFor="let cidade of cidades">{{cidade.cidade}}
                                </option>

                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.categoria" (change)="getAnuncios()">
                                <option value="">Selecione a categoria</option>
                                <option value="{{categoria.CategoryID}}" *ngFor="let categoria of categoriasAnuncio">
                                    {{categoria.CategoriaName}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <div class="input-group mb-3" style="border-radius: 25px">
                            <select class="form-control" style="border-radius: 25px" style="width: 100%"
                                [(ngModel)]="buscaAnuncio.tipo" (change)="getAnuncios()">
                                <option value="">Selecione o tipo</option>
                                <option value="Venda">Venda</option>
                                <option value="Doação">Doação</option>


                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<button id="openModalFiltroAnuncio" [hidden]="true" data-toggle="modal" data-target="#modalFiltroAnuncio">Open
    Modal</button>


<ng-template #modalProduto let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body info-produto">
        <div class="container-fluid ">
            <div class="row">
                <div class="col-md-6">
                    <div *ngFor="let img of product.imagens ; let i=index;let firstItem = first;"
                        class="tab-pane active" id="imagem{{i}}" aria-expanded="true">
                        <img [hidden]="this.chosenImg != i"
                            src="{{product.fkProdutos_LojaIntegrada ? this.urlImage(product.ProductsImage, product.fkLojaIntegrada) : urlImage(product.ProductsImage, 0)}}"
                            width="100%" style="border-radius: 25px;" />
                    </div>

                    <ul *ngIf="product"
                        class="nav nav-pills nav-pills--brand m-nav-pills--align-right m-nav-pills--btn-pill m-nav-pills--btn-sm"
                        role="tablist">
                        <li *ngFor="let prod of product.imagens ; let i=index;let firstItem = first;"
                            class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link" [ngClass]="{ active: firstItem }" data-toggle="tab"
                                (click)="changeImg(i)" role="tab">{{i+1}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h5>{{this.product.ProductsName}}</h5>
                    <div *ngIf="this.more">
                        <div [innerHTML]="this.product.ProductsDescription">
                        </div>
                    </div>

                    <span *ngIf="!this.more" (click)="this.more = true" class="more">...Ler mais</span>
                    <h4 class="mt-3" style="color: #01b1af;">R$ {{this.formatDecimal(this.product.ProductsPrice)}}</h4>
                    <br>
                    <div class="row">
                        <div class="col-12 justify-content-center" style="text-align: center;">
                            <div class="alert alert-danger" role="alert">
                                Você precisa estar logado para comprar. <a
                                    href="https://app.pipooh.com.br/?refRemarketing=site-principal&ref=site-principal">Logar
                                    agora.</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(modalProduto)"
    data-target="#openModalProduto">Launch demo modal</button>
<app-footer></app-footer>