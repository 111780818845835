<app-header></app-header>
<div class="row justify-content-center" style="margin: 25px;">
    <div class="col-lg-6" >
        <div class="section-title" style="text-align: center;">
            <h2>Contato</h2>
        </div>
        <p style="text-align: center;">Entre em contato com a Pipooh.</p>

        <div class="dados">
            <div class="dados-icon">
                <i class="fa fa fa-mobile"></i>
            </div>
            <div class="dados-text">
                <span>Dúvidas ou televendas</span><br>
                <a  href="https://wa.me/5511988829676?text=Assunto.">+55 11 98882-9676</a>
            </div>
        </div>
        <div class="dados">
            <div class="dados-icon">
                <i class="fa fa-envelope"></i>
            </div>
            <div class="dados-text">
                <span>Email</span>
                <p>atendimento@pipooh.com</p>
            </div>
        </div>
        <div class="dados" style="text-align: left;">
            <h4>Fale conosco</h4>
            <p>Se preferir deixe um comentário e nós entramos em contato.</p>
            <div class="row" style="margin-right: 15px;">
                <div class="col-6">
                    <input type="text" [(ngModel)]="nome" placeholder="Nome">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="email" placeholder="Email">
                </div>
                <br>
                <div class="col-12">
                    <textarea name="" [(ngModel)]="mensagem" style="resize: none; height: 150px" placeholder="Mensagem" id="" cols="30"
                        rows="8"></textarea>
                </div>
                <div class="col-12">
                    <a (click)="enviar()" style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;">Enviar</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <img src="../../assets/img/kid-heahphone.jpg" width="90%" alt="">
    </div>
</div>
<app-footer></app-footer>