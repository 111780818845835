import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private _router: Router,) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    var myParam = urlParams.get('id');
    if (myParam) {
      this._router.navigate(['/cha-fralda', { 'id':  myParam}]);
    }
  }

}
