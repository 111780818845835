<footer class="footer">

    <div class="container">
        <div class="row" style="text-align: center;">


            <div class="col-lg-4 footer-box">
                <div>
                    <div class="" >
                            <a href="https://www.facebook.com/MeuChaFralda.com.br/?ref=page_internal" style="font-size: 35px;"><i
                                class="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/pipooh.com.br/" style="font-size: 35px;"><i class="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 footer-box">
                <div>
                    <div class="" >
                        <a href="" >
                            <img src="assets/img/LOGO_CARTEIRA.png" width="60%"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 footer-box">
                <div>
                    <!-- <div class="" >
                            <img src="assets/img/aws.png" width="20%">
                            <img src="assets/img/mysql.png" width="20%">
                            <img src="assets/img/download (6).png" width="25%">
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 footer-box">
                <div class="footer-widget">
                    <a href="parceiro" style="text-decoration: underline;">Quero ser parceiro</a><br>
                    <a href="afiliado" style="text-decoration: underline;">Quero ser afiliado</a><br>
                    <a href="empresarial" style="text-decoration: underline;">Conta empresarial</a>
                </div>
            </div>

            <div class="col-lg-4 footer-box">
                <div class="footer-widget">
                    <a href="contato" style="text-decoration: underline;">Contato</a><br>
                    <a href="troca" style="text-decoration: underline;">Troca</a>
                </div>
            </div>
            <div class="col-lg-4 footer-box">
                <div class="footer-widget">
                    <div class="footer-box">
                        <p>CNPJ: 37.240.661/0001-02</p>
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div class="copyright-reserved" style="text-align: center;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright-text footer-widget">
                        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                        Copyright &copy;
                        <script>document.write(new Date().getFullYear());</script>
                        Todos os direitos reservados Pipooh
                        <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                    </div>
                    <div class="payment-pic ">
                        <img src="assets/img/payment-method.png" alt="">
                    </div>


                </div>
            </div>
        </div>
    </div>
</footer>