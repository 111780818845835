import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoriaEstabelecimentoService } from 'src/app/_services/categoria-estabelecimento.service';
import { EstabelecimentoService } from 'src/app/_services/estabelecimento.service';
@Component({
  selector: 'app-estabelecimentos',
  templateUrl: './estabelecimentos.component.html',
  styleUrls: ['./estabelecimentos.component.css']
})
export class EstabelecimentosComponent implements OnInit {

  constructor(private _estabelecimentoService: EstabelecimentoService,
    private _categoriaEstabelecimentoService: CategoriaEstabelecimentoService,
    private modalService: NgbModal,
  ) { }
  // Estabelecimento
  estabelecimento: any = [];
  estabelecimentos: any = [];
  estabelecimentoDetalhe: any = [];
  endereco: any;
  //Filtro
  nomeEstabelecimento: any;
  busca: any;
  //Paginação
  page = 1;
  pageSize = 12;
  collectionSize = [];
  //
  loading: any = 0;
  loadingBusca: any = 0;
  // categoria
  categorias: any = [];
  categoriaSelecionada: any = "";
  //modal
  closeResult = '';
  ngOnInit(): void {
    this.getSearch();
    this.buscarCategorias();
  }
  buscarCategorias() {
    this._categoriaEstabelecimentoService.selecionarCategoria({}).then(data => {
      this.categorias = data.payload;
    })
  }
  buscarEstabelecimentoLista(busca, categoria) {
    this.loadingBusca = 1;
    this._estabelecimentoService.buscarEstabelecimentos({ "busca": busca, "categoria": categoria }).then(data => {
      var aux = data;
      this.estabelecimentos = aux.payload;
      this.collectionSize = this.estabelecimentos.length;
      this.loadingBusca = 0;
    });
    this.nomeEstabelecimento = null;

  }

  getSearch() {
    this.buscarEstabelecimentoLista(this.busca, this.categoriaSelecionada);

  }

  abrirEstabelecimento(estabelecimento, content) {
    this.estabelecimentoDetalhe = [];
        this.estabelecimentoDetalhe = estabelecimento;
        this.endereco = this.estabelecimentoDetalhe.endereco + " " + this.estabelecimentoDetalhe.numero + " " + this.estabelecimentoDetalhe.cidade + " " + this.estabelecimentoDetalhe.uf;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
}

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
