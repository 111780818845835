import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../_services/helper.service';
@Component({
  selector: 'app-afiliado',
  templateUrl: './afiliado.component.html',
  styleUrls: ['./afiliado.component.css']
})
export class AfiliadoComponent implements OnInit {
  nome: any;
  email: any;
  redes: any;
  telefone: any;
  constructor( private toastr: ToastrService,
    private _helperService: HelperService) { }

  ngOnInit(): void {
  }
  enviar(){
    if (this.email == "" || this.email == null) {
      this.toastr.error('Email obrigatorio!');
      return;
    }
    if (this.telefone == "" || this.telefone == null) {
      this.toastr.error('Telefone obrigatorio!');
      return;
    }
    this._helperService.emailAfiliado({ "email": this.email, "nome": this.nome, "telefone": this.telefone, "redes": this.redes }).then(data => {
      this.toastr.success('Solicitação enviada com sucesso!');
    })
  }

}
