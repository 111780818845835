<!-- Header -->
<nav class="navbar navbar-expand-lg navbar-light shadow">
    <div class="container d-flex justify-content-between align-items-center">
        <a class="navbar-brand text-success logo h1 align-self-center d-none d-lg-block" href="#">
            <img src="assets/img/LOGO_CARTEIRA.png" width="50%" style="width: 180px">
        </a>
        <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
            data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>


        <div class=" collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="templatemo_main_nav">
            <div class="flex-fill">

                <ul class="nav navbar-nav d-flex justify-content-end mx-lg-auto" style="margin: 20px;">
                    <li class="nav-item">
                        <a class="nav-link d-lg-none" href="/">Home</a>
                    </li>
                    &nbsp;&nbsp;
                    <li class="nav-item">
                        <a class="nav-link" fragment="comoFunciona" (click)="ancora('planos')">Planos</a>
                    </li>
                    &nbsp;&nbsp;
                    <li class="nav-item">
                        <a class="nav-link" fragment="organize" (click)="ancora('assinatura')">O Clube</a>
                    </li>
                    &nbsp;&nbsp;
                    <li class="nav-item">
                        <a class="nav-link" fragment="parceiro" (click)="ancora('pulpa')">Pulpa</a>
                    </li>
                    &nbsp;&nbsp;
                    <li class="nav-item">
                        <a class="nav-link" fragment="concierge" (click)="ancora('concierge')">Concierge</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</nav>

<!-- Close Header -->

<div style="padding: 20px;">


    <div class="row">
        <div class="col-6">
            <div style="text-align: center; align-items: center;" class="d-lg-none">
                <img src="/assets/img/lancamento-assinatura/MASCOTES.png" alt="" width="130%">

            </div>
            <div style="text-align: center; align-items: center;" class="d-none d-lg-block ">
                <img src="/assets/img/lancamento-assinatura/MASCOTES.png" alt="" width="100%">

            </div>

        </div>
        <div class="col-6">
            <div style="text-align: end;" class="d-lg-none">
                <h2 style="color: #00B2AF; font-size: 5vw; font-weight: 700;">ASSINATURA</h2>
                <h2 style="color:#00B2AF; font-size: 10vw; font-weight: 900; margin-left: -50px;">PIPOOH</h2>
                <!-- <p style="font-weight: 600; font-size: 4vw;">COM OFERTA ESPECIAL.
                </p> -->
            </div>
            <div style="text-align: end;" class="d-none d-lg-block ">
                <h2 style="color: #00B2AF; font-size: 3vw; font-weight: 700;">ASSINATURA</h2>
                <h2 style="color:#00B2AF; font-size: 7vw; font-weight: 900; margin-left: -50px;">PIPOOH</h2>
                <!-- <p style="font-weight: 600; font-size: 3vw;">COM OFERTA ESPECIAL.
                </p> -->
            </div>



        </div>

    </div>
    <!-- <div class="col-12">
        <div class="timer">
            <div class="row justify-content-center">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-3">{{daysToDday}}</div>
                        <div class="col-3">{{hoursToDday}}</div>
                        <div class="col-3">{{minutesToDday}}</div>
                        <div class="col-3">{{secondsToDday}} </div>

                        <div class="col-3">Dias</div>
                        <div class="col-3">Hrs</div>
                        <div class="col-3">Min</div>
                        <div class="col-3">Seg</div>
                    </div>
                </div>


            </div>
        </div>

    </div> -->
    <div class="row">
        <div class="col-12" style="background-color: #FDBDD3; border-radius: 15px;">
            <h2 style="color:#00B2AF; text-align:center;font-weight:700;">Planos</h2>
        </div>
    </div>
    <div class="row row-eq-height" id="planos" style="margin-top: 25px;">
        <div class="col-lg-3 col-6 planoCol " style="margin-top: 25px;">
            <div class="planos align-text-bottom" style="background-color: #00B1AE; height: 100%;">
                <h2 class="planosTitle">BABY</h2>
                <!-- <p class="precoAntes">DE R$ 19,90</p> -->
                <p class="precoDepois"> POR R$ 24,90  <span>(mensais)</span></p>
                <p style="font-size: 12px; color: white;line-height: 0%;">assinatura</p>

                <div class="descricaoPlanos">
                    <p class="descricaoText "> Frete grátis na Pipooh</p>
                    <p class="descricaoText"><a style="color: #fff;"
                        href="https://clube.pipooh.com.br/">clube.pipooh.com.br</a></p>
                    <p class="descricaoText"> Previdência na Pulpa</p>
                    <p class="descricaoText">Acesso a compra de 2 vouchers especiais</p>
                    <p class="descricaoText">5.000 pontos</p>
                    <!-- <p class="descricaoText">Clube De Descontos</p> -->

                </div>

            </div>
            <div class="assine "
                style="background-color: #00B1AE; border-radius: 25px;margin-bottom:15px ;margin-top: 10px;"
                (click)="this.valor=24.9; plano = 'Baby'; open(validarEmail);">
                <h2>ASSINE</h2>
            </div>
        </div>
        <div class="col-lg-3 col-6 planoCol" style="margin-top: 25px;">
            <div class="planos" style="background-color: #FBE393;height: 100%;">
                <h2 class="planosTitle" style="color: #00B1AE;">INFANT</h2>
                <!-- <p class="precoAntes" style="color: #00B1AE;">DE R$ 59,90</p> -->
                <p class="precoDepois" style="color: #00B1AE;"> POR R$ 49,90 <span>(mensais)</span></p>
                <p style="font-size: 12px; color: #00B1AE;line-height: 0%;">assinatura</p>

                <div class="descricaoPlanos">
                    <p class="descricaoText" style="color: #00B1AE;"> Frete grátis na Pipooh</p>
                    <p class="descricaoText" style="color: #00B1AE;"><a
                            href="https://clube.pipooh.com.br/">clube.pipooh.com.br</a></p>
                    <p class="descricaoText" style="color: #00B1AE;">Previdência na Pulpa</p>
                    <!-- <p class="descricaoText" style="color: #00B1AE;">Assinatura Baby Concierge</p> -->
                    <p class="descricaoText" style="color: #00B1AE;">Acesso a compra de 4 vouchers especiais</p>
                    <p class="descricaoText" style="color: #00B1AE;">15.000 pontos</p>
                    <p class="descricaoText" style="color: #00B1AE;">Produtos na promoção ilimitados</p>


                </div>
            </div>
            <div class="assine "
                style="background-color: #FBE393; border-radius: 25px;margin-bottom:15px ;margin-top: 10px;"
                (click)="this.valor=49.9; plano = 'Infant'; open(validarEmail);">
                <h2 style="color: #00B1AE;">ASSINE</h2>
            </div>
        </div>
        <div class="col-lg-3 col-6 planoCol" style="margin-top: 25px;">
            <div class="planos" style="background-color: #FBE393;height: 100%;">
                <h2 class="planosTitle" style="color: hsl(179, 100%, 35%);">KIDS</h2>
                <p class="precoAntes" style="color: #00B1AE;text-decoration: line-through;">DE R$ 119,90</p>
                <p class="precoDepois" style="color: #00B1AE;"> POR R$ 59,45 <span>(mensais)</span></p>
                <p style="font-size: 12px; color: #00B1AE;line-height: 0%;">assinatura</p>
                <div class="descricaoPlanos">
                    <p class="descricaoText" style="color: #00B1AE;"> Frete grátis na Pipooh</p>
                    <p class="descricaoText" style="color: #00B1AE;"><a
                            href="https://clube.pipooh.com.br/">clube.pipooh.com.br</a></p>
                    <p class="descricaoText" style="color: #00B1AE;">Previdência na Pulpa</p>
                    <!-- <p class="descricaoText" style="color: #00B1AE;">Assinatura Baby Concierge</p> -->
                    <p class="descricaoText" style="color: #00B1AE;">Acesso a compra de 10 vouchers especiais</p>
                    <p class="descricaoText" style="color: #00B1AE;">30.000 pontos</p>
                    <p class="descricaoText" style="color: #00B1AE;">Cashback dobrado</p>
                    <p class="descricaoText" style="color: #00B1AE;">Produtos na promoção ilimitados</p>



                </div>
            </div>
            <div class="assine "
                style="background-color: #FBE393; border-radius: 25px;margin-bottom:15px ;margin-top: 10px;"
                (click)="this.valor=119.9; plano = 'Kids'; open(validarEmail);">
                <h2 style="color: #00B1AE;">ASSINE</h2>
            </div>
        </div>
        <div class="col-lg-3 col-6 planoCol" style="margin-top: 25px;">
            <div class="planos" style="background-color: #00B1AE;height: 100%;">
                <h2 class="planosTitle">CHILD</h2>
                <!-- <p class="precoAntes">DE R$ 199,90</p> -->
                <p class="precoDepois"> POR R$ 179,90 <span>(mensais)</span></p>
                <p style="font-size: 12px; color: white;line-height: 0%;">assinatura</p>

                <div class="descricaoPlanos">
                    <p class="descricaoText"> Frete grátis na Pipooh</p>
                    <p class="descricaoText"><a style="color: #fff;"
                            href="https://clube.pipooh.com.br/">clube.pipooh.com.br</a></p>
                    <p class="descricaoText">Previdência na Pulpa</p>
                    <!-- <p class="descricaoText">Assinatura Baby Concierge</p> -->
                    <p class="descricaoText">Acesso a compra de 10 vouchers especiais</p>
                    <p class="descricaoText">35.000 pontos</p>
                    <!-- <p class="descricaoText">Bônus: R$ 65 em créditos</p> -->
                    <p class="descricaoText">R$ 50 mensais na Pulpa</p>
                    <p class="descricaoText">Cashback dobrado</p>
                    <p class="descricaoText">Produtos na promoção ilimitados</p>
                </div>
            </div>
            <div class="assine "
                style="background-color: #00B1AE; border-radius: 25px;margin-bottom:15px ;margin-top: 10px;"
                (click)="this.valor=179.9; plano = 'Child'; open(validarEmail);">
                <h2>ASSINE</h2>
            </div>
        </div>
    </div>
    <div class="row row-eq-height" style="margin-top: 20px; margin-bottom: 20px;">
        <div class="col-lg-6 d-lg-none">
            <div class="d-flex align-items-center" style="text-align: center;">
                <div style="display: block;">
                    <img src="/assets/img/lancamento-assinatura/CARTOES.png" alt="" width="90%">

                </div>


            </div>
        </div>
        <div class="col-lg-6 " id="assinatura">
            <div class="assinatura">
                <h3>Conheça a assinatura</h3>
                <h2>PIPOOH</h2>
                <p>Vantagens para o seu bebê que você não vai ver em nenhuma outra carteira digital</p>
                <ul>
                    <li>FRETE GRÁTIS: em todos os planos você resgata seus créditos na loja PIPOOH com frete grátis para
                        qualquer
                        região do Brasil (resgates acima de R$ 150,00);</li>
                    <li>CLUBE DE DESCONTO: uma rede com centenas de parceiros que oferecem até 60% de desconto,
                        conteúdos
                        gratuitos, sorteios bimestrais, clube de revistas e mais de 2 mil farmácias via Vidalink;</li>
                    <li>PONTOS: os pontos na PIPOOH podem ser utilizados para a compra de produtos, crescimento na
                        categoria de
                        acesso às promoções e ainda podem ser transferidos para uma previdência do seu bebê (Pulpa);
                    </li>
                    <li>BÔNUS: é dinheiro na sua conta para consumo em parceiros ou no e-commerce PIPOOH</li>
                </ul>
            </div>

        </div>
        <div class="col-lg-6 d-none d-lg-block  ">
            <div class="d-flex align-items-center" style="text-align: center; ">
                <div style="display: block;">
                    <img src="/assets/img/lancamento-assinatura/CARTOES.png" alt="" width="90%"
                        style="display: block;    border-radius: 25px; ">

                </div>

            </div>
        </div>
        <div class="row row-eq-height" style="margin-top: 20px; margin-bottom: 20px;">
            <div class="col-lg-6" style="text-align: center; margin-bottom: 10px;">
                <img src="/assets/img/lancamento-assinatura/PULPA.jpg" alt="" width="90%" style="border-radius: 25px">
            </div>
            <div class="col-lg-6" id="pulpa" style="margin-bottom: 10px;">
                <div class="pulpa  d-flex align-items-center">
                    <div style="display: block;">
                        <h2 style="font-weight: 700;">PULPA</h2>
                        <p>O PRESENTE QUE CRESCE JUNTO COM A CRIANÇA</p>
                        <p>Converta todos os seus pontos PIPOOH em um plano de previdência do bebê. A cada 350.000
                            pontos, um total
                            de
                            R$ 7.500 gastos na compra de produtos e serviços pela plataforma, o valor de R$ 100 (mínimo)
                            é aportado
                            para a
                            previdência do seu filho via conta da Pulpa que deverá ser aberta e linkada à PIPOOH. A
                            transferência não
                            é
                            automática e deverá ser solicitada pelo responsável</p>
                        <p>Garanta o futuro do seu filho sem consumir nada a mais por isso.</p>
                        <p>No PLANO CHILD a PIPOOH garante o aporte de R$ 600 no ano, via Pulpa, para a previdência.</p>
                        <p>A Previdência Pulpa é administrada via a maior seguradora do Brasil, a ICATU.</p>
                        <p>Consulte mais informações em Pulpa.com.br</p>
                    </div>


                </div>
            </div>
        </div>
        <div class="row row-eq-height">
            <div class="col-lg-6">
                <img src="/assets/img/lancamento-assinatura/BABY CONCIEGE.jpg" alt="" width="90%"
                    style="border-radius: 25px">

            </div>
            <!-- <div class="col-lg-6" id="concierge">
                <div class="concierge d-flex align-items-center">
                    <div style="display: block;">
                        <h2 style="font-weight: 700; display: inline-block;">BABY CONCIERGE</h2>
                        <p>O Baby Concierge é um clube de assinaturas do universo materno-infantil que oferece descontos
                            e vantagens
                            em
                            marcas e profissionais diversos que atendem a este público.</p>
                        <p>Os assinantes têm acesso a descontos e vantagens em cerca de 180 marcas e 80 profissionais de
                            cerca de 40
                            especialidades</p>
                    </div>

                </div>

            </div> -->
        </div>
    </div>

    <!-- Modal  -->

    <ng-template #validarEmail let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding: 25px;">
            <p>Insira seu e-mail cadastrado na Pipooh</p>
            <input type="text" class="form-control" [(ngModel)]="email" placeholder="Digite seu e-mail">
            <br>
            <label for=""><input type="checkbox" [(ngModel)]="termos" id="termosEcondicoes" name="termosEcondicoes"> Eu concordo com os <a   style="color: #01B1AF;" (click)="open(termosCondicoes)">Termos e condições de uso</a> </label>
            <p><strong>Os benefícios estarão disponíveis em até 48H após a confirmação da assinatura</strong></p>
            <a class=" btn " (click)="inserirPedido()"
                style="color: white; padding: 10px; background-color: #01B1AF; display: block; font-size: 15px !important;">
                Assinar
            </a>
            <a class="btn btn-primary " style="background-color: #EF7300; color: white;border-color: #EF7300;
        font-size: 20px; margin-top: 10px; width: 100%;" href="https://app.pipooh.com.br/?ref=assinatura">Abrir conta gratuitamente</a>
        </div>
    </ng-template>

    <ng-template #termosCondicoes let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="padding: 25px;">
            <p>Esses Termos e Condições Gerais de Uso regulamentam o uso do serviço do Site de
                Internet www.pipooh.com.br, que a Pipooh Comercio de Produtos Infantis Ltda
                disponibiliza aos usuários do presente Site Eletrônico, conforme condições abaixo mencionadas.
            </p>
            <p>A utilização deste Site implica na plena aceitação de seu conteúdo pelo usuário. O
                acesso a conteúdo e uso dos serviços do Site está disciplinado por Políticas, Termos e
                Regulamentos, que poderão complementar estes Termos e Condições Gerais de Uso.
            </p>
            <p>O Usuário deverá tomar ciência de todas as Políticas antes de se cadastrar ou de se
                utilizar de seus serviços.
            </p>
            <p>I - DA ADESÃO AO CLUBE DE ASSINATURA PIPOOH DA CONTRATAÇÃO DE PLANOS</p>
            <p>2. Estão aptos a associar-se ao CLUBE PIPOOH somente os Participantes e com conta aberta na PIPOOH que
                possuam CPF/MF válido no Brasil, nos termos fixados pela Secretaria da Receita Federal do Brasil. Para
                tanto, basta o Participante da CARTEIRA DIGITAL PIPOOH optar pela associação ao Clube de Assinatura
                PIPOOH na opção disponível em sua Conta, mediante a aceitação dos Termos e Condições, da escolha do
                plano de vantagens e benefícios a que terá direito, bem como efetuar o pagamento mensal do valor
                referente ao Plano desejado, doravante "Plano" ou "Planos", conforme item II deste instrumento.</p>
            <p>3. A ADESÃO DOS PARTICIPANTES da CARTEIRA DIGITAL PIPOOH AO CLUBE DE ASSINATURA PIPOOH PODE SER FEITA
                PELO SITE DA PIPOOH EM SUA ÁREA LOGADA (AAP.PIPOOH.COM.BR), ONDE ESTÃO DESCRITOS OS TERMOS E CONDIÇÕES
                DO CLUBE SMILES, OS PLANOS, VALORES DAS MENSALIDADES E A DESCRIÇÃO DOS BENEFÍCIOS E VANTAGENS DO CLUBE A
                QUE O PARTICIPANTE DO PROGRAMA SMILES TERÁ DIREITO, OU POR MEIO DE CANAIS DISPONIBILIZADOS PELA PIPOOH,
                DE TEMPOS EM TEMPOS.</p>
            <p>4. APÓS TER LIDO, ENTENDIDO E CONCORDADO COM OS TERMOS E CONDIÇÕES - CLUBE DE ASSINATURAS PIPOOH, O
                PARTICIPANTE DO PROGRAMA CARTEIRA DIGITAL PIPOOH PODERÁ MANIFESTAR SUA ADESÃO AO CLUBE DE ASSINATURAS
                PIPOOH DA SEGUINTE FORMA:
                a) aceitar os Termos de Condições – Clube de Assinaturas Pipooh;
                b) selecionar o Plano desejado dos benefícios e vantagens, caso aplicável, e
                c) informar os dados de seu cartão de crédito para realização dos pagamentos das mensalidades do Clube
                de Assinaturas Pipooh vinculado ao Plano desejado e escolher o dia de vencimento de sua mensalidade. O
                Participante da CARTEIRA DIGITAL PIPOOH poderá escolher qualquer uma delas, que não poderá ser alterada.
            </p>
            <p>4.1 O Associado poderá, uma vez aderido ao Clube de Assinaturas Pipooh, solicitar a alteração de seu
                Plano atualmente contratado para outros Planos disponibilizados pela PIPOOH. Para tanto, basta que o
                Associado solicite a adesão do novo Plano através do site app.pipooh.com.br ou de outros canais
                disponibilizados pela PIPOOH. As regras referentes ao novo Plano contratado, valores da mensalidade dos
                Planos e os Benefícios e Vantagens a ele vinculados estão disponíveis neste Regulamento, na Tabela de
                Mensalidade dos Planos e Benefícios e Vantagens divulgada na Internet, por meio do site
                http://www.pipooh.com.br/clube-pipooh-planosebeneficios. Adicionalmente, o Associado deverá observar as
                seguintes condições:</p>
            <p>4.1.1 Somente os Associados cujos pagamentos de sua mensalidade estiverem em dia poderão requerer a
                mudança de Planos.</p>
            <p>4.1.2. ADESÃO À MUDANÇA DE PLANOS PELO ASSOCIADO: A adesão do Associado ao novo Plano deverá ser
                realizada através do site app.pipooh.copm.br ou de outros canais disponibilizados pela Pipooh, desde que
                os pagamentos de suas mensalidades estejam em dia.</p>
            <p>4.1.3. A solicitação de mudança de Plano poderá ser realizada a qualquer momento pelo Associado, mas esta
                solicitação só será efetivada a partir do pagamento da nova mensalidade lançada no cartão de crédito do
                Associado, que poderá ocorrer no próprio mês da solicitação ou não. Assim, após a solicitação do
                Associado pela mudança ao novo Plano do Clube de Assinaturas Pipooh, as novas condições do plano de
                Benefícios e Vantagens vinculados a este, somente serão válidas a partir do pagamento da nova
                mensalidade lançada no cartão de crédito indicado pelo Associado para o pagamento das mensalidades do
                Clube de Assinaturas Pipooh quando da sua adesão, conforme item II do presente Regulamento.
                Por exemplo: O vencimento da mensalidade do Associado a Carteira Digital Pipooh é todo dia 15. No dia 19
                de um determinado mês, o Associado solicita a mudança de Plano. A mudança só será efetivada no dia 15 do
                mês subsequente ao da solicitação, caso a solicitação de pagamento seja aprovada pelo emissor do cartão
                de crédito. Em caso de pagamento recusado da nova mensalidade do novo Plano pelo emissor do cartão de
                crédito do Associado, o Associado permanecerá no Plano vigente antes da solicitação de mudança e com seu
                Plano suspenso até a sua regularização, nos termos do item II do presente Regulamento. Assim, a mudança
                de Plano só será efetuada após a regularização do pagamento da mensalidade atual do Associado, de forma
                automática.
            </p>
            <p>4.1.4. A PIPOOH somente acatará a primeira solicitação de mudança de Plano realizada pelo Associado
                dentro do período correspondente a cada ciclo de pagamento da mensalidade do Clube PIPOOH do Associado.
                Caso o Associado solicite mais de um pedido de mudança de Plano dentro de um mesmo ciclo de pagamento da
                mensalidade do Clube, deverá aguardar o processamento do primeiro pedido e solicitar, novamente, no
                ciclo de pagamento subsequente, a nova mudança de Plano.</p>
            <p>4.1.5 Os Associados que tiverem aderido inicialmente ao Clube de Assinatura PIPOOH por meio de ações
                promocionais e cancelado suas respectivas assinaturas deverão aguardar o prazo de 365 (trezentos e
                sessenta e cinco) dias contados da data da última adesão para aderirem ao Clube Assinaturas PIPOOH por
                meio de nova ação promocional de adesão. No entanto, o Participante da Carteira Digital Pipooh poderá, a
                qualquer tempo, aderir novamente ao Clube, desde que tal adesão não seja por meio de uma ação
                promocional.</p>
                <p>4.1.5.1. Do mesmo modo, os Associados que tiverem participado de ações promocionais de mudança de Plano deverão aguardar o prazo de 365 (trezentos e sessenta e cinco) dias contados da data da mudança de Plano promocional para participarem de uma nova ação promocional de mudança de Plano.</p>
                <p>4.1.5.2. Assim, o prazo de 365 (trezentos e sessenta e cinco) dias, contados da última adesão promocional do Clube, deverá ser respeitado para futuras participações do Associado em promoções de adesão ao Clube e, também para efeito de mudança de Plano promocional, ressalvando-se, contudo, que tais prazos não são cumulativos. Vejamos um exemplo:
                    Por exemplo: Um Participante que, em um determinado mês, tenha aderido ao Clube de Assinaturas PIPOOH por meio de uma ação promocional de adesão e cancele sua adesão após 6 (seis) meses de assinatura, somente poderá aderir novamente ao Clube, por meio de uma promoção, após decorridos 365 (trezentos e sessenta e cinco) dias da data de adesão promocional. Entretanto, caso esse mesmo Participante, no 7º (sétimo) mês contado da adesão promocional, venha a aderir novamente ao Clube PIPOOH, sem ser por meio de uma ação promocional, e no 8º (oitavo) mês participe de uma ação promocional de mudança de Plano, a PIPOOH autorizará a participação, pois o prazo de espera de 365 (trezentos e sessenta e cinco) dias seria válido apenas para ações promocionais de adesão, e não para ações promocionais de mudança de Plano.
                    </p>
                    <p>4.1.6. Caso o Participante da Carteira Digital PIPOOH adiquira ao Clube PIPOOH através de uma Promoção e ainda possua benefícios decorrentes desta promoção (Milhas Bônus, descontos em reais a receber, entre outros), em caso de solicitação de mudança de Plano na vigência destes benefícios, os mesmos serão cancelados. Para melhor esclarecimento desta condição, vejamos um exemplo:
                        Por Exemplo: Promoção "Milhas em Dobro". O Participante, se aderir ao Clube Smiles por meio da citada promoção, teria até o 6º (sexto) mês o direito ao recebimento de 1.000 (mil) Milhas Bônus. Uma vez efetuada a adesão ao Clube, por meio da promoção, caso o Associado solicite a mudança de Plano depois do pagamento da primeira mensalidade, ele não receberá a bonificação de 1.000 (mil) Milhas Bônus a partir do 2º mês.
                        </p>
                        <p>4.1.7 O Associado que tenha aderido ao Clube Smiles, seja por meio de uma ação promocional ou não, cuja mensalidade não seja mais comercializada pela PIPOOH ou, ainda, esteja sujeito às regras do Regulamento Original do Clube PIPOOH, e realize a mudança de Plano, nos termos aqui previstos e, posteriormente, decida realizar uma nova mudança de Plano, o Associado reconhece e concorda que estará sujeito às regras, mensalidades e condições dos Planos vigentes à época da solicitação desta última mudança requerida.</p>
                        <p>4.1.8 O Participante deverá permanecer no Clube durante, no mínimo, 12 (doze) meses. Caso o Participante saia do Clube ou deixe de pagar a mensalidade antes do prazo de 12 (doze) meses, as Milhas Bônus serão estornadas, e pagará uma multa financeira de 50% do montante restante.</p>
                        <p>II - DO VALOR DA MENSALIDADE E FORMA DE PAGAMENTO</p>
                        <p>5. Pela associação ao Clube PIPOOH, o Associado pagará à PIPOOH a remuneração descrita abaixo, cujo valor será informado, mensalmente, no extrato mensal da Conta PIPOOH e constará da Tabela de Mensalidade dos Planos e Benefícios e Vantagens disponibilizada na Internet, por meio do site http://www.pipooh.com.br/clube-pipooh-planosebeneficios </p>
                        <p>5.1 A majoração do valor da Mensalidade, respeitado o prazo de 12 (doze) meses contados da adesão do Associado ao Clube PIPOOH ou de seu último reajuste; ou a instituição de nova cobrança aplicável ao Associado será divulgada na forma da Tabela de Mensalidade dos Planos e Benefícios e Vantagens disponibilizada na Internet com, no mínimo, 90 (noventa) dias de antecedência à cobrança.</p>
                        <p>6. No momento da adesão, será cobrado o valor da primeira parcela para ativação da associação no Clube PIPOOH, conforme o Plano escolhido pelo Associado Clube PIPOOH disponível na Tabela de Mensalidade dos Planos e Benefícios e Vantagens divulgada na Internet, por meio do site http://www.pipooh.com.br/clube-pipooh-planosebeneficios. </p>
                        <p>7. O Associado deverá utilizar, como meio de pagamento das mensalidades do Clube PIPOOH, cartão de crédito na adesão ao Clube no qual as mensalidades serão debitadas automaticamente caso o Associado não se manifeste em contrário para encerramento de sua associação ao Clube. O Associado terá a opção de pagar as mensalidades com cartão de crédito das seguintes bandeiras: American Express, Diners Club, Mastercard, Visa.</p>
                        <p>7.1 A PIPOOH poderá, a seu critério, disponibilizar ao Participante CARTEIRA DIGITAL PIPOOH ou Associados outras formas de pagamento das mensalidades do Clube, as quais estarão disponibilizadas no site www.pipooh.com.br ou informadas no momento de sua adesão.</p>
                        <p>8. A PIPOOH dispensa tratamento estritamente seguro aos dados confidenciais do Participante da CARTEIRA DIGITAL PIPOOH e do Associado Clube PIPOOH</p>
                        <p>9. CASO, NA DATA DO PAGAMENTO DA MENSALIDADE, NÃO SEJA AUTORIZADA A TRANSAÇÃO PELO EMISSOR DO CARTÃO DE CRÉDITO INFORMADO PELO ASSOCIADO, POR QUALQUER MOTIVO, O ACESSO AOS BENEFÍCIOS DO CLUBE PIPOOH SERÁ SUSPENSO ATÉ A REGULARIZAÇÃO DO PAGAMENTO PELO ASSOCIADO.</p>
                        <p>9.1 SE POR QUALQUER MOTIVO O EMISSOR DO CARTÃO DE CRÉDITO NÃO AUTORIZAR A TRANSAÇÃO DE PAGAMENTO DA MENSALIDADE DO CLUBE, PODERÁ A PIPOOH, SE DISPONÍVEL ESTA FUNCIONALIDADE E POR SUA MERA LIBERALIDADE, ENCAMINHAR AO ASSOCIADO BOLETO DE PAGAMENTO, POR MEIO DO E-MAIL INFORMADO EM SEU CADASTRO, PARA PAGAMENTO DA MENSALIDADE NÃO AUTORIZADA PELO EMISSOR DO CARTÃO DE CRÉDITO.</p>
                        <p>10. O Associado poderá solicitar a alteração do cartão de crédito utilizado para pagamento da mensalidade do Clube PIPOOH desde que observadas as seguintes situações: <br>
                            a)   O Associado deseje alterar o cartão de crédito para os próximos pagamentos das mensalidades; <br>
                            b)   O Associado pretenda regularizar pagamentos em atraso. Nesta hipótese, a regularização das mensalidades em atraso deve ser efetuada pelo próprio Associado por meio do site da PIPOOH, dentro da área logada, ou seja, opção "Minha Conta".
                            </p>
                            <p>III - DOS BENEFÍCIOS E VANTAGENS DE PLANOS</p>
                            <p>11. OS BENEFÍCIOS E VANTAGENS DE CADA PLANO AQUI DESCRITOS NÃO SÃO CUMULATIVOS COM DEMAIS BENEFÍCIOS VINCULADOS À CATEGORIA DE UM PARTICIPANTE DA CARTEIRA DIGITAL.</p>
                            <p>12. Benefícios e Vantagens do Clube PIPOOH aos Associados:</p>
                            <p>a. Milhas Prêmio - o Associado terá direito a um crédito mensal de Milhas DO BEBE, conforme o Plano escolhido, as quais terão validade diferenciada ("Milhas Prêmio"). A quantidade mensal de Milhas Prêmio a ser creditada na Conta PIPOOH do Associado e o prazo da validade estendida estão descritos na Tabela de Mensalidade dos Planos e Benefícios e Vantagens divulgada na Internet, por meio do site http://www.pipooh.com.br/clube-pipooh-planosebeneficios. </p>
                            <p>a.1) O crédito mensal das Milhas Prêmio ocorrerá no momento em que o pagamento da mensalidade for processado com sucesso no cartão de crédito indicado pelo Associado, tanto na adesão como nos pagamentos subsequentes das mensalidades.</p>
                            <p>a.2) Estorno de Milhas Prêmio : A PIPOOH poderá realizar estorno de Milhas Prêmio, caso: (i) haja, comprovadamente, erro de crédito das Milhas Prêmio na Conta PIPOOH do Associado; ou (ii) seja identificado que as Milhas Prêmio foram adquiridas de forma ilícita ou irregular.</p>
                            <p>b. Acesso ILIMITADO a compra de produtos na Promoção - o Associado do Clube PIPOOH poderá ter, a critério exclusivo da PIPOOH, acesso antecipado a determinadas promoções da PIPOOH e de seus parceiros comerciais ("Parceiros"), como também tendo de forma ilimitada a compra de produtos na promoção</p>
                            <p>C. As MILHAS PIPOOH para todos os Associados ao Clube PIPOOH poderão converter e transferir para uma conta PULPA, que serão aportados na previdência da criança indicada no cadastro. O valor mínimo para a realização do aporte é de 350.000 mil pontos, que é convertido R$100,00 em aporte. </p>
                            <p>12.1. A QUANTIDADE DE MILHAS PRÊMIOS, PRAZO DA VALIDADE ESTENDIDA E ACESSO ANTECIPADO E ILIMITADO A PROMOÇÕES, ACESSO A DESCONTOS EXCLUSIVOS, LIBERAÇÃO DE TRANSFERÊNCIA DE PONTOS PARA A PULPA, CRÉDITOS BONIFICADOS ESTÃO DISPONÍVEIS NO LINK http://www.pipooh.com.br/clube-pipooh-planosebeneficios</p>
                            <p>13. AS PONTOS PRÊMIO E OS CRÉDITOS BONIFICADOS PROVENIENTES DAS ASSINATURAS SÃO QUALIFICÁVEIS PARA CRESCIMENTO DE CATEGORIA.</p>
                            <p>14. A PIPOOH, em parceria ou não com os seus Parceiros, poderá realizar promoções pontuais e exclusivas para a utilização dos Pontos PIPOOH por Associados que aderiram ao Clube PIPOOH em condições específicas não previstas neste instrumento e por período determinado. Tais promoções serão comunicadas com antecedência, através dos canais de comunicação da PIPOOH e/ou dos Parceiros.</p>
                            <p>IV - ENCERRAMENTO DOS TERMOS E CONDIÇÕES - CLUBE PIPOOH 
                                15. O ASSOCIADO PODERÁ REQUERER O CANCELAMENTO DA ASSINATURA DO CLUBE PIPOOH A QUALQUER MOMENTO, MEDIANTE AO PAGAMENTO DE MULTA. CASO O ASSOCIADO SOLICITE O CANCELAMENTO ANTES DOS 12 MESES DE VIGÊNCIA DA ASSINATURA. REFERENTE A 50% DO VALOR TOTAL RESTANTE AS 12 MESES. 
                                </p>
                                <p>16. OS ASSOCIADOS QUE TIVEREM ADERIDO AO CLUBE PIPOOH POR MEIO DE AÇÕES PROMOCIONAIS E CANCELADO SUAS RESPECTIVAS ASSINATURAS DEVERÃO AGUARDAR O PRAZO DE 365 (TREZENTOS E SESSENTA E CINCO) DIAS CONTADOS DA DATA DA ÚLTIMA ADESÃO PARA ADERIREM AO CLUBE PIPOOH POR MEIO DE NOVA AÇÃO PROMOCIONAL. NO ENTANTO, O PARTICIPANTE DA CARTIERA DIGITAL PIPOOH PODERÁ, A QUALQUER TEMPO, ADERIR NOVAMENTE AO CLUBE PIPOOH, DESDE QUE TAL ADESÃO NÃO SEJA POR MEIO DE UMA AÇÃO PROMOCIONAL.</p>
                                <p>17. O Associado pode desistir deste instrumento no prazo de 7 (sete) dias a contar da data de adesão e qualquer benefício utilizado neste período será revogado ao Associado ao Clube PIPOOH ou da solicitação de alteração do Plano. Caso esse direito de arrependimento seja exercido pelo Associado, os valores eventualmente pagos, a qualquer título, durante o prazo de reflexão, serão devolvidos, de imediato e todos os pontos prêmio, créditos bônus e fretes grátis serão automaticamente estornados.</p>
                                <p>18. Para solicitações de cancelamento da assinatura do Clube PIPOOH, a PIPOOH disponibiliza ao Associado um canal específico para este fim de atendimento.</p>
                                <p>19. Qualquer que seja a causa que motivou o Associado a solicitar o cancelamento de sua assinatura no Clube PIPOOH, a eficácia deste instrumento perdurará pelo tempo necessário e com a finalidade única de possibilitar o pleno cumprimento de todas as obrigações do Associado junto à PIPOOH e vice e versa.</p>
                                <p>20. Constatado, a qualquer tempo, o inadimplemento do Associado a Smiles poderá, a seu exclusivo critério, rescindir o presente instrumento, mediante comunicação escrita, considerando-se devidas todas as obrigações contratuais do Associado, além da suspensão do uso e do cancelamento dos Benefícios e Vantagens do Clube PIPOOH.</p>
                                <p>21. Constituirá, ainda, inadimplemento contratual, passível de aplicação das penalidades legais e contratuais cabíveis, a verificação pela PIPOOH, a qualquer tempo, de não serem verídicas ou completas as informações e comunicações prestadas pelo Associado, visando ao ingresso e/ou à permanência no Clube PIPOOH e/ou no NA CARTIERA DIGITAL PIPOOH, incluída a constatação de qualquer omissão ou ação irregular em relação.</p>
                                <p>22. O PRODUTO CLUBE PIPOOH PODERÁ SER EXTINTO PELA PIPOOH A QUALQUER TEMPO, MEDIANTE AVISO PRÉVIO AOS ASSOCIADOS COM 6 (SEIS) MESES DE ANTECEDÊNCIA, FICANDO ASSEGURADOS TODOS OS BENEFÍCIOS E VANTAGENS ATÉ O TÉRMINO DO PRAZO MENCIONADO.</p>
                                <p>V - ALTERAÇÕES DOS TERMOS E CONDIÇÕES - CLUBE PIPOOH. A SMILES PODERÁ MODIFICAR O TEXTO DESTE INSTRUMENTO, INCLUSIVE COM RELAÇÃO AO VALOR DAS MENSALIDADES, SEUS PLANOS E OS BENEFÍCIOS E VANTAGENS PREVISTOS NA TABELA DE MENSALIDADE DOS PLANOS E BENEFÍCIOS E VANTAGENS DIVULGADA NO SITE http://www.pipooh.com.br/clube-pipooh-planosebeneficios, DESDE QUE INFORME PREVIAMENTE TAIS MODIFICAÇÕES AOS PORTADORES COM, NO MÍNIMO 90 (NOVENTA) DIAS DE ANTECEDÊNCIA, POR QUALQUER MEIO DE COMUNICAÇÃO EFETIVA, INCLUINDO, MAS NÃO SE LIMITANDO A E-MAIL, EXTRATO MENSAL, OU AINDA, MEDIANTE REMESSA DO NOVO TERMOS E CONDIÇÕES AOS ASSOCIADOS A CRITÉRIO DA PIPOOH, PROCEDENDO AOS RESPECTIVOS REGISTROS EM CARTÓRIO DE REGISTRO DE TÍTULOS E DOCUMENTOS. SE O ASSOCIADO NÃO ESTIVER DE ACORDO COM AS MODIFICAÇÕES INTRODUZIDAS PODERÁ EXERCER O SEU DIREITO DE TERMINAR O INSTRUMENTO, SEM CUSTO ADICIONAL.</p>
                                <p>24. O PAGAMENTO DA MENSALIDADE, APÓS A CIÊNCIA DAS MODIFICAÇÕES CONTRATUAIS REFERIDAS NO ITEM 23 ACIMA IMPLICARÁ PLENA ACEITAÇÃO, PELO ASSOCIADO, DAS REFERIDAS ALTERAÇÕES.</p>
                                <p>VI - DISPOSIÇÕES ESPECIAIS</p>
                                <p>25. A PIPOOH não se responsabiliza pela qualidade, quantidade, vícios ou defeitos, ainda que ocultos, garantia e/ou assistência técnica de bens e/ou serviços adquiridos com os Pontos PIPOOH, bem como por diferenças de preços e/ou por quaisquer parcelamentos ou financiamentos negociados entre o Associado e o estabelecimento comercial parceiro da PIPOOH, exceto quando tais produtos forem resgatados diretamente com o e-commerce ou loja própria PIPOOH</p>
                                <p>26. Este instrumento obriga as partes, seus herdeiros e sucessores, sendo sempre aplicáveis a legislação brasileira, o Código de Proteção e Defesa do Consumidor e legislação correlata, em relação a eventuais omissões e/ou contradições.</p>
                                <p>27. O Associado, ao aderir ao presente Termos e Condições - Clube Smiles, autoriza, respeitada a legislação em vigor, o oferecimento, pela Smiles, de seus produtos e/ou serviços, desde que tal oferta não lhe ocasione qualquer custo, salvo se o Associado concordar expressamente com disposição em contrário.</p>
                                <p>28. Toda e qualquer comunicação escrita à PIPOOH deverá ser encaminhada para o endereço de sua sede social.
                                 </p>
                                 <p>29. Este instrumento passará a vigorar a partir de 01 de ABRIL de 2022 e terá validade por prazo indeterminado.</p>
                                 <p> 
                                    30. Todo e qualquer pagamento, a qualquer título, eventualmente devido pelas partes, decorrente das obrigações assumidas no presente instrumento, será exclusivamente cumprido pela PIPOOH no domicílio do Associado localizado no Brasil, sujeito às normas brasileiras.
                                    </p>
                                    <p>VII - FORO E LEGISLAÇÃO APLICÁVEL</p>
                                    <p>31. O presente Termos e Condições - Clube PIPOOH e sua execução, incluindo as obrigações de natureza financeira dele decorrentes, serão regidos, interpretados e executados de acordo com as leis vigentes na República Federativa do Brasil.</p>
                                    <p>32. O Foro do presente instrumento é o do domicílio do Associado, sendo este o único competente para dirimir eventuais dúvidas ou questões que se originem do presente instrumento, excluído qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
        </div>
        <div class="modal-footer" style="justify-content: flex-start;">
            <label for="termosEcondicoes"  class="btn btn-success" (click)="modal.close('Close click')">Concordo</label>
        </div>
    </ng-template>
    <app-footer></app-footer>