<app-header></app-header>
<!-- celular unico -->
<div *ngIf="gemeos==0"
  class="row justify-content-center d-sm-block d-md-none"
  style="margin-bottom: 15px; margin-top: 20px"
>
  <div class="col-8" style="text-align: center; background-color: #fff">
    <h3 style="color: #f8b3cf">Chá revelação</h3>
    <p style="color: #01b1af">E ai, qual o seu palpite?</p>

  </div>
  <div class="col-6" style="background-color: #f8b3cf; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">MENINA</h2>
    <img
      src="../../assets/img/revelacao/pipu_rosa.png"
      alt=""
      style="max-width: 70%"
    />
    <br />

    <a mat-raised-button  (click)="confirmDialog('menina')"

      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
  <div class="col-6" style="background-color: #01b1af; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">MENINO</h2>
    <img
      src="../../assets/img/revelacao/pipu_verde.png"
      alt=""
      style="max-width: 70%"
    />
    <br />
    <a mat-raised-button  (click)="confirmDialog('menino')"
      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
</div>

<!-- computador unico -->
<div *ngIf="gemeos==0"
  class="row justify-content-center "
  style="margin-bottom: 15px; margin-top: 10px"
>
  <div class="col-12 d-none d-md-block" style="text-align: center; background-color: #fff">
    <h3 style="color: #f8b3cf">Chá revelação</h3>
    <p style="color: #01b1af">E ai, qual o seu palpite?</p>

  </div>
  <div class="col-md-4 d-none d-md-block" style="background-color: #f8b3cf; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">MENINA</h2>
    <img
      src="../../assets/img/revelacao/pipu_rosa.png"
      alt=""
      style="max-width: 70%"
    />
    <br />
    <a mat-raised-button  (click)="confirmDialog('menina')"
      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
  <div class="col-md-4 d-none d-md-block" style="background-color: #01b1af; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">MENINO</h2>
    <img
      src="../../assets/img/revelacao/pipu_verde.png"
      alt=""
      style="max-width: 70%"
    />
    <br />
    <a mat-raised-button  (click)="confirmDialog('menino')"
      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
</div>



<!-- computador gemeos -->
<div *ngIf="gemeos==1"
  class="row justify-content-center "
  style="margin-bottom: 15px; margin-top: 10px"
>
  <div class="col-12 d-none d-md-block" style="text-align: center; background-color: #fff">
    <h3 style="color: #f8b3cf">Chá revelação</h3>
    <p style="color: #01b1af">E ai, qual o seu palpite?</p>

  </div>
  <div class="col-md-3 d-none d-md-block" style="background-color: #01b1af; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">MENINAS</h2>
    <img
      src="../../assets/img/revelacao/rosas.png"
      alt=""
      style="max-width: 70%"
    />
    <br />
    <a mat-raised-button  (click)="confirmDialog('meninas')"
      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
  <div class="col-md-3 d-none d-md-block" style="background-color: #f8b3cf; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">MENINOS</h2>
    <img
      src="../../assets/img/revelacao/verdes.png"
      alt=""
      style="max-width: 70%"
    />
    <br />
    <a mat-raised-button  (click)="confirmDialog('meninos')"
      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
  <div class="col-md-3 d-none d-md-block" style="background-color: #01b1af; text-align: center">
    <h2 style="color: white; margin-top: 20px; margin-bottom: 20px">CASAL</h2>
    <img
      src="../../assets/img/revelacao/casal.png"
      alt=""
      style="max-width: 70%"
    />
    <br />
    <a mat-raised-button  (click)="confirmDialog('casal')"
      style="font-size: 13px; margin-top: 30px; margin-bottom: 10px"
      class="btn btn-revelacao"
      >VOTAR</a
    >
  </div>
</div>




<!-- celular gemeos -->
<div *ngIf="gemeos==1"
  class="row justify-content-center d-sm-block d-md-none"
  style="margin-bottom: 15px; margin-top: 10px"
>
  <div class="col-12" style="text-align: center; background-color: #fff">
    <h3 style="color: #f8b3cf">Chá revelação</h3>
    <p style="color: #01b1af">E ai, qual o seu palpite?</p>

  </div>
  <div class="col-lg-3 " style="background-color: #01b1af; text-align: center">
    <div class="row">
      <div class="col-6" style="    display: flex;
      align-items: center;
      justify-content: center;
      ">
        <img
        src="../../assets/img/revelacao/rosas.png"
        alt=""
        style="max-width: 70%"
      />
      </div>
      <div class="col-6">
        <h2 style="color: white; margin-top: 20px; margin-bottom: 5px">MENINAS</h2>

        <a mat-raised-button  (click)="confirmDialog('meninas')"
          style="font-size: 13px; margin-top: 5px; margin-bottom: 10px"
          class="btn btn-revelacao"
          >VOTAR</a
        >
      </div>
    </div>

  </div>
  <div class="col-lg-3" style="background-color: #f8b3cf; text-align: center">
    <div class="row">
      <div class="col-6" style="    display: flex;
      align-items: center;
      justify-content: center;
      " >
        <img
        src="../../assets/img/revelacao/verdes.png"
        alt=""
        style="max-width: 70%"
      />
      </div>
      <div class="col-6">
        <h2 style="color: white; margin-top: 20px; margin-bottom: 5px">MENINOS</h2>

        <a mat-raised-button  (click)="confirmDialog('meninos')"
          style="font-size: 13px; margin-top: 5px; margin-bottom: 10px"
          class="btn btn-revelacao"
          >VOTAR</a
        >
      </div>
    </div>

  </div>
  <div class="col-lg-3" style="background-color: #01b1af; text-align: center">
    <div class="row">
      <div class="col-6" style="    display: flex;
      align-items: center;
      justify-content: center;;
      ">
        <img
        src="../../assets/img/revelacao/casal.png"
        alt=""
        style="max-width: 70%"
      />
      </div>
      <div class="col-6">
        <h2 style="color: white; margin-top: 20px; margin-bottom: 5px">CASAL</h2>


        <a mat-raised-button  (click)="confirmDialog('casal')"
          style="font-size: 13px; margin-top: 5px; margin-bottom: 10px"
          class="btn btn-revelacao"
          >VOTAR</a
        >
      </div>
    </div>

  </div>
</div>
<app-footer></app-footer>


