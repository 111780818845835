<div *ngIf="voucher" class="container hidden-xs" style="text-align: center;">
    <!-- <img src="https://img.pipooh.com.br/{{voucher.voucher}}" alt=""> -->
    <div class="row">

        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <div style="background-image: url('https://img.pipooh.com.br/{{voucher.voucher}}');">
                <br><br><br><br><br><br><br><br><br>
                <p style="font-size: 20px; padding-left: 30px; padding-right: 30px"><strong>PARABÉNS,
                        {{nome.toUpperCase()}} </strong></p>
                <p style='font-size: 17px; padding-left: 30px; padding-right: 30px;height: 160px;'> {{voucher.mensagem}}
                </p>
                <br>
                <p style='font-size: 17px; text-align: center; color: #000'>VALOR DO VOUCHER:</p>
                <p style='text-align: center; margin-bottom: 30px;'><b
                        style='font-size: 20px; border-radius: 20px; color: black; text-decoration: none; border: 2px solid; padding: 20px; background-color: #FFF;'>
                        R$ {{voucher.valor}} </b></p>
                <p style='font-size: 17px; text-align: center; color: #000'>COD DE VALIDAÇÃO:</p>
                <p style='text-align: center;'><b
                        style='font-size: 20px; border-radius: 20px; color: black; text-decoration: none; border: 2px solid; padding: 20px; background-color: #FFF;'>
                        {{voucher.codigo}} </b></p>
                <br><br><br>
                <p style='text-align: center; font-size: 15px; color: #000;'><mark
                        style='background-color: #fff'><strong>
                            &nbsp; Clique no botão abaixo e se cadastre para validar seu voucher &nbsp;
                        </strong></mark></p><br>
                <p style='text-align: center;'><a type='button' href='https://app.pipooh.com.br/'
                        style='font-size: 20px; border-radius: 20px; color: #000; text-decoration: none; border: 2px solid; padding: 20px; background-color: #fff;'>
                        VALIDAR VOUCHER </a></p>
                <p style='text-align: center; font-size: 15px; padding-left: 30px; padding-right: 30px'>O
                    voucher pode ser utilizado na PIPOOH ou em mais de 30 lojas parceiras.</p>
                <p style='text-align: center; font-size: 15px; padding-left: 30px; padding-right: 30px'><strong>
                        FRALDAS | HIGIENE | ENXOVAL | FESTA | VESTUÁRIO e muito mais...</strong></p>
                <p style='font-size: 15px; padding-left: 45px; text-align: left;'><img
                        src='https://img.pipooh.com.br/imagens/rh/voucher/icone.png' style='width: 4%'> Utilize
                    como, quando e onde desejar</p>
                <p style='font-size: 15px; padding-left: 45px; text-align: left;'><img
                        src='https://img.pipooh.com.br/imagens/rh/voucher/icone.png' style='width: 4%'> De forma
                    presencial ou on-line</p>
                <p style='font-size: 15px; padding-left: 45px; text-align: left;'><img
                        src='https://img.pipooh.com.br/imagens/rh/voucher/icone.png' style='width: 4%'> Parcial
                    ou integral.</p>
                <br>
                <br><br>
                <br><br><br>
                <br><br><br>
                <br><br>
                <br>
                <br>
                <!-- <br><br> -->
                <!-- <br> -->


            </div>
        </div>
    </div>

</div>
<div *ngIf="voucher" class="container visible-xs" style="text-align: center;">
    <!-- <img src="https://img.pipooh.com.br/{{voucher.voucher}}" alt=""> -->
    <div class="row">

        <div class="col-lg-3"></div>
        <div class="col-lg-6">
            <div style="background-image: url('https://img.pipooh.com.br/{{voucher.voucherMobile}}');">
                <br><br><br><br><br><br><br><br><br>
                <p style="font-size: 20px; padding-left: 30px; padding-right: 30px"><strong>PARABÉNS,
                        {{nome.toUpperCase()}} </strong></p>
                <p style='font-size: 15px; padding-left: 30px; padding-right: 30px;height: 180px;'> {{voucher.mensagem}}
                </p>
                <br>
                <p style='font-size: 17px; text-align: center; color: #000'>VALOR DO VOUCHER:</p>
                <p style='text-align: center; margin-bottom: 35px;'><b
                        style='font-size: 20px; border-radius: 20px; color: black; text-decoration: none; border: 2px solid; padding: 20px; background-color: #FFF;'>
                        R$ {{voucher.valor}} </b></p>
                <p style='font-size: 17px; text-align: center; color: #000'>COD DE VALIDAÇÃO:</p>
                <p style='text-align: center;'><b
                        style='font-size: 20px; border-radius: 20px; color: black; text-decoration: none; border: 2px solid; padding: 20px; background-color: #FFF;'>
                        {{voucher.codigo}} </b></p>
                <br><br><br>
                <p style='text-align: center; font-size: 15px; color: #000;'><mark
                        style='background-color: #fff'><strong>
                            &nbsp; Clique no botão abaixo e se <br> cadastre para validar seu voucher &nbsp;
                        </strong></mark></p><br>
                <p style='text-align: center;'><a type='button' href='https://app.pipooh.com.br/'
                        style='font-size: 20px; border-radius: 20px; color: #000; text-decoration: none; border: 2px solid; padding: 20px; background-color: #fff;'>
                        VALIDAR VOUCHER </a></p>
                <p style='text-align: center; font-size: 15px; padding-left: 30px; padding-right: 30px'>O
                    voucher pode ser utilizado na PIPOOH ou em mais de 30 lojas parceiras.</p>
                <p style='text-align: center; font-size: 15px; padding-left: 30px; padding-right: 30px'><strong>
                        FRALDAS | HIGIENE | ENXOVAL | FESTA | VESTUÁRIO e muito mais...</strong></p>
                <p style='font-size: 15px; padding-left: 45px; text-align: left;'><img
                        src='https://img.pipooh.com.br/imagens/rh/voucher/icone.png' style='width: 4%'> Utilize
                    como, quando e onde desejar</p>
                <p style='font-size: 15px; padding-left: 45px; text-align: left;'><img
                        src='https://img.pipooh.com.br/imagens/rh/voucher/icone.png' style='width: 4%'> De forma
                    presencial ou on-line</p>
                <p style='font-size: 15px; padding-left: 45px; text-align: left;'><img
                        src='https://img.pipooh.com.br/imagens/rh/voucher/icone.png' style='width: 4%'> Parcial
                    ou integral.</p>
                <br>
                <br><br>
                <br><br><br>
                <br><br><br>
                <br>
                <!-- <br><br> -->
                <!-- <br> -->
                <!-- <br><br>
                <br> -->


            </div>
        </div>
    </div>

</div>