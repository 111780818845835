<app-header></app-header>
<div class="row justify-content-center" style="margin: 25px;">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
        <div class="creditos-itens">


            <a (click)="um = 1" *ngIf="um == 0" style="color: #01b1af;"><i class="fa fa-plus" aria-hidden="true"></i>
                O que é uma carteira digital?
            </a>
            <a (click)="um = 0" *ngIf="um == 1" style="color: #01b1af;"><i class="fa fa-minus" aria-hidden="true"></i>
                O que é uma carteira digital?
            </a>
            <div class="" *ngIf="um == 1">
                <p>Basicamente, a carteira digital é um aplicativo onde você coloca dinheiro e armazena alguns dados
                    financeiros, como informações sobre seus cartões de crédito e débito, pagamentos e transferências
                    realizadas. Com esse recurso, você pode fazer compras em lojas físicas e virtuais apenas com seu
                    celular ou outro dispositivo digital, como os relógios inteligentes. Muita praticidade, né?</p>
                <p>Frente a tantas funcionalidades, podemos considerar que a carteira digital é o mais próximo que temos
                    de uma carteira do futuro. Com o armazenamento eletrônico de diferentes meios de pagamento, você
                    poderá até dispensar a boa e velha carteira de couro.
                </p>
            </div>
        </div>
        <!-- <br> -->
        <div class="creditos-itens">
            <a (click)="dois = 1" style="color: #01b1af;" *ngIf="dois == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                O que é Previdência?
            </a>
            <a (click)="dois = 0" style="color: #01b1af;" *ngIf="dois == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                O que é Previdência?
            </a>

            <p *ngIf="dois == 1">Além de ser um instrumento eficaz para complementar a aposentadoria paga pelo INSS, a
                previdência privada pode viabilizar projetos de vida como fazer um intercâmbio, pagar os estudos dos
                filhos e assim por diante.
            </p>
        </div>
        <div class="creditos-itens">
            <a (click)="tres = 1" style="color: #01b1af;" *ngIf="tres == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                O que é um programa de Pontos? </a>
            <a (click)="tres = 0" style="color: #01b1af;" *ngIf="tres == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                O que é um programa de Pontos? </a>

            <p *ngIf="tres == 1">O sistema de pontos funciona como um programa de recompensas, onde suas compras e
                contas pagar através da PIPOOH voltam em forma de benefícios para você trocar seus pontos por pontos
                Livelo, o maior programa de pontos e milhas do Brasil ou você transforma estes pontos em dinheiro para
                aportar na previdência do seu filho.
            </p>
            <p *ngIf="tres == 1">Na PIPOOH, você encontra diversas opções para acumular pontos:

            </p>
            <ul *ngIf="tres == 1">
                <li>Ganhando presentes </li>
                <li>Pagando as contas diárias dos seus filhos</li>
                <li>Clube PIPOOH</li>
                <li>Comprar nos parceiros </li>
                <li>Receber Pensão </li>
                <li>Receber benefícios empresariais </li>
                <li>Participar dos desafios (sobre educação e atividades em família) </li>
            </ul>

        </div>
        <div class="creditos-itens">
            <a (click)="quatro = 1" style="color: #01b1af;" *ngIf="quatro == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> O que é Cashback e como usar?</a>
            <a (click)="quatro = 0" style="color: #01b1af;" *ngIf="quatro == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> O que é Cashback e como usar?</a>

            <div *ngIf="quatro == 1">


                <p>Cashback significa “dinheiro de volta”. Ou seja, ganhar cashback significa receber de volta uma parte do dinheiro gasto em uma compra. Importante lembrar que o cashback é diferente do desconto, já que o desconto reduz o valor a ser pago na compra, já o cashback não altera este preço, mas te reembolsa parte do valor gasto, depois da compra. É aquela surpresa boa que torna sua compra ainda mais prazerosa, mesmo depois de finalizada.
                </p>

            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="cinco = 1" style="color: #01b1af;" *ngIf="cinco == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                A Pipooh tem algum custo?</a>
            <a (click)="cinco = 0" style="color: #01b1af;" *ngIf="cinco == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                A Pipooh tem algum custo?</a>

            <p *ngIf="cinco == 1">Não, A mamãe/ papai não tem nenhum tipo de custo ou taxa para abrir e manter a sua conta na PIPOOH e também é 100% gratuito todas as ferramentas disponíveis na plataforma, como: Organizar os eventos, participar do programa de pontos, vender ou doar produtos não utilizados, receber auxílios empresariais ou pensões alimentícias.</p>
        </div>
        <div class="creditos-itens">
            <a (click)="seis = 1" style="color: #01b1af;" *ngIf="seis == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                    Se não pago nada, como a PIPOOH ganha dinheiro? 
                </a>
            <a (click)="seis = 0" style="color: #01b1af;" *ngIf="seis == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                    Se não pago nada, como a PIPOOH ganha dinheiro? 
                </a>
            <div *ngIf="seis == 1">

                <p>A PIPOOH ganha um comissionamento pago pelo lojista que aceita os créditos da PIPOOH como forma de pagamento. A Mãe/ Pai não tem paga nenhum valor adicional em nenhum momento ou compra, pagando o preço do e-commerce da loja ou prateleira, no caso de lojas físicas. 
                </p>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="sete = 1" style="color: #01b1af;" *ngIf="sete == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                Como organizar todos os eventos infantis na PIPOOH? </a>
            <a (click)="sete = 0" style="color: #01b1af;" *ngIf="sete == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                Como organizar todos os eventos infantis na PIPOOH? </a>
                <ul *ngIf="sete == 1">
                    <li>Em menos de 5 minutos você abre uma conta digital na Pipooh.</li>
                    <li>Cadastra sua gestação e filhos.</li>
                    <li>Cria e personaliza o seu evento.</li>
                    <li>Compartilha de forma rápida e automática seu convite aos convidados.</li>
                    <li>Seus convidados compram os produtos em sua página personalizada.</li>
                    <li>Seus presentes viram créditos para serem utilizados para a compra de produtos/serviços direto com a Pipooh ou em lojas parceiras.</li>
                    <li>Ao abrir sua conta Pipooh já inicia seu programa de pontos, onde a cada 1 real ganho em presente ou em compras de crédito você ganha 50 pontos. 
                    </li>
                    <li>A mamãe/ papai realizando seu evento e abrindo a conta na Pipooh tem a garantia de evitar perdas e diminuir desperdícios financeiros, realizando suas compras de acordo com o desenvolvimento do seu bebê.
                    </li>
                    <li>Você pode retirar seus produtos em uma loja física, receber em casa ou comprando em uma loja parceira Pipooh. Comprando no parceiro ainda recebe cash back. 
                    </li>
                </ul>
        </div>
        <div class="creditos-itens">
            <a (click)="oito = 1" style="color: #01b1af;" *ngIf="oito == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                    Que tipos de eventos posso organizar pela PIPOOH? </a>
            <a (click)="oito = 0" style="color: #01b1af;" *ngIf="oito == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                    Que tipos de eventos posso organizar pela PIPOOH? </a>
            <div *ngIf="oito == 1">
                <ul>
                    <li>Chá Revelação </li>
                    <li>Chá Fralda</li>
                    <li>Chá de bebe</li>
                    <li>Chá de Boas vindas </li>
                    <li>Fute Fralda </li>
                    <li>Chá Rifa</li>
                    <li>Chá Bar</li>
                    <li>Lista de Natal </li>
                    <li>Lista de dia das crianças  </li>
                    <li>Aniversários  </li>
                    <li>Mêsversário </li>
                </ul>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="nove = 1" style="color: #01b1af;" *ngIf="nove == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                  Quando posso iniciar as minhas compras?</a>
            <a (click)="nove = 0" style="color: #01b1af;" *ngIf="nove == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                  Quando posso iniciar as minhas compras?</a>
            <div *ngIf="nove == 1">
                <p>A qualquer momento, basta ter o crédito disponível. Os seus créditos não expiram e você pode usar como, quando e onde quiser, desde que seja um parceiro PIPOOH. 
                </p>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="dez = 1" style="color: #01b1af;" *ngIf="dez == 0"><i class="fa fa-plus" aria-hidden="true"></i>
                Como funciona a entrega?</a>
            <a (click)="dez = 0" style="color: #01b1af;" *ngIf="dez == 1"><i class="fa fa-minus" aria-hidden="true"></i>
                Como funciona a entrega?</a>
            <p *ngIf="dez == 1">Cada loja tem a sua própria mecânica de entrega, no momento de finalizar a sua compra, cada vendedor e pacote apresentará uma data e política diferente de entrega. Como também temos disponíveis parceiros de serviços online e lojas físicas. 
            </p>

        </div>
        <div class="creditos-itens">
            <a (click)="onze = 1" style="color: #01b1af;" *ngIf="onze == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                 Tem valor mínimo de compra/ regate?</a>
            <a (click)="onze = 0" style="color: #01b1af;" *ngIf="onze == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                 Tem valor mínimo de compra/ regate?</a>

            <p *ngIf="onze == 1">Não tem pedido mínimo.</p>
        </div>
        <div class="creditos-itens">
            <a (click)="doze = 1" style="color: #01b1af;" *ngIf="doze == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                    Meus créditos vencem?</a>
            <a (click)="doze = 0" style="color: #01b1af;" *ngIf="doze == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                    Meus créditos vencem?</a>

            <p *ngIf="doze == 1">Não, os seus créditos não expiram e você pode usar como, quando e onde quiser, desde que seja um parceiro PIPOOH. 
            </p>
        </div>
        <div class="creditos-itens">
            <a (click)="treze = 1" style="color: #01b1af;" *ngIf="treze == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i>
                 Quero fazer troca de produtos, como faço?</a>
            <a (click)="treze = 0" style="color: #01b1af;" *ngIf="treze == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i>
                 Quero fazer troca de produtos, como faço?</a>

            <p *ngIf="treze == 1">Para a compra em lojas parceiras o processo de troca deve seguir as políticas individuais de cada lojista, e deve entrar em contato diretamente com o parceiro. </p>
            <p *ngIf="treze == 1">Se a compra foi comercializada e entregue pela Pipooh:</p>
            <p *ngIf="treze == 1">Antes de solicitar sua troca confira se o produto está em perfeito estado e lacrado, como também com 6 meses antes do prazo de validade. </p>
            <p *ngIf="treze == 1">O produto estando apto para troca, siga os passos:
            </p>
            <p *ngIf="treze == 1">1: Abra uma solicitação de troca no sistema, nos seus pedidos.
            </p>
            <p *ngIf="treze == 1">2: Poste via Correios o produto desejado.
            </p>
            <p *ngIf="treze == 1">3: Chegando na Pipooh, é feita a conferência do bom estado do produto

            </p>
            <p *ngIf="treze == 1">4: Estando em perfeito estado realizamos a inclusão do crédito em sua conta Pipooh, no valor que pagou no momento da compra.

            </p>
        </div>
        <div class="creditos-itens">
            <a (click)="quatorze = 1" style="color: #01b1af;" *ngIf="quatorze == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> Se a mãe não comprou o produto com a Pipooh</a>
            <a (click)="quatorze = 0" style="color: #01b1af;" *ngIf="quatorze == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> Se a mãe não comprou o produto com a Pipooh</a>
            <div *ngIf="quatorze == 1">
                <p>A Pipooh compra seus produtos com abatimentos de valores, esta compra é paga em créditos Pipooh que podem ser utilizados como e quando desejar, tanto com produtos no e-commerce Pipooh, promoções ou em parceiros, recebendo cashback.
                </p>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="quinze = 1" style="color: #01b1af;" *ngIf="quinze == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> Mudei de endereço, como faço para alterar na Pipooh?</a>
            <a (click)="quinze = 0" style="color: #01b1af;" *ngIf="quinze == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> Mudei de endereço, como faço para alterar na Pipooh?</a>

            <p *ngIf="quinze">Antes de você finalizar a sua compra, a mãe/pai deve alterar seu endereço no seu perfil e o sistema já vai calcular com o seu endereço novo o custo de frete.
            </p>
        </div>
        <div class="creditos-itens">
            <a (click)="dezeseis = 1" style="color: #01b1af;" *ngIf="dezeseis == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> Por motivo de força maior, não precisarei utilizar os serviços da Pipooh, o que devo fazer?</a>
            <a (click)="dezeseis = 0" style="color: #01b1af;" *ngIf="dezeseis == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> Por motivo de força maior, não precisarei utilizar os serviços da Pipooh, o que devo fazer?</a>
            <div *ngIf="dezeseis == 1">
                <p>Pedimos que entre em contato conosco, em algum de nossos canais de relacionamentos, para que possamos confirmar algumas informações, após análise, será devolvido 80 % do valor a ser creditado em uma conta corrente nominal ou estornamos as compras de todos os convidados, no caso a aquisição do seu crédito em conta tenha sido feito via a organização de um evento ou empresarial.

                </p>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="dezesete = 1" style="color: #01b1af;" *ngIf="dezesete == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> Posso resgatar meus créditos em dinheiro?</a>
            <a (click)="dezesete = 0" style="color: #01b1af;" *ngIf="dezesete == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> Posso resgatar meus créditos em dinheiro?</a>
            <div *ngIf="dezesete == 1">
                <p>Não, a Pipooh só disponibiliza o resgate em produtos dentro da plataforma ou em compra em parceiros. A proposta da Pipooh é garantir que todos os seus créditos sejam utilizados com a criança e que seu consumo tenha benefícios através do programa de pontos e Cashback

                </p>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="dezoito = 1" style="color: #01b1af;" *ngIf="dezoito == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> Como funciona o Cashback?</a>
            <a (click)="dezoito = 0" style="color: #01b1af;" *ngIf="dezoito == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> Como funciona o Cashback?</a>
            <div *ngIf="dezoito == 1">
                <p>Ao adquirir ou ganhar créditos Pipooh nos eventos organizados, estes créditos podem ser utilizados como meio de pagamento em lojas parceiras, ao realizar o pagamento no parceiro ou comprar um voucher para uso em uma loja online, você recebe um % que a loja pré estabelece, onde fica disponível para consulta em seu cadastro.

                </p>
                <p>Caso pedido ou pagamento seja cancelado, o cashback também é retirado da conta, e os seus créditos iniciais voltam para a sua conta.
                </p>
            </div>
        </div>
        <div class="creditos-itens">
            <a (click)="deznove = 1" style="color: #01b1af;" *ngIf="deznove == 0"><i class="fa fa-plus"
                    aria-hidden="true"></i> Quais são os dados
                da Pipooh?</a>
            <a (click)="deznove = 0" style="color: #01b1af;" *ngIf="deznove == 1"><i class="fa fa-minus"
                    aria-hidden="true"></i> Quais são os
                dados da Pipooh?</a>
            <div *ngIf="deznove == 1">
                <p>A Pipooh fica estabelecida em São Paulo Capital.
                </p>
                <p>CNPJ: 37.240.661/0001-02
                </p>
                <p>Telefone de atendimento: 11 98882 9676 (Telefone e WhatsApp) </p>
            </div>
        </div>
    </div>
    <div class="col-lg-2"></div>
</div>
<app-footer></app-footer>