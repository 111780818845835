import { Component, OnInit } from '@angular/core';
import { EventoEstabelecimentoService } from 'src/app/_services/evento-estabelecimento.service';
@Component({
  selector: 'app-pesquisa-nps',
  templateUrl: './pesquisa-nps.component.html',
  styleUrls: ['./pesquisa-nps.component.css']
})
export class PesquisaNpsComponent implements OnInit {
  nota: any;
  comoMelhorar: any;
  aceitaContato: any;
  resposta: any;
  perguntasDetratores: any = [];
  perguntasNeutros: any = [];
  perguntasPromotores: any = [];
  pesquisa: any = [];
  codigoIngresso: any;
  loading: any = false;
  constructor(
    private _eventoEstabelecimento : EventoEstabelecimentoService,
  ){}
  
  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.codigoIngresso = urlParams.get('id');
    this._eventoEstabelecimento.buscarNPS({"codigo": this.codigoIngresso}).then(data => {
      console.log("data", data.payload);
      this.pesquisa = data.payload.pesquisa[0];
      // data.payload.perguntas.forEach(pergunta => {
      //   if(pergunta.tipo == 'Detratores'){
      //     this.perguntasDetratores.push({"check": false, "pergunta": pergunta.pergunta});
      //   } else if(pergunta.tipo == 'Neutros'){
      //     this.perguntasNeutros.push({"check": false, "pergunta": pergunta.pergunta});
      //   } else if(pergunta.tipo == 'Promotores'){
      //     this.perguntasPromotores.push({"check": false, "pergunta": pergunta.pergunta});
      //   }
      // });
      console.log("data", data.payload);
    });
  }

  inserirNPS(){
    this.loading = true;
    this.resposta = "";
    if(this.nota >= 0 && this.nota <= 6){
      this.perguntasDetratores.forEach(pergunta => {
        if(pergunta.check == true) {
          if (this.resposta != "") {
            this.resposta += pergunta.pergunta + " - ";

          } else {
            this.resposta = pergunta.pergunta + " - ";
          }
        }
      });
    }
    this._eventoEstabelecimento.insertNPS({"codigo": this.codigoIngresso, "nota": this.nota, "resposta": this.resposta, "comoMelhorar": this.comoMelhorar, "aceitaContato": this.aceitaContato}).then(data => {
      this.loading = false;
      if(data.success){
      this.pesquisa.participanteNPS = true; 

      }
    }).catch(e => {this.loading = false});
  }
}
