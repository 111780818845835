import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import{WebPrincipalService} from '../_services/web-principal.service';



@Component({
  selector: 'app-escolher-mamae',
  templateUrl: './escolher-mamae.component.html',
  styleUrls: ['./escolher-mamae.component.css']
})
export class EscolherMamaeComponent implements OnInit {
  mamaeNome: any;
  pesquisaMamae: any;
  page = 1;
  pageSize = 12;
  constructor(private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _web:WebPrincipalService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.mamaeNome = params['mamae'];
      if(this.mamaeNome==undefined || this.mamaeNome==null){
        this.mamaeNome="";
      }

      this.pesquisarMamae(this.mamaeNome);

    });
  }

  pesquisarMamae(mamaeNome) {

    this._web.pesquisaMamae({"pesquisaMamae":mamaeNome}).then(data => {
      this.pesquisaMamae = data.payload;
    });
  }
  buscarMamae(){
    this.pesquisarMamae(this.mamaeNome);
  }

}
