import { Component, OnInit } from '@angular/core';
import { RifaService } from '../_services/rifa.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ConvidadoService } from '../_services/convidado.service';
import { PedidoService } from '../_services/pedido.service';

@Component({
  selector: 'app-carrinho-rifa',
  templateUrl: './carrinho-rifa.component.html',
  styleUrls: ['./carrinho-rifa.component.css'],
})
export class CarrinhoRifaComponent implements OnInit {
  mamaeID: any;
  rifa: any;
  loadingRifa: any;
  pedidoToken: any;
  bilhetes = this._rifa.getBilhetes();
  bilhetesValor: any;
  bilhetesQuantidade: any;
  bilhetesQuantidadeTotal: any;
  bilhetesReservados = 0;
  bilhetesDisponiveis = 0;
  closeResult = '';
  premiosRifa: any;
  nomeRifa: any;
  infoEvento: any = [];
  eventoID: any;
  pedido: any = [];

  constructor(
    private _rifa: RifaService,
    private modalService: NgbModal,
    private _convidado: ConvidadoService,
    private _pedidoService: PedidoService
  ) {}

  ngOnInit(): void {
    this.pedidoToken = localStorage.getItem('pedidoAnt');
    this.mamaeID = localStorage.getItem('pipoohMamae');
    this.eventoID = localStorage.getItem('pipoohEvento');

    localStorage.removeItem('rifa');
    var rifaPedido = localStorage.getItem('rifaPedido');
    if (rifaPedido == null || rifaPedido == undefined) {
      window.location.href = '/carrinho';
    }

    this.bilhetes = this._rifa.getLocalBilhetes();

    this.getRifa();
    this.getInfoEvento();
    this.getDadosRifa();
  }

  getDadosRifa() {
    this._rifa
      .getRifaPremiosPorEvento({ UserID: this.mamaeID, eventoID: this.eventoID,}).then((data) => {
        this.premiosRifa = data.payload;
      });

    this._rifa.getBilheteValor({ UserID: this.mamaeID, eventoID: this.eventoID }).then((data) => {
        this.bilhetesValor = data.payload;
      });

    this._rifa.getBilheteQuantidade({
        UserID: this.mamaeID,
        pedidoToken: this.pedidoToken,
        eventoID: this.eventoID,
      })
      .then((data) => {
        this.bilhetesQuantidade = data.payload;
        this.bilhetesQuantidadeTotal = data.payload;
      });
    this._rifa
      .getNomeRifa({ UserID: this.mamaeID, eventoID: this.eventoID })
      .then((data) => {
        this.nomeRifa = data.payload;
      });
    this._pedidoService
      .selectPedidoToken({ pedidoToken: this.pedidoToken, })
      .then((data) => {
        this.pedido = data.payload[0];
      });
  }

  finalizarRifa() {
    if(this.bilhetes.length < this.bilhetesQuantidadeTotal){
      document.getElementById("openModalErro").click();
    }else {
      this._rifa.finalizarRifa({
        tokenPedido: this.pedidoToken,
        UserID: this.mamaeID,
        bilhetes: this.bilhetes,
        eventoID: this.eventoID,
      });
  
      localStorage.removeItem('rifaPedido');
  
      window.location.href =
        'https://checkout.pipooh.com.br/?id=' + this.pedidoToken;
    }
  }
  finalizarMesmoAssim(){
    this._rifa.finalizarRifa({
      tokenPedido: this.pedidoToken,
      UserID: this.mamaeID,
      bilhetes: this.bilhetes,
      eventoID: this.eventoID,
    });

    localStorage.removeItem('rifaPedido');

    window.location.href =
      'https://checkout.pipooh.com.br/?id=' + this.pedidoToken;
  }
  getInfoEvento() {
    let mamae = localStorage.getItem('pipoohMamae');
    this._convidado
      .getEventoPorID({ UserID: mamae, eventoID: this.eventoID })
      .then((data) => {
        this.infoEvento = data;
        this.infoEvento = this.infoEvento.payload[0];
        console.log(this.infoEvento);
      });
  }

  getRifa() {
    this._rifa
      .getRifa({ UserID: this.mamaeID, eventoID: this.eventoID })
      .then((data) => {
        this.rifa = data;
        this.rifa = this.rifa.payload;
        this.rifa.forEach((element) => {
          if (element.Status == 'Bloqueado') {
            this.bilhetesReservados = this.bilhetesReservados + 1;
          } else if (element.Status == 'Livre') {
            this.bilhetesDisponiveis = this.bilhetesDisponiveis + 1;
          }
        });
      });
  }
  open(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title,',
        size: 'md',
        windowClass: 'modal',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  addBilhete(bilhete) {
    this.bilhetesQuantidade = this.bilhetesQuantidade - 1;
    this._rifa.addBilhete(bilhete);
    this.bilhetes = this._rifa.getBilhetes();
  }
  removeBilhete(bilhete) {
    this.bilhetesQuantidade = this.bilhetesQuantidade + 1;
    this._rifa.removeBilhete(bilhete);
    this.bilhetes = this._rifa.getBilhetes();
  }
}
