<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=469756370940862&ev=CompleteRegistration" />

<div style="text-align: center;" id="logologo">
    <div>
        <img src="./assets/img/logo transp.png" style="width: 50%; " alt="" class="img-fluid">
    </div>

</div>
<section id="pipooh-mais-barato-re" style="margin-top: 10px;">

    <div style="text-align: center; margin-bottom: 40px;  background-color: aliceblue;">
        <div style="width: 80%; display: inline-block;">
            <div style="margin-top: 10px;">
                <h2 style="font-family: DS Marker Felt; color:#01B1AF;">
                    Porque as mamães perdem seus produtos de chá de bebê/ fralda? </h2>
            </div>

            <div class="iframe-container">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/FeqR3EU8tcs"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-10" style="text-align: center;">
            <h5 style="background-color: #f5a4c7; border-radius: 5PX; color: white;">Seu Chá Moderno,
                Inteligente e na Medida Certa!</h5>
            <p class="sem-negrito">
                Pipooh não têm o objetivo de substituir o seu chá presencial e não é uma simples lista de presentes, ele
                é complementar, prestamos um serviço único no mercado de transformar os seus presentes de fralda no chá
                em crédito e entregamos as fraldas de acordo com as necessidades do seu bebê, sem ocupar espaço ou ter
                perdas e o melhor NÃO TÊM CUSTO.
            </p>
        </div>

    </div>


    <div class="row justify-content-center" style="width: 100%; margin: auto;">
        <div onclick="document.getElementById('openModalProduto').click();" class="col-3"
            style="background-color:none;  cursor: pointer;">
            <div class="m-portlet__body home-present" style="text-align: center;">
                <img class="img-button-landing" src="assets/img/produtos_icon.jpg" alt="">
                <figcaption style="font-size: 3vw;">Produtos</figcaption>

            </div>
        </div>

        <div onclick="document.getElementById('openModalResultado').click();" class="col-3"
            style="background-color:none;  cursor: pointer;">
            <div class="m-portlet__body home-present" style="text-align: center;">
                <img class="img-button-landing" src="assets/img/resultados_icon.jpg" alt="">
                <figcaption style="font-size: 3vw;">Resultados</figcaption>


            </div>
        </div>
        <div onclick="document.getElementById('openModalChaRifa').click();" class="col-3" style="  cursor: pointer;">
            <div class="m-portlet__body home-present" style="text-align: center;">
                <img class="img-button-landing" src="assets/img/charifa_icon.jpg" alt="">
                <figcaption style="font-size: 3vw;">Chá Rifa</figcaption>

            </div>
        </div>
        <div onclick="document.getElementById('openModalEntrega').click();" class="col-3" style="  cursor: pointer;">
            <div class="m-portlet__body home-present" style="text-align: center;">
                <img class="img-button-landing" src="assets/img/entrega_icon.jpg" alt="">
                <figcaption style="font-size: 3vw;">Entrega</figcaption>
            </div>
        </div>

    </div>

    <div style="text-align: center; margin-top: 40px;">
        <a onclick="this.callFB();" href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5"
            class="btn btn-orange">Acessar o meu perfil</a>
    </div>

    <div style="text-align: center; border-bottom: aqua;">
        <a onclick="this.callFB();" style="text-decoration: underline;"
            href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5" class="btn">Ainda não têm
            cadastro?</a>
    </div>

    <div style="text-align: center;">
        <img style="width: 100%;" src="assets/img/VoceSabia.png" alt="">
    </div>

    <div style="text-align: center;">
        <h2 class="fade-in;" style="font-family: DS Marker Felt;">CONFIRA OS RELATOS ABAIXO!</h2>
        <carousel class="fade-in">
            <div class="carousel-cell">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/hML567ufv-M"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="carousel-cell">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/XbY4ymqlxUc"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="carousel-cell">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/CyXlJClJOJA"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="carousel-cell">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/NWxUK1jJx20"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="carousel-cell">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/MsTK0usu9dE"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
            <div class="carousel-cell">
                <iframe style="width: 100%;" src="https://www.youtube.com/embed/VZDS3tnfYWw"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
            </div>
        </carousel>

    </div>
    <div style="text-align: center; margin-top: 40px;">
        <a onclick="this.callFB();" href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5"
            class="btn btn-orange">Acessar o meu perfil</a>
    </div>

    <div style="text-align: center; border-bottom: aqua;">
        <a onclick="this.callFB();" style="text-decoration: underline;"
            href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5" class="btn">Ainda não têm
            cadastro?</a>
    </div>

    <div style="text-align: center;">
        <img src="assets/img/PorquecomaPipooh.png" style="width: 50%;" alt="">
    </div>

    <div class="row justify-content-center">
        <div class="col-5">
            <div style="background-color: #73E5DD; width: 100%; color: #73E5DD;border-radius:5px;">a</div>

            <div style="text-align: center;">
                <h5>EVITAR PERDAS DE FRALDAS</h5>
                <p class="font-sub" style=" text-align: center; font-size: 0.8rem;">Você utiliza seus créditos conforme
                    o desenvolvimento do bebê, evitando perdas por
                    tamanho, vencimento ou alergias</p>

            </div>
        </div>
        <div class="col-5">
            <div style="background-color: #73E5DD; width: 100%; color: #73E5DD;border-radius:5px;">a</div>

            <div style="text-align: center;">
                <h5>GANHAR AINDA MAIS PRESENTES</h5>
                <p class="font-sub" style=" text-align:center;font-size: 0.8rem;">As mães/pais que fazem chá na Pipooh
                    ganham até R$5.000 em presentes.</p>

            </div>
        </div>


    </div>

    <div class="row justify-content-center">
        <div class="col-5 justify-content-center" style="text-align: center;">
            <div style="background-color: #73E5DD; width: 100%; color: #73E5DD;border-radius:5px;">a</div>
            <div style="text-align: center;">
                <h5>PROTEGER SEU BEBÊ</h5>
                <p class="font-sub" style="text-align: center;font-size: 0.8rem;">A pipooh armazena as fraldas para
                    você, recebendo em casa ou retirando na loja mais
                    próxima, evitando acúmulo de pó no quartinho do bebê e ocupar espaço.</p>
            </div>
        </div>
        <div class="col-5">
            <div style="background-color: #73E5DD; width: 100%; color: #73E5DD;border-radius:5px; ">a</div>

            <div style="text-align: center;">
                <h5>TROCA GARANTIDA</h5>
                <p class="font-sub" style="text-align: center; font-size: 0.8rem;">A qualquer momento e quantas vezes
                    você quiser, você pode trocar os produtos
                    adquiridos. Garantindo que o seu bebê não vai perder produtos por tamanho ou alergias.</p>

            </div>
        </div>

    </div>

    <div style="text-align: center; margin-top: 40px;">
        <a onclick="this.callFB();" href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5"
            class="btn btn-orange">Acessar o meu perfil</a>
    </div>

    <div style="text-align: center; border-bottom: aqua;">
        <a onclick="this.callFB();" style="text-decoration: underline;"
            href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5" class="btn">Ainda não têm
            cadastro?</a>
    </div>


    <div style="border-radius: 5px; background-color: #52B2AB; width: 80%; margin: 0 auto;">
        <div class="row justify-content-center" style="padding:5px;">
            <div class="col-10" style="background-color: #F5A4C7; border-radius: 15px; ">
                <h2 style="text-align: center; font-size: 6VW; font-family: DS Marker Felt; margin-top: 3%;">O SEU CHÁ
                    PODE SER:</h2>
            </div>
        </div>
        <div class="row justify-content-center" style="padding: 20px;">


            <div class="col-9">
                <div class="row">
                    <div class="col-2">
                        <img style="width: 10vw;" src="assets/img/icone_certo.png" alt="">

                    </div>
                    <div class="col-10" style="margin-top: 1vw;">
                        <h2 style="margin-left: 1vw; font-size: 6vw; color: white;">Presencial</h2>

                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <img style="width: 10vw;" src="assets/img/icone_certo.png" alt="">

                    </div>
                    <div class="col-10" style="margin-top: 1vw;">
                        <h2 style="margin-left: 1vw;font-size: 6vw;color: white;"> Empresarial</h2>

                    </div>
                </div>

                <div class="row">
                    <div class="col-2">
                        <img style="width: 10vw;" src="assets/img/icone_certo.png" alt="">

                    </div>
                    <div class="col-10" style="margin-top: 1vw;">
                        <h2 style="margin-left: 1vw;font-size: 6vw; color: white;"> Chá Rifa</h2>

                    </div>
                </div>

                <div class="row">
                    <div class="col-2">
                        <img style="width: 10vw;" src="assets/img/icone_certo.png" alt="">

                    </div>
                    <div class="col-10" style="margin-top: 1vw;">
                        <h2 style="margin-left: 1vw;font-size: 6vw;color: white;"> Virtual</h2>

                    </div>
                </div>

            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-11">
                <img style="width: 100%;" src="assets/img/Dafestaateconsumo.png" alt="">
            </div>

        </div>
    </div>

    <div style="text-align: center; margin-top: 40px;">
        <a onclick="this.callFB();" href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5"
            class="btn btn-orange">Acessar o meu perfil</a>
    </div>

    <div style="text-align: center; border-bottom: aqua;">
        <a onclick="this.callFB();" style="text-decoration: underline;"
            href="https://app.pipooh.com.br/?refRemarketing=remarketing-5&ref=remarketing-5" class="btn">Ainda não têm
            cadastro?</a>
    </div>

    <div style="text-align: center;">
        <h2 class="fade-in">CONFIRA AS REPORTAGENS!</h2>
        <carousel class="fade-in">
            <div class="carousel-cell">
                <a
                    href="https://g1.globo.com/economia/pme/pequenas-empresas-grandes-negocios/noticia/2021/03/14/plataforma-ajuda-na-organizacao-de-cha-de-bebe-virtual.ghtml"><img
                        src="assets/img/g1.png" alt=""></a>
            </div>
            <div class="carousel-cell">
                <a
                    href="https://revistapegn.globo.com/Mulheres-empreendedoras/noticia/2020/09/apos-falir-na-quarentena-ela-lancou-um-site-de-cha-de-fraldas-e-faturou-r-100-mil.html"><img
                        src="assets/img/pequenas-empresas-grandes-negocios.png" alt=""></a>

            </div>
            <div class="carousel-cell">
                <a
                    href="https://g1.globo.com/economia/pme/pequenas-empresas-grandes-negocios/noticia/2021/03/14/pequenas-empresas-and-grandes-negocios-contatos-de-14032021.ghtml"><img
                        src="assets/img/globo-reportagem.jpg" alt=""></a>
            </div>
            <div class="carousel-cell">
                <a
                    href="https://vejasp.abril.com.br/cultura-lazer/marca-organiza-chas-de-fralda-virtuais-e-transforma-presentes-em-creditos/"><img
                        src="assets/img/veja-sp.jpg" alt=""></a>

            </div>
        </carousel>
    </div>

    <!-- Modals A Partir Daqui -->

    <ng-template #produto let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="width: auto; text-align: center;">
                <img style="width: 80%;" src="assets/img/300Produtos.png" alt="">

            </div>

            <div style="background-color:#73E5DD ; width: 100%;">
                <carousel style="width: 100%;">
                    <div class="carousel-cell">
                        <img style="width: 100%;" src="assets/img/pampers_logo.png" alt="">
                    </div>
                    <div class="carousel-cell">
                        <div class="carousel-cell">
                            <img style="width: 100%;" src="assets/img/huggies_logo.png" alt="">
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="carousel-cell">
                            <img style="width: 100%;" src="assets/img/pompom_logo.png" alt="">
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="carousel-cell">
                            <img style="width: 100%;" src="assets/img/mamypoko_logo.png" alt="">
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="carousel-cell">
                            <img style="width: 100%;" src="assets/img/johnson_logo.png" alt="">
                        </div>
                    </div>
                </carousel>
            </div>

            <div style="text-align: center;">
                <img style="width: 80%;" src="assets/img/PorqueÉMaisBarato.png" alt="">
                <div class="iframe-container">
                    <iframe style="width: 100%;" src="https://www.youtube.com/embed/5nGTp-vaIAY"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>

            </div>
        </div>

    </ng-template>

    <button id="openModalProduto" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(produto)"
        data-target="#openModalProduto">Launch demo modal</button>

    <hr>


    <ng-template #resultado let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="margin-bottom: 5px;">
                <img style="width: 95%;" src="assets/img/5milPresentes.jpg" alt="">
            </div>
            <ngb-carousel style="max-height: 50%;">
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img style="width:100%;" src="assets/img/resultado3.jpeg" alt="Random third slide">
                    </div>

                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img style="width:100%" src="assets/img/resultado2.jpeg" alt="Random third slide">
                    </div>

                </ng-template>
                <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                        <img style="width:100%" src="assets/img/resultado1.jpeg" alt="Random third slide">
                    </div>

                </ng-template>
            </ngb-carousel>
        </div>
    </ng-template>

    <button id="openModalResultado" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(resultado)"
        data-target="#openModalResultado">Launch demo modal</button>


    <ng-template #chaRifa let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h3 style="text-align: center;     font-family: DS Marker Felt; color: #4DB0AB; font-size: 50px;">RIFA 100%
                INTEGRADA</h3>
            <div style="text-align: center;">
                <div class="iframe-container">
                    <iframe style="width: 100%;" src="https://www.youtube.com/embed/zhvwhDCpBhw"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>

            </div>
            <div style="text-align: center; background-color: #F5A4C7;padding: 5px;margin-top: 5px; color: white;">
                <h4 class="sem-negrito">ABRA SUA CONTA NA PIPOOH</h4>
            </div>
            <div style="text-align: center; padding: 5px;">
                <h4 class="sem-negrito">CONFIGURE A SUA RIFA</h4>
            </div>
            <div style="text-align: center; background-color: #F5A4C7;padding: 5px; color: white;">
                <h4 class="sem-negrito">CONVIDADOS PRESENTEIAM E RECEBEM BILHETES EM TROCA</h4>
            </div>
            <div style="text-align: center; padding: 5px;">
                <h4 class="sem-negrito">SELECIONAM SEUS NÚMEROS</h4>
            </div>
            <div style="text-align: center; background-color: #F5A4C7;padding: 5px; color: white;">
                <h4 class="sem-negrito">SORTEIO AUTOMÁTICO</h4>
            </div>
            <div style="text-align: center; margin-top: 10px;">
                <h4 class="sem-negrito">TODO PRESENTE VIRA CRÉDITO E VOCÊ RETIRA OS PRODUTOS NA LOJA OU RECEBE EM CASA
                </h4>
            </div>
        </div>

    </ng-template>

    <button id="openModalChaRifa" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(chaRifa)"
        data-target="#openModalChaRifa">Launch demo modal</button>


    <ng-template #entrega let-modal>
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="text-align: center;">

                <h2 style="font-family: DS Marker Felt; color: #51B2AA; font-size: 50px;">ENTREGA <span
                        style="font-family: DS Marker Felt; color: #F5A4C7;">!</span></h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-10" style="text-align: center;">
                    <p>
                        Troque os seus créditos por produtos no e-commerce da Pipooh (Área ADM) ou em uma loja
                        credenciada.
                    </p>
                </div>
            </div>

            <div class="row justify-content-around">
                <div class="col-5" style="text-align: center;">
                    <img src="assets/img/EntregaIcone_Loja.jpg" style="width: 113%;" alt="">
                    <div style="font-size: 1rem; color: #AFAEAE;">
                        <p>
                            Lojas da Pipooh:
                            São Paulo
                            <br>
                            Bahia
                        </p>
                    </div>
                </div>
                <div class="col-5" style="text-align: center;">
                    <img src="assets/img/EntregaIcone.jpg" style="width: 100%;" alt="">
                    <div style="color: #AFAEAE;">
                        Entregamos para todo o Brasil
                    </div>
                </div>
            </div>
            <div style="width: 100%; margin-bottom: 30px;">
                <img style="width: 100%;" src="assets/img/Texto_Entrega.jpg" alt="">
            </div>



        </div>
    </ng-template>

    <button id="openModalEntrega" class="btn btn-lg btn-outline-primary" [hidden]="true" (click)="open(entrega)"
        data-target="#openModalChaRifa">Launch demo modal</button>
</section>