<div class="row justify-content-center" style="margin-bottom: 15px; margin-top: 10px; padding: 30px;">
  <div class="row" style="    border: 1px solid #01b1af;
      border-radius: 20px;
      padding: 20px; ">
    <!-- <div > -->

    <div class="col-lg-3" *ngFor="let evento of eventos">
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image">
            <img src="https://img.pipooh.com.br/{{evento.foto}}" width="100%" alt="" style="border-radius: 25px" />
          </div>
          <mat-card-title>{{ evento.nomeOrganizador }}</mat-card-title>
          <mat-card-subtitle> {{ evento.nome }}</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image src="https://img.pipooh.com.br/{{ evento.imagem }}"
          style="width: 100% !important;margin: 0 !important" alt="" />

        <mat-card-content style="text-align: center;">

          <p style="color: #01b1af;">
            R${{formatDecimal(evento.valor)}}
          </p>
        </mat-card-content>
        <mat-card-actions style="text-align: center;">
          <!-- <button mat-button>LIKE</button>
            <button mat-button>SHARE</button> -->
          <a class="btn btn-primary text-white" routerLink="/evento-estabelecimento"
            [queryParams]="{id: evento.id}">Visualizar</a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <!-- </div> -->

</div>