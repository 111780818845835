<app-header></app-header>


<div class="row justify-content-center" id="comoFunciona">
    <div class="col-12" style="text-align: center; margin-top: 40px;">
        <div class="section-title">
            <h2 style="color:#01B1AF ; font-weight: 700; font-size: 40px; ">Lembrete cancelado com sucesso!</h2>
        </div>
    </div>



</div>

<app-footer></app-footer>