<app-header></app-header>
<div class="content-body" style="text-align: center; padding: 20px;">
    <div class="container">
        <img src="/assets/img/cartao.png" class="hidden-xs"  alt="">
        <img src="/assets/img/cartao.png" class="visible-xs" style="width: 100%;" alt="">
    </div>
    <div class="container">
        <br>
        <p>Olá, {{cartao.nome}}</p>
        <p><strong>Parabéns!</strong></p>
        <p>{{cartao.CompradorNome}} enviou um cartão presente de {{cartao.modelo}} para {{cartao.nomeCrianca}} no valor de R$
            {{formatDecimal(cartao.PedidoValor)}} na PIPOOH.</p>
        <p *ngIf="cartao.mensagem"> <strong> Mensagem:</strong> {{cartao.mensagem}} </p>
        <p><strong>Cartão Presente:</strong>  {{cartao.codigo}}</p>
        <p>Faça o login ou cadastre-se no link abaixo e valide seu cartão presente agora.</p>
        <a href="https://app.pipooh.com.br" class="btn btn-success2" style="margin-bottom: 20px;">Validar agora</a>
        <br>
        <p>Video de como validar:</p>
        <a href="https://www.youtube.com/watch?v=Lu1AbMGSyH8&list=PLIBqKX0QkVUcpFHGFQCPZ6RTtxGw_a8nS&index=11">https://www.youtube.com/watch?v=Lu1AbMGSyH8&list=PLIBqKX0QkVUcpFHGFQCPZ6RTtxGw_a8nS&index=11</a>
    </div>
</div>