import { Component, OnInit } from '@angular/core';
import { CarrinhoService } from '../_services/carrinho.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../environments/environment.prod';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public URL = `${environment.domain}`;

  items = this._carrinho.getItems();
  closeResult = '';
  pesquisaMamae:any;



  constructor(
    private _carrinho: CarrinhoService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _router: Router,

  ) { }

  ngOnInit(): void {
    this.items=this._carrinho.getLocalCarrinho();

  }

  ancora(tag) {
    this._router.navigate(['/'], { fragment: tag });
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openProduto() {
    document.getElementById("openModalCarrinho").click();

  }
  urlImage(image) {
    return this.URL + image;
  }
  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }
  removeCarrinho(item) {
    this._carrinho.removeCarrinho(item);
  }

  pesquisarMamae(){
    if(this.pesquisaMamae==undefined || this.pesquisaMamae==null){
      this.pesquisaMamae="";
    }
    window.location.href = "/escolher-mamae?mamae="+this.pesquisaMamae;

  }
}
