<app-header></app-header>

<div class="row justify-content-center" style="margin-bottom: 15px; margin-top: 10px; padding: 20px;">

  <div class="  col-lg-8 col-md-6 col-sm-8 ">
    <table class="table table-striped border-col">
      <thead>
        <tr class="textoTitleTable">
          <th scope="col"></th>
          <th scope="col">
            <p class="textoTtitleTable">Tipo</p>
          </th>
          <th scope="col">
            <p class="textoTtitleTable">Qtd</p>
          </th>
          <th scope="col">
            <p class="textoTtitleTable">Valor</p>
          </th>
          <th scope="col">
            <p class="textoTtitleTable">Total</p>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items">
          <td width="20%">
            <img *ngIf="!item.fkProdutos_LojaIntegrada" src="{{this.urlImage(item.ProductsImage)}}" alt="" width="100%"
              style="border-radius: 25px;" />
            <img *ngIf="item.fkProdutos_LojaIntegrada" src="{{this.urlImageLoja(item.ProductsImage)}}" alt=""
              width="100%" style="border-radius: 25px;" />
          </td>
          <td class="align-middle">
            <h4 style="font-size: 20px; font-weight: 500;">{{item.CategoriaName}} </h4>
          </td>
          <td class="align-middle">{{item.quantidade}}</td>
          <td class="align-middle">
            <h4 style="color: #01B1AF; font-weight: 550;font-size: 18px !important;">R$
              {{this.formatDecimal(item.ProductsPrice)}}</h4>
          </td>
          <td class="align-middle">
            <h4 style="color: #01B1AF; font-weight: 550;font-size: 18px !important;">R$
              {{this.formatDecimal(item.ProductsPrice * item.quantidade)}}
            </h4>
          </td>
          <td class="align-middle" (click)="removeCarrinho(item)"><i class="fas fa-times"
              style="color:#842029;cursor: pointer;"></i>
          </td>
        </tr>
        <tr style="height: 50px;">
          <td></td>
          <td colspan="3"><a (click)="continuarComprando()" class="btn btn-carrinho" *ngIf="infoEvento.rifaAtiva != 1">Continuar comprando</a></td>
          <td>
            <h4 style="color: #01B1AF; font-weight: 550; font-size: 18px !important;">Total: R$
              {{this.formatDecimal(this.valorTotal)}}</h4>
          </td>
          
          <!-- <td>R$ {{this.formatDecimal(this.total)}}</td> -->
          <td></td>
        </tr>
        <tr style="background-color: transparent;">
        </tr>
      </tbody>
    </table>
  
    <div class="" *ngIf="infoEvento.rifaAtiva == 1">
      <p>
        <b>Você está participando de uma rifa.</b>  <br>
        Já ganhou {{bilhetes}} bilhetes. <br>
        <span style="color: red;">Faltam R$ {{this.formatDecimal(this.valorRestante)}} para o próximo bilhete</span>
      </p>
    </div>
    
  <a (click)="continuarComprando()" class="btn btn-carrinho">Continuar comprando</a>

  </div>

  <div class="col-lg-3 col-md-6 col-sm-8  ">
    <div class="">
      <form #myForm="ngForm" (ngSubmit)="onSubmit(myForm);" novalidate style="padding:15px;">
        <div class="form-style-6">
          <h4 style="color: white;text-align: center; font-weight: 550;">{{this.infoMamae.UserName}}</h4>
          <div class="row justify-content-around" style="border-bottom: white;">
            <div class="col-6">
              <h5 style="color: white; font-weight: 550;">Subtotal</h5>
            </div>
            <div class="col-6">
              <h5 style="color: white; text-align: center;font-weight: 550;"> R${{this.formatDecimal(this.valorTotal)}}
              </h5>
            </div>
          </div>
          <div class="row justify-content-around" style="border-bottom: white;font-weight: 550;">
            <div class="col-6">
              <h5 style="color: white;font-weight: 550;">Frete</h5>
            </div>
            <div class="col-6">
              <h5 style="color: white; text-align: center;font-weight: 550;"> R${{this.formatDecimal(this.Frete)}} </h5>
            </div>
          </div>

          <div style="text-align: center; margin: 10px; border-radius: 25px; ">
            <a onclick="document.getElementById('openModalFrete').click();" class="btn"
              style="color: #01B1AF; background-color: white; border-radius: 25px; box-shadow: 2px 2px 2px 1px rgba(0, 0, 255, .2);">Deseja
              contribuir
              com frete?</a>
          </div>
          <div class="row justify-content-around" style="border-bottom: white;">
            <div class="col-6">
              <h5 style="color: white; font-weight: 550;">Total:</h5>
            </div>
            <div class="col-6">
              <h5 style="color: white; text-align: center; font-weight: 550;"> R${{this.formatDecimal(this.Total)}}
              </h5>
            </div>
          </div>
          <br>
          <input type="text" name="CompradorNome" ngModel required placeholder="Seu nome completo"
            style="border-radius: 25px;" #CompradorNome="ngModel">
          <input type="email" name="CompradorEmail" pattern="[^ @]*@[^ @]*" ngModel placeholder="Seu e-mail" required
            style="border-radius: 25px;" #CompradorEmail="ngModel">
          <div class="row ">
            <div class="col-3">
              <input style="height: 100%; border-radius: 25px; text-align: center;" type="text" name="CompradorDDD"
                ngModel required placeholder="DDD" #CompradorDDD="ngModel" mask="00">

            </div>
            <div class="col-9">
              <input style="height: 100%; border-radius: 25px;" type="text" mask="00000-0000" name="CompradorTelefone"
                ngModel required placeholder="Telefone" #CompradorTelefone="ngModel">

            </div>
            <h4 style="color: white; text-align: center; font-weight: 550; margin-top: 10px;">Deixe o seu recado!</h4>

            <div class="col-12" style="margin-top: 10px;">
              <textarea ngModel value='' name="CompradorMensagem" #mensagem="ngModel"
                style="border-radius: 25px;  overflow: hidden;"
                placeholder="Deixe sua mensagem de carinho para a criança."></textarea>
            </div>

            <h4 style="color: white; text-align: center; font-weight: 550; margin-top: 10px;"> Deixe sua mensagem em
              vídeo!</h4>
            

            <div class="col-12" *ngIf="!video">
              <input type='file' style="display: none;" (change)="upload($event.target.files)" id="bannerUpload" accept=".mp4" />
              <label for="bannerUpload" class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white"><i
                  class="fas fa-plus"></i> Inserir vídeo</label>
            </div>
            <div class="col-12" *ngIf="video">
              <label class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white"><i class="fas fa-check"></i> Vídeo inserido</label>
              <label for="bannerUpload" class="btn btn-success2 m-btn m-btn--custom btn-sm-block text-white"><i
                  class="fas fa-plus"></i> Editar vídeo</label>
            </div>
            <label for="" style="color: white; "><input type="checkbox" ngModel required #termos="ngModel" id="termos" name="termos"> Eu concordo com os <a style="color: black;"  href="https://img.pipooh.com.br/imagens/pdf/5e04cf7bbfb6de5a8d3255bc94f2885c.pdf"
              target="_blank">Termos e condições de uso</a> </label>
            <label for="" style="color: white; "><input type="checkbox" ngModel required #politica="ngModel" id="politica" name="politica"> Eu concordo com as <a  style="color: black;" href="https://img.pipooh.com.br/imagens/pdf/da192f01bd138c4df9338dd70b02f1bf.pdf"
              target="_blank">Política de privacidade.</a> </label>
          </div>
          <br />
          <button class="btn" style="
          background-color: #f8b3cf;
          border-radius: 25px;
          color: white;
          text-align: center;" type="submit"
         [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading }">Finalizar Pedido</button>
          <!-- <input type="submit" [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading }" value="Finalizar Pedido" href="/carrinho" /> -->
          <!-- <button class="btn" [disabled]="loading" [ngClass]="{'m-loader m-loader--right m-loader--light': loading }"
          type="submit" [disabled]="loading" value="Finalizar Pedido" href="/carrinho" ></button> -->

        </div>


      </form>

    </div>
  </div>
</div>


<!-- Modais a partir daqui -->
<ng-template #modalFrete let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="text-align: center;">
      <h2 style="text-align: center;color: #F8B3CF;">Sobre o Frete</h2>
      <p>
        Para as mamães que optarem por receber em casa, as entregas são feitas pelos correios. <br>
        Caso queira contribuir com o frete, coloque abaixo.
      </p>
    </div>

    <form #myFrete="ngForm" (ngSubmit)="onSubmitFrete(myFrete)" novalidate style="padding:20px; ">
      <div class="form-style-6" style="text-align: center;">
        <input type="text" name="CompradorFrete" ngModel placeholder="0.00" [dropSpecialCharacters]="false" mask="0*.00"
          #Frete="ngModel" style="border-radius: 25px;">
        <input 
         type="submit" (click)="modal.dismiss('Cross click')" value="Finalizar Pedido" />

      </div>
    </form>
  </div>
</ng-template>

<button id="openModalFrete" class="btn btn-lg btn-outline-primary" hidden="true" (click)="open(modalFrete)"
  data-target="#openModalFrete"></button>

  <app-footer></app-footer>