<app-header></app-header>
<div class="content-body" style="text-align: center;">
    <div style="color: #fff; background-color: #01B1AF; padding: 20px;">
        <h3 style="padding-top: 20px;">BENEFÍCIO EMPRESARIAL
            HUMANIZADO PARA: </h3>
        <p style="color: #F9E291;"><strong>Gestantes, papais e mamães (0 a 5 anos)</strong> </p>
        <p>Temos as melhores soluções para administrar, reter e proporcionar uma vida melhor para este nicho de
            colaboradores. <br> Trazendo ganhos com base nos <b style="color: #F9E291;">dados de mercado</b> abaixo:
        </p>
        <img src="/assets/img/eventos/16.png" class="hidden-xs" width="5%" alt="">
        <div class="visible-xs">
            <img src="/assets/img/eventos/16.png" width="20%" alt="">
        </div>
        <div class="visible-xs">
            <div class="row " style="text-align: center;margin-top: 20px;">
                <div class="col-5"
                    style="background-color: #fff; color: #000;border-radius: 20px; margin-right: 10px; margin-left: 30px;">
                    <h1 style="font-size: 60px;"><strong>30%</strong> </h1>
                    <p>das mulheres abandonam seus cargos em até 2 anos após o parto.</p>
                </div>
                <div class="col-5" style="background-color: #fff; color: #000; border-radius: 20px;">
                    <h1 style="font-size: 60px;"><strong>22%</strong> </h1>
                    <p>das mulheres não conseguem retornar para o mercado após o nascimento do filho.</p>
                </div>
            </div>
        </div>

        <div class="row hidden-xs" style="text-align: center;margin-top: 20px;">
            <div class="col-5"
                style="background-color: #fff; color: #000; width: 25%;
                padding: 25px;border-radius: 20px; margin-right: 10px; margin-left: 370px;">
                <h1 style="font-size: 60px;"><strong>30%</strong> </h1>
                <p>das mulheres abandonam seus cargos em <br> até 2 anos após o parto.</p>
            </div>
            <div class="col-5" style="background-color: #fff; color: #000; width: 25%;
            padding: 25px; border-radius: 20px;">
                <h1 style="font-size: 60px;"><strong>22%</strong> </h1>
                <p>das mulheres não conseguem retornar para <br> o mercado após o nascimento do filho.</p>
            </div>
        </div>

        <p style="color: #000; margin-top: 10px;">fonte: <a style="color: #000;"
                href="https://www.rhportal.com.br/artigos-rh/maternidade-e-carreira/">https://www.rhportal.com.br/artigos-rh/maternidade-e-carreira/</a>
        </p>
    </div>
    <div style="padding: 15px;">
        <h3 style="color: #01B1AF;margin-top: 20px;margin-bottom: 20px;">PRODUTOS PIPOOH</h3>
        <div class="row hidden-xs">
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="/assets/img/eventos/32.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;"> KITS MATERNIDADE </h3>
                        <p>Sem estoque ou logística; <br>
                            Sem desperdícios ;
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="/assets/img/eventos/33.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;"> BENEFÍCIOS <br> CONTÍNUOS </h3>
                        <p>Auxílio Filho; <br>
                            Auxílio gestação; <br>
                            Auxílio puerpério.
                        </p>
                    </div>
                </div>

            </div>
            
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="/assets/img/eventos/35.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;">EVENTOS</h3>
                        <p>Patronize os eventos de CHÄ de BEBÊ na empresa. </p>
                    </div>
                </div>

            </div>
            
            <div class="col-6">
                <div class="row">
                    <div class="col-3">
                        <img src="/assets/img/eventos/34.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;">CONTEÚDO e DESAFIOS</h3>
                        <p>Cursos; <br>
                            Desafios e Quiz; <br>
                            Régua de conteúdo</p>
                    </div>
                </div>

            </div>
            
            
        </div>
        <div class="row visible-xs">
            <div class="col-12" style="margin-bottom: 10px;">
                <div class="row">
                    <div class="col-6">
                        <img src="/assets/img/eventos/32.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;"> KITS MATERNIDADE </h3>
                        <p>Sem estoque ou logística; <br>
                            Sem desperdícios ;
                        </p>
                    </div>
                </div>
                

            </div>
            <div class="col-12" style="margin-bottom: 10px;">
                <div class="row">
                    <div class="col-6">
                        <img src="/assets/img/eventos/33.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;"> BENEFÍCIOS <br> CONTÍNUOS </h3>
                        <p>Auxílio Filho; <br>
                            Auxílio gestação; <br>
                            Auxílio puerpério.
                        </p>
                    </div>
                </div>

            </div>

            <div class="col-12" style="margin-bottom: 10px;">
                <div class="row">
                    <div class="col-6">
                        <img src="/assets/img/eventos/34.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;">CONTEÚDO e DESAFIOS</h3>
                        <p>Cursos; <br>
                            Desafios e Quiz; <br>
                            Régua de conteúdo</p>
                    </div>
                </div>

            </div>
            
            <div class="col-12" style="margin-bottom: 10px;">
                <div class="row">
                    <div class="col-6">
                        <img src="/assets/img/eventos/35.png" width="100%" alt="">
                    </div>
                    <div class="col-6">
                        <h3 style="color: #01B1AF;">EVENTOS</h3>
                        <p>Patronize os eventos de CHÄ de BEBÊ na empresa. </p>
                    </div>
                </div>

            </div>
            
            
            
            
        </div>

        <!-- <img src="/assets/img/eventos/26.png" class="visible-xs" width="100%" alt="">
        <img src="/assets/img/eventos/26.png" class="hidden-xs" width="100%" alt=""> -->
    </div>
    <div style="margin-top: 20px; background-color: #01B1AF; padding: 15px;">
        <div class="row">
            <div class="col-6">
                <img src="/assets/img/eventos/27.png" width="30%" alt="">
                <p style="color: #F9E291;"><strong>SISTEMA</strong></p>
                <p style="color: #fff;">100% auto gerenciável. </p>
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/28.png" width="30%" alt="">
                <p style="color: #F9E291;"><strong>QUEDA DE SINISTRO</strong></p>
                <p style="color: #fff;">Quanto maior o conhecimento e o acesso a informação, <br> menor o custo com idas a PS
                    e incidentes. </p>
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/29.png" width="30%" alt="">
                <p style="color: #F9E291;"><strong>RETENÇÃO</strong></p>
                <p style="color: #fff;">Maior retenção de mulheres com o <br> retorno da licença maternidade.</p>
            </div>
            <div class="col-6">
                <img src="/assets/img/eventos/30.png" width="30%" alt="">
                <p style="color: #F9E291;"><strong>OTIMIZE TEMPO</strong></p>
                <p style="color: #fff;">Otimiza em até 90% o tempo do RH na gestão de KITS.</p>
            </div>
        </div>
    </div>
    <div style="margin-top: 20px;padding: 20px;">
        <h3 style="color: #01B1AF;">CLIENTES</h3>

        <img src="/assets/img/eventos/31.png" width="100%" class="visible-xs" alt="">
        <img src="/assets/img/eventos/31.png" width="50%" class="hidden-xs" alt="">
    </div>
    <div class="dados" style="text-align: center;">
        <h4 style="color: #01B1AF;">FAZER COTAÇÃO</h4>
        <div class="row" style="margin-right: 15px;">
            <div class="col-lg-6 col-md-12">
                <input type="text" [(ngModel)]="empresa" placeholder="Empresa">
            </div>
            <div class="col-lg-6 col-md-12">
                <input type="text" [(ngModel)]="nome" placeholder="Nome">
            </div>
            <div class="col-lg-6 col-md-12">
                <input type="text" [(ngModel)]="telefone" placeholder="Telefone">
            </div>
            <div class="col-lg-6 col-md-12">
                <input type="text" [(ngModel)]="email" placeholder="Email">
            </div>
            <div class="col-lg-6 col-md-12">
                <select name="" id="" [(ngModel)]="servico" class="form-control">
                    <option value="">Selecione o serviço</option>
                    <option value="Kit Maternidade">Kit Maternidade</option>
                    <option value="Benefícios continuo">Benefícios continuo</option>
                    <option value="Eventos">Eventos</option>
                    <option value="Conteúdo e desafios">Conteúdo e desafios</option>
                </select>
            </div>
            <div class="col-lg-6 col-md-12">
                <input type="text" [(ngModel)]="volume" placeholder="Volume de funcionário receberão o benefício:">
            </div>
            <br>
            <div class="col-12">
                <a (click)="enviar()"
                    style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;">Enviar</a>
            </div>
        </div>
    </div>
    <!-- <div class="col-lg-12">
        <div class="section-title" style="text-align: center;">
            <h2>Entenda como funciona:</h2>
        </div>

        <label for="">Criação</label>
        <p>Em 5 minutos você consegue gerar o chá do seu funcionário.
        </p>

        <label for="">Divulgação
        </label>
        <p>Divulga o link personalizado do chá de forma rápida e automatizada. </p>

        <label for="">Presentes </label>
        <p>Os convidados compram através da loja e pagam com cartão, boleto ou Pix </p>

        <label for="">Créditos
        </label>
        <p>Os presentes viram créditos para os pais utilizarem para compras pelo e-commerce da Pipooh e em lojas
            parceiras.</p>

        <label for="">Entrega do Chá</label>
        <p>RH encaminha o e-mail com o link para o colaborador onde passa a administrar a conta do chá.
        </p>

        <label for="">Compras</label>
            <p>Utiliza os créditos para comprar os produtos de acordo com a necessidade do neném.</p>
            <p>As compras podem ser realizadas na Pipooh ou em lojas parceiras (ganha cashback).</p>

            <label for="">Benefícios </label>
            <p>Acumula pontos e troca produtos, e têm acesso a produtos na promoção toda sexta feira.</p>
            <p>O RH também pode presentear faturado com Créditos, kit ou kits personalizados, tudo pelo sistema.
            </p>

            
        <div class="dados" style="text-align: left;">
            <h4>Fale conosco</h4>
            <p>Preencha o formulário e agende uma apresentação 
            </p>
            <div class="row" style="margin-right: 15px;">
                <div class="col-6">
                    <input type="text" [(ngModel)]="empresa" placeholder="Empresa">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="nome" placeholder="Nome">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="telefone" placeholder="Telefone">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="email" placeholder="Email">
                </div>
                <br>
                <div class="col-12">
                    <a (click)="enviar()" style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;">Enviar</a>
                </div>
            </div>
        </div>
    </div> -->
</div>
<app-footer></app-footer>