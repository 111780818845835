import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LembreteService } from 'src/app/_services/lembrete.service';

@Component({
  selector: 'app-contatos-lembrete',
  templateUrl: './contatos-lembrete.component.html',
  styleUrls: ['./contatos-lembrete.component.css']
})
export class ContatosLembreteComponent implements OnInit {
  contatos: any = [];
  contato: any = [];
  fkUsuario: any;
  fkEvento: any;
  loading: any = false;
  constructor(
    private _lembreteService : LembreteService,
    private toastr : ToastrService,
  ) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.fkUsuario = urlParams.get('id');
    this.fkEvento = urlParams.get('evento');
    this.buscarContatos();
  }
  buscarContatos(){
    this._lembreteService.buscarContatos({"fkUsuario": this.fkUsuario, "fkEvento": this.fkEvento}).then(data => {
      this.contatos = data.payload;
    });
  }
  inserirContato(){
    this.loading = true;
    if(!this.contato.telefone || this.contato.telefone == ""){
      this.toastr.error("Telefone inválido");
      this.loading = false;
      return;
    }
    if(!this.contato.nome || this.contato.nome == ""){
      this.toastr.error("Nome inválido");
      this.loading = false;
      return;
    }
    this._lembreteService.inserirContato({"fkUsuario": this.fkUsuario, "fkEvento": this.fkEvento, "telefone": this.contato.telefone, "nome": this.contato.nome}).then(data => {
      if(data.success){
        this.toastr.success("Contato Inserido com sucesso!");
        this.buscarContatos();
        this.contato = [];
        this.loading = false;
      } else {
        this.loading = false;
      }
    })
    this.loading = false;
  }
  deleteConvidado(id){
    this._lembreteService.deleteContato({"fkContato": id}).then(data => {
      if(data.success){
        this.toastr.success("Contato deletado com sucesso!");
        this.buscarContatos();
      }
    })
  }

}
