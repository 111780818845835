<app-header></app-header>
<div class="row justify-content-center" style="margin: 25px;">
    <div class="col-lg-6">
        <div class="section-title" style="text-align: center;">
            <h2>Parceiro</h2>
        </div>
        <p style="text-align: center;">Divulgue seu negócio para mais de <strong>40 mil mães. </strong>
        </p>
        <p style="text-align: center;">Seja o destino de compra dos créditos ganhos em chá de bebê, hoje com mais de
            R$400mil em carteira das mães e aceite a Pipooh como meio de pagamento em sua loja física ou online. </p>
        <p style="text-align: center;">Seu estabelecimento fica disponível no aplicativo das mães e você quem define as
            palavras chaves que quer ser encontrada. </p>

        <div class="dados" style="text-align: left;">
            <h5 style="color: #01B1AF;">Entre em contato e seja um parceiro:</h5>
            <div class="row" style="margin-right: 15px;">
                <div class="col-6">
                    <input type="text" [(ngModel)]="nome" placeholder="Nome da loja">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="segmento" placeholder="Segmento">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="telefone" placeholder="Telefone">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="email" placeholder="E-mail">
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="contato" placeholder="Nome">
                </div>
                <br>
                <div class="col-12">
                    <a (click)="enviar(modalConfirmacao)"
                        style="cursor: pointer; text-align: center; display: block; color: white; padding: 10px; background-color: #01B1AF;border-radius: 10px; margin-bottom: 25px;">Enviar</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 hidden-xs">
        <img src="../../assets/img/parceiro.png" width="80%" alt="">
    </div>
</div>

<!-- Modais -->
<ng-template #modalConfirmacao let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body info-cadastro" style="margin: 25px;">
        <div class="container-fluid ">
            <h6>Solicitação enviada com sucesso!</h6>
            <div class="row">
                <p>Entraremos em contato em até 24 horas para agendar uma apresentação do funcionamento do projeto de
                    parceria Pipooh.</p>
            </div>
        </div>
    </div>
</ng-template>
<button class="btn btn-lg btn-outline-primary" [hidden]="true">Launch demo modal</button>
<app-footer></app-footer>