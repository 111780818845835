import { Component, OnInit } from '@angular/core';
import {
  ConfirmDialogModel,
  ConfirmDialogComponent,
} from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConvidadoService } from '../_services/convidado.service';

@Component({
  selector: 'app-carrinho-revelacao',
  templateUrl: './carrinho-revelacao.component.html',
  styleUrls: ['./carrinho-revelacao.component.css'],
})
export class CarrinhoRevelacaoComponent implements OnInit {
  pedidoToken: any;
  revelacaoID: any;
  eventoID: any;
  gemeos: any;
  result: string = '';
  votacao:any;
  constructor(public dialog: MatDialog, private _convidado: ConvidadoService) {}

  ngOnInit(): void {
    this.pedidoToken = localStorage.getItem('pedidoAnt');
    this.revelacaoID = localStorage.getItem('revelacaoID');
    this.eventoID = localStorage.getItem('eventoRevelacao');
    this.gemeos = localStorage.getItem('gemeos');
    console.log(
      this.pedidoToken +
        '\n' +
        this.revelacaoID +
        '\n' +
        this.eventoID +
        '\n' +
        this.gemeos
    );
  }
  confirmDialog(nome): void {
    this.votacao=nome;
    const message = `Seu voto é:  ${nome}`;

    const dialogData = new ConfirmDialogModel('Confirmação de voto', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      console.log(dialogResult);
      if (dialogResult) {
        // votar e inserir voto
        console.log(this.votacao)
        this._convidado.insertVotosRevelacao({revelacaoID:this.revelacaoID, eventoID:this.eventoID, pedidoToken:this.pedidoToken, voto:this.votacao}).then((data) => {
          window.location.href = "https://checkout.pipooh.com.br/?id=" + this.pedidoToken;

        });
      }
      this.result = dialogResult;
    });
  }
}
