import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaPontuacaoService } from '../_services/categoria-pontuacao.service';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.css']
})
export class BeneficiosComponent implements OnInit {

  constructor(private _categoriaPontuacaoService: CategoriaPontuacaoService,
    private _router: Router,) { }
  categorias: any = [];
  //dropdown
  dropdown = [];
  ngOnInit(): void {
    this.buscarCategoriaPontuacao();
    this.dropdown = [
      {
        "titulo": "Como acumular pontos?",
        "descricao": 'Presentes ganhos ou compra de crédito:<br> R$1,00 = 50 pontos(A cada R$1,00 você acumula 50 pontos). <br> Valores recebidos das indicações: <br> R$1,00 = 25 pontos(A cada R$1,00 você acumula 25 pontos).',
        "status": false
      },
      {
        "titulo": "Promoção de produtos",
        "descricao": 'Toda sexta-feira produtos em promoção são liberados com limitação de estoque dos quais são adquiridos por ordem de compra. <br> Confira na tabela abaixo qual a sua categoria e quantos <strong>produtos na promoção</strong> terá direito de compra no mês não é cumulativo todo dia 01 o valor é zerado podendo adquirir novamente',
        "status": false
      },
      {
        "titulo": "Meus pontos vencem?",
        "descricao": 'Sim, no mês subsequente ao mês de aniversário da sua conta (data deabertura da conta), os pontos zeram e sua categoria regride para uma anterior. <br /> Ex: a abertura da sua conta foi janeiro/2022, o seus pontos e categoria são zerados/regredido no dia 01 de fevereiro/2023.',
        "status": false
      },
    ]
  }

  formatMilhares(string){
    if(string != ""){
      return string.toLocaleString('pt-BR');
    }
  }

  buscarCategoriaPontuacao() {
    this._categoriaPontuacaoService.buscarCategorias({}).then(data => {
      this.categorias = data.payload;
      
    });
  }

  ancora(tag) {
    this._router.navigate(['/beneficios'], { fragment: tag });
  }

}
