<app-header></app-header>


<div class="row justify-content-center">
    <div class="col-lg-8">
        <div class="row justify-content-center">
            <div class="col-12" style="text-align: center; color: white;">
                <div style="height:150px; margin-top: 100px;" class="instagram">
                    <div>
                        <h2 style="color:#01B1AF ; font-weight: 700; font-size: 35px; ">Vai presentear?</h2>
                        <h3 style="color: #939393;">Pesquise aqui o nome da mamãe/papai</h3>
                    </div>
                </div>
                <div class="">
                    <div class="input-group mb-3" style="border-radius: 25px;">
                        <input style="border-radius: 25px;" type="text" class="form-control"
                            (keyup.enter)="buscarMamae()" [(ngModel)]="this.mamaeNome"
                            placeholder="Pesquisar por mamãe/papai" aria-label="Recipient's username"
                            aria-describedby="button-addon2" />
                        <div class="input-group-append mr-md-5">
                            <button style="border-radius: 25px;" class="btn btn-outline-secondary"
                                (click)="buscarMamae()" type="button" id="button-addon2">
                                Buscar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row justify-content-center">
    <div class="col-xs-12 col-lg-8 p-xs-1 ">


        <div class="row" *ngIf="this.pesquisaMamae">
            <div class="col-xs-12 col-md-4"
                *ngFor="let mamae of pesquisaMamae | slice: (page-1) * pageSize : page * pageSize">
                <div class="row" >

                    <div class="col-md-12 col-lg-12" style="margin-bottom: 10px; text-align: center;">
                        <img style=" max-width: 100%;max-height: 400px; min-width: 50%;" class="imagemPerfil"
                            src="https://img.pipooh.com.br/{{mamae.UserFoto}}" alt="">
                    </div>
                    <div class="col-lg-12" style="text-align: center;">
                        <p>mamãe: {{mamae.UserName}}</p>
                    </div>
                    <div class="" style="text-align: center; margin-bottom: 20px;">
                        <a href="/cha-fralda?id={{mamae.UserID}}" class="btn btn-outline-secondary" style="border-radius: 25px;">Ir para perfil</a>
                    </div>
                </div>

            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 justify-content-center">
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="7"
                    [collectionSize]="pesquisaMamae.length" [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>