import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RifaService {
  bilhetes: any;
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

  postData(url, formData): Promise<any> {

    return lastValueFrom(this.http.post(url, formData, { responseType: 'json' })).then(response => {
      var aux: any = response;
      if (aux.success == false) {
        this.toastr.error(aux.error, "Erro de solicitação");
        return aux;
      }
      else {
        return aux;
      }
    });

  }

  getRifa(formData) {

    return this.postData(`${this.URLAspNet}rifa`, formData);
  }
  getBilheteQuantidade(formData) {
    return this.postData(`${this.URLAspNet}rifa/bilhete/quantidade`, formData);

  }
  getBilheteValor(formData) {
    return this.postData(`${this.URLAspNet}rifa/valor`, formData);

  }
  getBilheteValorPorEvento(formData) {
    return this.postData(`${this.URLAspNet}rifa/valor/evento`, formData);

  }
  getNomeRifa(formData){
    return this.postData(`${this.URLAspNet}rifa/select/nomeRifa`, formData);

  }
  getRifaPremios(formData) {
    return this.postData(`${this.URLAspNet}rifa/premios`, formData);

  }
  getRifaPremiosPorEvento(formData) {
    return this.postData(`${this.URLAspNet}rifa/premios/evento`, formData);

  }
  finalizarRifa(formData) {
    return this.postData(`${this.URLAspNet}rifa/insert/bilhetes`, formData);

  }


  addBilhete(bilhete) {
    this.bilhetes.push(bilhete);
    localStorage.setItem('rifa', JSON.stringify(this.bilhetes));

  }
  getLocalBilhetes() {
    if (JSON.parse(localStorage.getItem('rifa')) == null) {
      return this.bilhetes = [];
    }
    return this.bilhetes = JSON.parse(localStorage.getItem('rifa'));
  }

  getBilhetes() {
    return this.bilhetes;
  }
  removeBilhete(bilhete) {
    this.bilhetes.forEach((item, index) => {
      if (item === bilhete) this.bilhetes.splice(index, 1);
    });
    localStorage.setItem('rifa', JSON.stringify(this.bilhetes));
  }
  clearBilhetes() {
    this.bilhetes = [];
    return this.bilhetes;
  }

}
