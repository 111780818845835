import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SEOService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
) { }

updateMetaInfo(content,property) {
    this.meta.updateTag({ name: property, content: content });
    this.meta.addTag({name: 'twitter:title', content: 'pipooh por aqui'});
    this.meta.updateTag({ property: 'og:image', content: 'https://blog.logrocket.com/wp-content/uploads/2021/07/adding-dynamic-meta-tags-react-app-without-ssr.png' }, "property='og:image'");


}

updateTitle(title?: string) {
    if (!title) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) { route = route.firstChild; }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)).subscribe((event) => {
                    this.titleService.setTitle(event['title'] + ' | Pipooh');
                });
    } else {
        this.titleService.setTitle(title + ' | Pipooh');
    }
}
}