import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CategoriaPontuacaoService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any> {
      return this.http.post(url, formData).toPromise().then(response => {
          var aux: any = response;
          if (aux.success == false) {
              this.toastr.error(aux.error, "Erro de solicitação");
              return aux;
          }
          else {
              return aux;
          }
      });
  }

  buscarCategorias(formData) {
    return this.postData(`${this.URLAspNet}categoriaPontuacao/web/select/categorias`, formData);
  }
}
