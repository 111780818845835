import { Component, OnInit, ViewChild, NgModule, AfterViewInit, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventoEstabelecimentoService } from '../_services/evento-estabelecimento.service';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from '../_services/usuario.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { cpf } from 'cpf-cnpj-validator';
import { PedidoIngressoService } from '../_services/pedigo-ingresso.service';
import { CadastroEventoService } from '../_services/cadastro-evento.service';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-evento-estabelecimento',
  templateUrl: './evento-estabelecimento.component.html',
  styleUrls: ['./evento-estabelecimento.component.css']
})

export class EventoEstabelecimentoComponent implements AfterViewInit, OnInit {
  //Modais
  @ViewChild('modalCpfDescontoPipooh') modalCpfDescontoPipooh;
  @ViewChild('modalCpfSemDescontoPipooh') modalCpfSemDescontoPipooh;
  @ViewChild('modalComprar') modalComprar;
  @ViewChild('modalCadastrar') modalCadastrar;
  @ViewChild('modalCadastrarSimplificado') modalCadastrarSimplificado;
  @ViewChild('modalRegulamento') modalRegulamento;
  @ViewChild('modalLogin') modalLogin;
  @ViewChild('modalCompraRealizada') modalCompraRealizada;
  @ViewChild('modalEnquete') modalEnquete;
  //evento 
  evento: any = [];
  fkEvento: any;
  imagesDesktop: any = [];
  //Sessoes
  sessoes: any = [];
  datas: any = [];
  horarios: any = [];
  temas: any = [];
  dataSelecionada: any = '';
  sessaoSelecionada: any = '';
  temaSelecionada: any = '';
  sessoesSelecionada: any = [{ "id": "", "data": "" }];
  //Ingressos
  inteira: any = 0;
  meia: any = 0;
  total: any = 0;
  desconto: any = 0;
  descontoFake: any = 0;
  descontoPipooh: any = 0;
  ingressos: any = [];
  ingressosComprados: any = [];
  enquetes: any = [];
  ingressosDisponiveis: any = 0;
  msgIngressoDisponivel: any = false;
  //Dados
  cpf: any = "";
  cpfValido: any = false;
  usuario: any = [];
  saldo: any = 0;
  token: any;
  //loading
  loading: any = false;
  //forms
  formLogin: FormGroup;
  formSimples: FormGroup;
  formCadastrar: FormGroup;
  formCadastrarSimplificado: FormGroup;
  submitted: any = false;
  semanas: any = [];
  semana: any;
  tipo: any;
  fila: any = false;
  //
  cor: any ='#fdf6de';
  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private _eventoEstabelecimentoService: EventoEstabelecimentoService,
    private _usuarioService: UsuarioService,
    private _pedidoIngressoService: PedidoIngressoService,
    private _cadastroEventoService: CadastroEventoService,
    private elemento: ElementRef
  ) { }

  ngAfterViewInit(): void {
    if (environment.domain == 'https://sistema.zull.com.br/') {
      this.elemento.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
      this.cor = "#fff";
    }
  }

  ngOnInit(): void {
    
    const urlParams = new URLSearchParams(window.location.search);
    this.fkEvento = urlParams.get('id');
    this.getInfoEvento();
    this.formLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      senha: ['', [Validators.required]],
    });
    this.formSimples = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      celular: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11), Validators.pattern('[0-9]*')]],
      UserID: [''],
    });
    this.formCadastrar = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, , Validators.email]],
      celular: ['', [Validators.required, , Validators.minLength(11), Validators.maxLength(11), Validators.pattern('[0-9]*')]],
      sexo: ['mamãe', [Validators.required]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      senha: ['', [Validators.required,]],
      senhaR: ['', [Validators.required,]],
      tipo: ['criancaNasceu', [Validators.required,]],
      termos: ['', [Validators.required,]],
      politica: ['', [Validators.required,]],
      semana: ['',],
      pedidoValor: ['',],
      ultimaMenstruacao: ['',],
      dataNascimentoCrianca: ['',],
    });
    this.formCadastrarSimplificado = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, , Validators.email]],
      celular: ['', [Validators.required, , Validators.minLength(11), Validators.maxLength(11), Validators.pattern('[0-9]*')]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      termos: ['', [Validators.required,]],
      politica: ['', [Validators.required,]],
      pedidoValor: ['',],
    });
    for (var i = 42; i >= 1; i--) {
      if (i != 0) {
        this.semanas[i] = i;
      }
    }


    this.semanas.shift();
  }

  formatDate(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).add(0, 'days').format('DD/MM/YYYY');
      return startTime;
    }
  }
  formatDateBD(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).format("YYYY-MM-DD");
      return startTime;
    }
  }

  formatDecimal(string) {
    if (string != '') {
      return parseFloat(string).toFixed(2);
    }
  }

  open(content) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title,',
        size: 'md',
        centered: true,
        windowClass: 'modal',
      })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }


  getInfoEvento() {
    this._eventoEstabelecimentoService.buscarEvento({ "fkEvento": this.fkEvento }).then((data) => {
      this.evento = data.payload.evento[0];
      // this.temas = data.payload.temas;
      this.datas = data.payload.datas;
      this.enquetes = data.payload.enquetes;
      this.enquetes.forEach(enquete => {
        if (enquete.tipo != 'Parágrafo') {
          this._eventoEstabelecimentoService.buscarEnqueteOpcao({ "fkEnquete": enquete.id }).then(data => {
            enquete.opcoes = data.payload;
          })
        }
      });
      var img = "https://img.pipooh.com.br/" + this.evento.imagem
      this.imagesDesktop.push(img)
      console.log("datas", this.datas)
    });
  }
  alterandoData() {
    if (this.dataSelecionada != "") {
      this._eventoEstabelecimentoService.buscarSessoesDataTema({ "fkEvento": this.fkEvento, "data": this.dataSelecionada }).then(data => {
        if (data.success) {
          this.temaSelecionada = "";
          this.sessaoSelecionada = "";
          this.sessoesSelecionada = [{ "id": "", "data": "" }];
          this.inteira = 0;
          this.meia = 0;
          this.ingressos = [];
          this.temas = data.payload;
        }
      })
    }
    this.calculoValorTotal();

  }

  alterandoSessao() {
    if (this.sessaoSelecionada != "") {
      this.sessoes.forEach(sessao => {
        if (sessao.id == this.sessaoSelecionada) {
          this.sessoesSelecionada = [{ "id": "", "data": "" }];
          this.inteira = 0;
          this.meia = 0;
          this.ingressos = [];
          this.sessoesSelecionada[0] = sessao;
          console.log("sessao", sessao)
          console.log("sessoesSelecionada", this.sessoesSelecionada[0])
        }
      });

    }
    this.calculoValorTotal();

  }

  alterandoTema() {
    if (this.temaSelecionada != "") {
      this._eventoEstabelecimentoService.buscarSessoesTema({ "fkEvento": this.fkEvento, "tema": this.temaSelecionada, "data": this.dataSelecionada }).then(data => {
        if (data.success) {
          this.sessaoSelecionada = "";
          this.sessoesSelecionada = [{ "id": "", "data": "" }];
          this.inteira = 0;
          this.meia = 0;
          this.ingressos = [];
          this.sessoes = data.payload;
          console.log("sessoes", this.sessoes)
        }
      })
    }
    this.calculoValorTotal();

  }

  addSessao() {
    this.sessoesSelecionada.push({ "id": "", "data": "" });
  };

  removerSessao(item) {
    var index = this.sessoesSelecionada.indexOf(item);
    this.sessoesSelecionada.splice(index, 1);
    if (this.sessoesSelecionada.length == 0) {
      this.sessoesSelecionada.push({ "id": "", "data": "" });
    }
    this.calculoValorTotal();
  }
  changeQtdIngresso(kind, tipo) {
    if (tipo == 'inteira') {
      if (kind == 'up') {
        if ((this.sessoesSelecionada[0].ingressos - this.sessoesSelecionada[0].ingressosVendidos) >= (this.inteira + this.meia + 1)) {
          if(this.sessoesSelecionada[0].multiplosIngressos === false && this.ingressos.length >= 1){
            this.toastr.error("Permitido um ingresso por vez!");
          }else {
            this.inteira++;
            this.ingressos.push({ "nome": "", "dataNascimento": "", "tipo": "Inteira", "valor": this.evento.valor });
          }
          
        } else if (((this.sessoesSelecionada[0].ingressos - this.sessoesSelecionada[0].ingressosVendidos) <= (this.inteira + this.meia + 1)) && this.sessoesSelecionada[0].fila == true) {
          if (this.sessoesSelecionada[0].limiteFila > this.sessoesSelecionada[0].ingressosFila + this.ingressos.length) {
            if(this.sessoesSelecionada[0].multiplosIngressos === false && this.ingressos.length >= 1){
              this.toastr.error("Permitido um ingresso por vez!");
            }else {
              this.inteira++;
            this.fila = true;
            this.ingressos.push({ "nome": "", "dataNascimento": "", "tipo": "Inteira", "valor": this.evento.valor });
            }
            
          } else {
            this.toastr.error("Limite da fila de espera atingido");
          }

        }
      } else {
        if (this.inteira >= 1) {
          this.inteira--;
          this.ingressos.pop();
        }
      }
    } else {
      if (kind == 'up') {
        if ((this.sessoesSelecionada[0].ingressos - this.sessoesSelecionada[0].ingressosVendidos) >= (this.inteira + this.meia + 1)) {
          this.meia++;
          this.ingressos.push({ "nome": "", "dataNascimento": "", "tipo": "Meia", "valor": this.evento.valor / 2 });
        } else if (((this.sessoesSelecionada[0].ingressos - this.sessoesSelecionada[0].ingressosVendidos) <= (this.inteira + this.meia + 1) && this.sessoesSelecionada[0].fila == true)) {
          if (this.sessoesSelecionada[0].limiteFila > this.sessoesSelecionada[0].ingressosFila + this.ingressos.length) {
            this.meia++;
            this.fila = true;
            this.ingressos.push({ "nome": "", "dataNascimento": "", "tipo": "Meia", "valor": this.evento.valor / 2 });
          } else {
            this.toastr.error("Limite da fila de espera atingido");
          }
        }

      } else {
        if (this.meia >= 1) {
          this.meia--;
          this.ingressos.pop();
        }
      }
    }
    this.calculoValorTotal();
  }

  calculoValorTotal() {
    var qtdSessoes = 0;
    this.sessoesSelecionada.forEach(sessao => {
      if (sessao.id != '') {
        qtdSessoes++
      }
    })
    if (qtdSessoes == 0) {
      qtdSessoes++
    }
    this.total = qtdSessoes * ((this.inteira * this.evento.valor) + (this.meia * (this.evento.valor_meia)));

  }

  buscarCpf() {
    this.loading = true;
    this.msgIngressoDisponivel = false;
    if (cpf.isValid(this.cpf) == false) {
      this.toastr.error("CPF inválido");
      this.loading = false;
      this.cpfValido = false;
      this.usuario = [];
      return;
    }
    if (this.evento.cadastro == 'Pipooh') {
      this._usuarioService.selecionarCpf({ "cpf": this.cpf }).then(data => {
        if (data.payload.length > 0) {
          this.usuario = data.payload;
          this.formCadastrar.controls['cpf'].setValue(this.cpf);
          if (this.evento.desconto_pipooh == 1) {
            this.descontoPipooh = 1;
            if (this.usuario[0].categoria == 'Baby' && this.usuario[0].status != 1) {
              this.desconto = this.evento.baby * 0.01;
            } else if (this.usuario[0].categoria == 'Top' && this.usuario[0].status != 1) {
              this.desconto = this.evento.top * 0.01;
            } else if (this.usuario[0].categoria == 'Super' && this.usuario[0].status != 1) {
              this.desconto = this.evento.super * 0.01;
            } else if (this.usuario[0].categoria == 'Vip' || this.usuario[0].status == 1) {
              this.desconto = this.evento.vip_assinante * 0.01;
            }
          }

        } else {
          if (this.evento.desconto_pipooh == 1) {
            this.descontoFake = this.evento.baby * 0.01;
          }
        }
        if (this.sessoesSelecionada[0].limitadoCpf == true) {
          this._pedidoIngressoService.buscarIngressoPorCpf({ "fkEvento": this.fkEvento, "tema": this.temaSelecionada, "cpf": this.cpf }).then(data => {
            var ingressosCpf = data.payload[0];
            if (this.ingressos.length + ingressosCpf.ingressos > this.sessoesSelecionada[0].quantidadeCpf) {
              this.loading = false;
              this.msgIngressoDisponivel = true;
              this.usuario = [];
              this.ingressosDisponiveis = this.sessoesSelecionada[0].quantidadeCpf - ingressosCpf.ingressos;
            } else {
              this.cpfValido = true;
              this.loading = false;
            }
          })
        } else {
          this.cpfValido = true;
          this.loading = false;
        }

      }).catch(e => { this.loading = false; this.usuario = [] })
    } else if (this.evento.cadastro == 'Simplificado') {
      this._cadastroEventoService.selecionarCpf({ "fkEvento": this.fkEvento, "cpf": this.cpf }).then(data => {
        console.log("data", data)
        if (data.payload.length > 0) {
          // this.usuario = data.payload;
          this.formCadastrarSimplificado.controls['cpf'].setValue(this.cpf);
          if (this.evento.desconto_pipooh == 1) {
            this.descontoFake = this.evento.baby * 0.01;

          }
        }
        if (this.sessoesSelecionada[0].limitadoCpf == true) {
          this._pedidoIngressoService.buscarIngressoPorCpfSimplificado({ "fkEvento": this.fkEvento, "tema": this.temaSelecionada, "cpf": this.cpf }).then(data => {
            var ingressosCpf = data.payload[0];
            if (this.ingressos.length + ingressosCpf.ingressos > this.sessoesSelecionada[0].quantidadeCpf) {
              this.loading = false;
              this.msgIngressoDisponivel = true;
              this.usuario = [];
              this.ingressosDisponiveis = this.sessoesSelecionada[0].quantidadeCpf - ingressosCpf.ingressos;
            } else {
              this.cpfValido = true;
              this.loading = false;
            }
          }).catch(e => { this.loading = false });
        } else {
          this.cpfValido = true;
          this.loading = false;
        }
      }).catch(e => { this.loading = false; this.usuario = [] })
    }
  }

  onSubmitFormSimples() {
    this.loading = true;
    if (this.formSimples.status == "VALID") {
      var preenchido = true;
      var dateInvalide = true;
      this.ingressos.forEach(ingresso => {
        if ((!ingresso.nome || ingresso.nome == "") || (!ingresso.dataNascimento || ingresso.dataNascimento == "")) {
          preenchido = false;
        }
      });
      if (!preenchido) {
        this.toastr.error("Preencha todos os dados dos ingressos");

        this.loading = false;
        return;
      } else {
        if (this.evento.enquete == 1 || this.evento.enquete == true) {
          this.loading = false;
          this.tipo = "simples";
          this.modalService.dismissAll(this.modalComprar);
          this.open(this.modalEnquete);
        } else {
          if (this.evento.cadastro == 'Pipooh') {
            this._pedidoIngressoService.inserirPedido({ "UserID": this.formSimples.value.UserID, "fkEvento": this.fkEvento, "nome": this.formSimples.value.nome, "email": this.formSimples.value.email, "cpf": this.cpf, "celular": this.formSimples.value.celular, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.desconto), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "token": this.usuario.token, "enquete": "" }).then(data => {
              this.loading = false;

              if (this.evento.cobranca == true) {
                window.location.href = "https://checkout.pipooh.com.br/?id=" + data.payload[0]["token"];
              } else {
                this.ingressosComprados = data.payload;
                this.modalService.dismissAll(this.modalComprar);
                this.open(this.modalCompraRealizada);
              }
            }).catch(e => { this.loading = false; })
          }
          if (this.evento.cadastro == 'Simplificado') {
            this._pedidoIngressoService.inserirPedidoSimplificado({ "UserID": this.formSimples.value.UserID, "fkEvento": this.fkEvento, "nome": this.formSimples.value.nome, "email": this.formSimples.value.email, "cpf": this.cpf, "celular": this.formSimples.value.celular, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.desconto), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "token": this.usuario.token, "enquete": "" }).then(data => {
              this.loading = false;

              if (this.evento.cobranca == true) {
                window.location.href = "https://checkout.pipooh.com.br/?id=" + data.payload[0]["token"];
              } else {
                this.ingressosComprados = data.payload;
                this.modalService.dismissAll(this.modalComprar);
                this.open(this.modalCompraRealizada);
              }
            }).catch(e => { this.loading = false; })
          }

        }
      }
    } else {
      this.toastr.error("Preencha o formulário corretamente")
    }
    this.submitted = true;
    this.loading = false;
  }
  comprarComCreditosPipooh() {
    this.loading = true;
    if (this.formSimples.status == "VALID") {
      var preenchido = true;
      var dateInvalide = true;
      this.ingressos.forEach(ingresso => {
        if ((!ingresso.nome || ingresso.nome == "") || (!ingresso.dataNascimento || ingresso.dataNascimento == "")) {
          preenchido = false;
        }
      });
      if (!preenchido) {
        this.toastr.error("Preencha todos os dados dos ingressos");

        this.loading = false;
        return;
      } else {
        if (this.evento.enquete == 1 || this.evento.enquete == true) {
          this.loading = false;
          this.tipo = "creditos"
          this.modalService.dismissAll(this.modalComprar);
          this.open(this.modalEnquete);
        } else {
          this._pedidoIngressoService.inserirPedidoResgate({ "UserID": this.formSimples.value.UserID, "fkEvento": this.fkEvento, "nome": this.formSimples.value.nome, "email": this.formSimples.value.email, "cpf": this.cpf, "celular": this.formSimples.value.celular, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.desconto), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "token": this.usuario.token, "enquete": "" }).then(data => {
            this.loading = false;
            if (data.success) {
              this.ingressosComprados = data.payload;
              this.modalService.dismissAll(this.modalComprar);
              this.open(this.modalCompraRealizada);
            }
          }).catch(e => { this.loading = false; })
        }
      }
    } else {
      this.toastr.error("Preencha o formulário corretamente")
    }
    this.submitted = true;
    this.loading = false;
  }
  onSubmitFormLogin() {
    this.loading = true;
    if (this.formLogin.status == "VALID") {
      if (this.evento.cadastro == 'Pipooh') {
        this._usuarioService.login({ "email": this.formLogin.value.email, "senha": this.formLogin.value.senha }).then(data => {
          this.loading = false;
          console.log("login", data)
          if (data.payload.length == 0) {
            this.toastr.error("E-mail ou senha inválido");
          } else {
            this.formSimples.controls['nome'].setValue(data.payload[0].UserName);
            this.formSimples.controls['email'].setValue(data.payload[0].UserEmail);
            this.formSimples.controls['UserID'].setValue(data.payload[0].UserID);
            this.formSimples.controls['celular'].setValue(data.payload[0].UserTelefone);
            this.ingressos[0].nome = data.payload[0].UserName;
            this.usuario = data.payload[0];
            this.token = this.usuario.token;
            this.modalService.dismissAll(this.modalLogin);
            // this.open(this.modalLogin);
            this.open(this.modalComprar);
          }
        }).catch(e => { this.loading = false; })
      } else if (this.evento.cadastro == 'Simplificado') {
        this._cadastroEventoService.login({ "email": this.formLogin.value.email, "senha": this.formLogin.value.senha }).then(data => {
          this.loading = false;
          console.log("login", data)
          if (data.payload.length == 0) {
            this.toastr.error("E-mail ou senha inválido");
          } else {
            this.formSimples.controls['nome'].setValue(data.payload[0].UserName);
            this.formSimples.controls['email'].setValue(data.payload[0].UserEmail);
            this.formSimples.controls['UserID'].setValue(data.payload[0].UserID);
            this.formSimples.controls['celular'].setValue(data.payload[0].UserTelefone);
            this.ingressos[0].nome = data.payload[0].UserName;
            this.usuario = data.payload[0];
            this.token = this.usuario.token;
            this.modalService.dismissAll(this.modalLogin);
            // this.open(this.modalLogin);
            this.open(this.modalComprar);
          }
        }).catch(e => { this.loading = false; })
      }


    } else {
      this.toastr.error("Preencha o e-mail e senha ")
    }
    this.submitted = true;
    this.loading = false;
  }
  onSubmitFormCadastrar() {
    this.submitted = true;
    this.loading = true;
    var celular = this.formCadastrar.get('celular').value;
    celular = celular.replace('(', '');
    celular = celular.replace(')', '');
    celular = celular.replace(' ', '');
    celular = celular.replace('-', '');
    console.log("celular", celular)
    this.formCadastrar.controls['celular'].setValue(celular);
    if (!this.formCadastrar.valid) {
      this.loading = false;
      this.toastr.error("Preencha todos os dados corretamente")
      return false;
    } else {
      this._usuarioService.selecionarEmail({ "fkEvento": this.fkEvento, "email": this.formCadastrar.value.email }).then(data => {
        if (data.payload.length > 0) {
          this.loading = false;
          this.toastr.error("E-mail já cadastrado na Pipooh", "E-mail inválido");
        } else {
          if (this.formCadastrar.value.nome.indexOf(' ') <= 0) {
            this.toastr.error('Informe o nome completo', 'Falha no cadastro'); this.loading = false; return false;
          };
          if (cpf.isValid(this.formCadastrar.value.cpf) == false) { this.toastr.error('CPF inválido', 'Falha no cadastro'); this.loading = false; return false; }
          if (this.formCadastrar.value.senha != this.formCadastrar.value.senhaR) { this.toastr.error('As senhas não coincidem', 'Falha no cadastro'); this.loading = false; return false; }
          if (this.formCadastrar.value.tipo == "gestante") {
            if (this.formCadastrar.value.semana == undefined || this.formCadastrar.value.semana == "") {
              this.toastr.error('Semana é obrigátoria', 'Falha no cadastro');
              this.loading = false;
              return false;
            }
            else {
              var date = new Date;
              var dias = parseInt(this.formCadastrar.value.semana) * 7;
              date.setDate(date.getDate() - dias);
              this.formCadastrar.value.ultimaMenstruacao = this.formatDateBD(date);
            }
            if (this.formCadastrar.value.ultimaMenstruacao == undefined || this.formCadastrar.value.ultimaMenstruacao == "") { this.toastr.error('Data da menstruação é obrigátoria', 'Falha no cadastro'); this.loading = false; return false; }
          } else if (this.formCadastrar.value.tipo == "criancaNasceu") {
            if (this.formCadastrar.value.dataNascimentoCrianca == undefined || this.formCadastrar.value.dataNascimentoCrianca == "") {
              this.toastr.error('Data de nascimento é obrigátoria', 'Falha no cadastro');
              this.loading = false;
              return false;
            } else {
              this.formCadastrar.value.dataNascimentoCrianca = this.formatDate(this.formCadastrar.value.dataNascimentoCrianca);
              this.formCadastrar.value.dataNascimentoCrianca = this.formatDateBD(this.formCadastrar.value.dataNascimentoCrianca);
            }
          }
          var preenchido = true;
          var dateInvalide = true;
          this.ingressos.forEach(ingresso => {
            if ((!ingresso.nome || ingresso.nome == "") || (!ingresso.dataNascimento || ingresso.dataNascimento == "")) {
              preenchido = false;
            }
          });
          if (!preenchido) {
            this.toastr.error("Preencha todos os dados dos ingressos");

            this.loading = false;
            return;
          } else {
            if (this.evento.enquete == 1 || this.evento.enquete == true) {
              this.loading = false;
              this.tipo = "cadastrar";
              this.modalService.dismissAll(this.modalCadastrar);
              this.open(this.modalEnquete);
            } else {
              this._pedidoIngressoService.inserirPedidoUsuario({ "formulario": this.formCadastrar.value, "fkEvento": this.fkEvento, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.descontoFake), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "enquete": "" }).then(data => {
                if (data.success) {
                  var aux = data.payload[0];
                  this.loading = false;
                  if (this.evento.cobranca == true) {
                    window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];

                  } else {
                    this.ingressosComprados = data.payload;
                    this.modalService.dismissAll(this.modalCadastrar);
                    this.open(this.modalCompraRealizada);
                  }
                } else {
                  this.loading = false;
                }
              }).catch(e => {
                this.loading = false;
              })
            }
          }
        }
      });
    }
  }
  onSubmitFormCadastrarSimplificado() {
    this.submitted = true;
    this.loading = true;
    var celular = this.formCadastrarSimplificado.get('celular').value;
    var email = this.formCadastrarSimplificado.get('email').value;
    celular = celular.replace('(', '');
    celular = celular.replace(')', '');
    celular = celular.replace(' ', '');
    celular = celular.replace('-', '');
    email = email.replace(' ', '');
    this.formCadastrarSimplificado.controls['celular'].setValue(celular);
    this.formCadastrarSimplificado.controls['email'].setValue(email);
    if (!this.evento.termos) {
      this.formCadastrarSimplificado.controls['termos'].setValue('true');
    }
    if (!this.evento.politica) {
      this.formCadastrarSimplificado.controls['politica'].setValue('true');
    }
    if (!this.formCadastrarSimplificado.valid) {
      this.loading = false;
      this.toastr.error("Preencha todos os dados corretamente")
      return false;
    } else {

      if (this.formCadastrarSimplificado.value.nome.indexOf(' ') <= 0) {
        this.toastr.error('Informe o nome completo', 'Falha no cadastro'); this.loading = false; return false;
      };
      if (cpf.isValid(this.formCadastrarSimplificado.value.cpf) == false) { this.toastr.error('CPF inválido', 'Falha no cadastro'); this.loading = false; return false; }

      var preenchido = true;
      this.ingressos.forEach(ingresso => {
        if ((!ingresso.nome || ingresso.nome == "") || (!ingresso.dataNascimento || ingresso.dataNascimento == "")) {
          preenchido = false;
        }
      });
      if (!preenchido) {
        this.toastr.error("Preencha todos os dados dos ingressos");

        this.loading = false;
        return;
      } else {
        if (this.evento.enquete == 1 || this.evento.enquete == true) {
          this.loading = false;
          this.tipo = "cadastrar";
          this.modalService.dismissAll(this.modalCadastrarSimplificado);
          this.open(this.modalEnquete);
        } else {
          this._pedidoIngressoService.inserirPedidoUsuarioSimplificado({ "formulario": this.formCadastrarSimplificado.value, "fkEvento": this.fkEvento, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.descontoFake), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "enquete": "" }).then(data => {
            if (data.success) {
              var aux = data.payload[0];
              this.loading = false;
              if (this.evento.cobranca == true) {
                window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];

              } else {
                this.ingressosComprados = data.payload;
                this.modalService.dismissAll(this.modalCadastrarSimplificado);
                this.open(this.modalCompraRealizada);
              }
            } else {
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
          })
        }

      }
    }
  }

  finalizarComprarIngressos() {
    this.loading = true;
    var preenchido = true;
    this.enquetes.forEach(enquete => {
      if (enquete.tipo == "Caixa") {
        enquete.opcoes.forEach(opcao => {
          if (opcao.check) {
            if (enquete.resposta) {
              enquete.resposta += opcao.opcao + " - ";

            } else {
              enquete.resposta = opcao.opcao + " - ";

            }
          }
        });
      }
      if ((!enquete.resposta || enquete.resposta == "")) {
        preenchido = false;
      }
    });
    if (!preenchido) {
      this.toastr.error("Responda todas as perguntas da enquete");
      this.loading = false;
      return;
    } else {
      if (this.tipo == "cadastrar") {
        if (this.evento.cadastro == 'Pipooh') {
          this._pedidoIngressoService.inserirPedidoUsuario({ "formulario": this.formCadastrar.value, "fkEvento": this.fkEvento, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.descontoFake), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "enquete": this.enquetes, "fila": this.fila }).then(data => {
            if (data.success) {
              var aux = data.payload[0];
              this.loading = false;
              if (this.evento.cobranca == true) {
                window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];

              } else {
                this.token = data.payload[0].token;
                this.ingressosComprados = data.payload;
                this.modalService.dismissAll(this.modalCadastrar);
                this.open(this.modalCompraRealizada);
              }
            } else {
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
          })
        } else if (this.evento.cadastro == 'Simplificado') {
          this._pedidoIngressoService.inserirPedidoUsuarioSimplificado({ "formulario": this.formCadastrarSimplificado.value, "fkEvento": this.fkEvento, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.descontoFake), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "enquete": this.enquetes, "fila": this.fila }).then(data => {
            if (data.success) {
              var aux = data.payload[0];
              this.loading = false;
              if (this.evento.cobranca == true) {
                window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];

              } else {
                this.token = data.payload[0].token;
                this.ingressosComprados = data.payload;
                this.modalService.dismissAll(this.modalCadastrar);
                this.open(this.modalCompraRealizada);
              }
            } else {
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
          })
        }

      } else if (this.tipo == "simples") {
        if (this.evento.cadastro == 'Pipooh') {
          this._pedidoIngressoService.inserirPedido({ "UserID": this.formSimples.value.UserID, "fkEvento": this.fkEvento, "nome": this.formSimples.value.nome, "email": this.formSimples.value.email, "cpf": this.cpf, "celular": this.formSimples.value.celular, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.desconto), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "token": this.usuario.token, "enquete": this.enquetes, "fila": this.fila }).then(data => {
            this.loading = false;

            if (this.evento.cobranca == true) {
              window.location.href = "https://checkout.pipooh.com.br/?id=" + data.payload[0]["token"];
            } else {
              this.ingressosComprados = data.payload;
              this.modalService.dismissAll(this.modalComprar);
              this.open(this.modalCompraRealizada);
            }
          }).catch(e => { this.loading = false; })
        }
        if (this.evento.cadastro == 'Simplificado') {
          this._pedidoIngressoService.inserirPedidoSimplificado({ "UserID": this.formSimples.value.UserID, "fkEvento": this.fkEvento, "nome": this.formSimples.value.nome, "email": this.formSimples.value.email, "cpf": this.cpf, "celular": this.formSimples.value.celular, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.desconto), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "token": this.usuario.token, "enquete": this.enquetes, "fila": this.fila }).then(data => {
            this.loading = false;

            if (this.evento.cobranca == true) {
              window.location.href = "https://checkout.pipooh.com.br/?id=" + data.payload[0]["token"];
            } else {
              this.ingressosComprados = data.payload;
              this.modalService.dismissAll(this.modalComprar);
              this.open(this.modalCompraRealizada);
            }
          }).catch(e => { this.loading = false; })
        }
      } else if (this.tipo = "creditos") {
        this._pedidoIngressoService.inserirPedidoResgate({ "UserID": this.formSimples.value.UserID, "fkEvento": this.fkEvento, "nome": this.formSimples.value.nome, "email": this.formSimples.value.email, "cpf": this.cpf, "celular": this.formSimples.value.celular, "valor": this.total, "valor_desconto": this.formatDecimal(this.total * this.desconto), "desconto_pipooh": this.descontoPipooh, "sessoes": this.sessoesSelecionada, "ingressos": this.ingressos, "token": this.usuario.token, "enquete": this.enquetes, "fila": this.fila }).then(data => {
          this.loading = false;
          if (data.success) {
            this.ingressosComprados = data.payload;
            this.modalService.dismissAll(this.modalComprar);
            this.open(this.modalCompraRealizada);
          }
        }).catch(e => { this.loading = false; })
      }
    }
  }
  dataNascimentoIngresso() {
    this.ingressos[0].dataNascimento = this.formCadastrar.value.dataNascimentoCrianca;
  }
}
