import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;



  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

  getProductsList(formData) {
    return this.postData(`${this.URLAspNet}convidado/select/produto/list`, formData);
  }
  getProductsListaEvento(formData) {
    return this.postData(`${this.URLAspNet}convidado/select/produto/lista/evento`, formData);
  }
  getProductsListAll(formData) {
    return this.postData(`${this.URLAspNet}convidado/select/produto/list_all`, formData);
  }
  getProductsImagem(formData) {
    return this.postData(`${this.URLAspNet}convidado/produto/select/imagem`, formData);
  }
  getCategorias(formData) {
    return this.postData(`${this.URLAspNet}convidado/select/categoria`, formData);
  }
  buscarCategorias(formData) {
    return this.postData(`${this.URLAspNet}produto/app/select/categoria`, formData);
  }
  buscarProdutosMarketplace(formData) {
    return this.postData(`${this.URLAspNet}produto/web/select/lista/marketplace`, formData);
  }

}
