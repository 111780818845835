import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-troca',
  templateUrl: './troca.component.html',
  styleUrls: ['./troca.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TrocaComponent implements OnInit {

  constructor(private _router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _helperService: HelperService) { }
  //Info interessado
  email: any;
  nome: any;
  telefone: any;
  cpf: any;
  cep: any;
  cidade: any;
  estado: any;
  //Produtos
  produtos: any = [];
  produto: any = {
    "marca": "",
    "pacotes": "",
    "tamanho": "",
    "quantidade": "",
    "validade": "",
  }
  ngOnInit(): void {
    this.produtos.push(this.produto);
  }
  adicionarProduto() {
    this.produtos.push(Object.assign({}, this.produtos[this.produtos.length - 1]));
  }
  enviar() {
    if (this.email == "" || this.email == null) {
      this.toastr.error('Email obrigatorio!');
      return;
    }
    if (this.nome == "" || this.nome == null) {
      this.toastr.error('Nome obrigatorio!');
      return;
    }
    if (this.telefone == "" || this.telefone == null) {
      this.toastr.error('Telefone obrigatorio!');
      return;
    }
    this.toastr.success('Solicitação enviado com sucesso!', "Solicitação de troca");
    this._helperService.emailTroca({ "email": this.email, "nome": this.nome, "telefone": this.telefone, "cep": this.cep}).then(data => {
      
    })
  }
  

}
