<app-header></app-header>

<div class="row justify-content-center" style="padding: 25px; ">

    <div class="col-xs-12 col-md-8 p-xs-1">
        <div class="section-title" style="text-align: center;">
            <h2>Parceiros Pipooh:</h2>
        </div>
        <div class="row pt-md-5">
            <div class="col-xs-12 col-md-3">
                <div class="input-group mb-3" style="border-radius: 25px;">
                    <select class="form-control" [(ngModel)]="categoriaSelecionada" style="width: 100%;"
                        (change)="getSearch()" style="border-radius: 25px;">
                        <option value="" style="border-radius: 25px;">Todos</option>
                        <option *ngFor="let cat of categorias" style="border-radius: 25px;" value="{{cat.categoria}}">
                            {{cat.categoria}}
                        </option>

                    </select>
                </div>
            </div>
            <div class="col-xs-12 col-md-9">
                <div class="input-group mb-3" style="border-radius: 25px;">
                    <input style="border-radius: 25px;" type="text" class="form-control" (keyup.enter)="getSearch()"
                        [(ngModel)]="this.busca" placeholder="Produto ou loja desejado"
                        aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <div class="input-group-append mr-md-5">
                        <button style="border-radius: 25px;" class="btn btn-outline-secondary" (click)="getSearch()"
                            type="button" id="button-addon2">
                            Buscar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="this.estabelecimentos">
            <table class="table table-striped text-left">
                <thead>
                    <tr>
                        <th scope="col">Imagem</th>
                        <th scope="col" class="hidden-xs">Nome da loja</th>
                        <th scope="col" class="hidden-xs">Endereço</th>
                        <th scope="col">Categoria</th>
                        <th scope="col">Cashback</th>
                        <!-- <th scope="col"></th> -->
                    </tr>
                </thead>
                <tbody *ngIf="this.estabelecimentos">
                    <tr (click)="abrirEstabelecimento(est, modalEstabelecimento);"
                        *ngFor="let est of this.estabelecimentos | slice: (page-1) * pageSize : page * pageSize">
                        <td><img src="https://img.pipooh.com.br/{{est.foto}}" alt="" style="max-width:70px" /></td>
                        <td class="hidden-xs">{{ est.nomeFantasia }}</td>
                        <td class="hidden-xs" *ngIf="est.modalidade == 'Físico'">{{ est.endereco}}, {{
                            est.numero}} - {{est.cidade}}/{{est.uf}}
                        </td>
                        <td class="hidden-xs" *ngIf="est.modalidade == 'Físico e Ecommerce'">{{
                            est.endereco}}, {{ est.numero}} - {{est.cidade}}/{{est.uf}} - Ecommerce
                        </td>
                        <td class="hidden-xs" *ngIf="est.modalidade == 'Ecommerce'">Ecommerce
                        </td>
                        <td class="hidden-xs"
                            *ngIf="est.modalidade == 'Venda direta' || est.modalidade == 'Franquia' || est.modalidade == 'Servico-Gift'">
                        </td>
                        <td>{{ est.categoria}}</td>
                        <td *ngIf="est.cashback || est.cashback > 0.00">{{ est.cashback}} %</td>
                        <td *ngIf="!est.cashback || est.cashback == 0.00"></td>
                    </tr>

                </tbody>
                <tbody *ngIf="this.loadingBusca == 1">
                    <tr>
                        <td colspan="7" class="text-center">
                            <mat-progress-bar mode="buffer"></mat-progress-bar>
                        </td>
                        <!-- <td colspan="7" class="text-center"> </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row py-3" *ngIf="!this.estabelecimentos">
            <div class="col-xs-12 col-md-4 py-2">
                <div class="media">
                    <h3>Estabelecimento não encontrado</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 justify-content-center">
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5"
                    [collectionSize]="estabelecimentos.length" [rotate]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>




<!-- Modais a partir daqui -->

<ng-template #modalEstabelecimento let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="row">
                <div class="col-md-6">
                    <img src="https://img.pipooh.com.br/{{estabelecimentoDetalhe.foto}}" width="100%">
                </div>
                <div class="col-md-6">
                    <h3>{{estabelecimentoDetalhe.nomeFantasia}}</h3>
                    <!-- <p><i class="fas fa-envelope" style="color: #ff8d29; font-size: 30px"> </i>
                        {{estabelecimentoDetalhe.email}}</p> -->
                    <p *ngIf="estabelecimentoDetalhe.facebook"><i class="fab fa-facebook"
                            style="color: #1877F2; font-size: 30px;" aria-hidden="true"></i> <a href="#"
                            (click)="abrirLink('https://facebook.com/' + estabelecimentoDetalhe.facebook); insertButton('Facebook Loja');">
                            {{estabelecimentoDetalhe.facebook}}</a></p>
                    <p *ngIf="estabelecimentoDetalhe.instagram"><i class="fab fa-instagram"
                            style="font-size: 30px; color: #D37245" aria-hidden="true"></i> <a href="#"
                            (click)="abrirLink('https://instagram.com/' + estabelecimentoDetalhe.instagram); insertButton('Instagram Loja');">
                            {{estabelecimentoDetalhe.instagram}}</a></p>
                    <!-- <p *ngIf="estabelecimentoDetalhe.whatsapp"><i class="fab fa-whatsapp"
                            style="color: rgb(1, 150, 1); font-size: 30px; " aria-hidden="true"></i><a href="#"
                            (click)="abrirLink('https://wa.me/55' + estabelecimentoDetalhe.whatsapp); insertButton('Whatsapp Loja'); ">
                            {{estabelecimentoDetalhe.whatsapp}}</a></p> -->
                    <p *ngIf="estabelecimentoDetalhe.site"><i class="flaticon-link" style="color: #a1ff03;"></i>
                        <a href="#" (click)="abrirLink(estabelecimentoDetalhe.site); insertButton('Site Loja');">
                            {{estabelecimentoDetalhe.site}}</a>
                    </p>
                    <p
                        *ngIf="estabelecimentoDetalhe.modalidade != 'Ecommerce' && estabelecimentoDetalhe.modalidade != 'Venda direta'">
                        <i class="flaticon-placeholder" style="color: #29beff"></i><a href="#"
                            (click)="abrirLink('https://google.com.br/maps/place/' + endereco); insertButton('Endereço Loja');">
                            {{estabelecimentoDetalhe.endereco}},
                            {{estabelecimentoDetalhe.numero}} -
                            {{estabelecimentoDetalhe.cidade}}/{{estabelecimentoDetalhe.uf}}
                        </a>
                    </p>
                    <p><strong>Descricao:</strong> {{estabelecimentoDetalhe.descricao}}</p>
                    <p><i class="fas fa-dollar-sign" style="color: rgb(1, 150, 1); font-size: 30px;"></i><strong>
                            Cashback:</strong>
                        {{estabelecimentoDetalhe.cashback}} %</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalTeste let-modal>
    <div class="modal-header">
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body">
        <p>teste</p>
    </div>
</ng-template>
<app-footer></app-footer>