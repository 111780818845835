import { Injectable } from '@angular/core';
import { environment, environmentAspNet } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrComponentlessModule, ToastrService } from 'ngx-toastr';
import { interval, firstValueFrom, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConvidadoService {
  private URL = `${environment.api}`;
  private URLAspNet = `${environmentAspNet.api}`;

  constructor(private http: HttpClient,
    private toastr: ToastrService) { }

    postData(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

    postDataSemToastr(url, formData): Promise<any>
    {

        return lastValueFrom(this.http.post(url, formData,{responseType: 'json'})).then(response =>
        {
            var aux: any = response;
            if (aux.success == false)
            {
                // this.toastr.error(aux.error, "Erro de solicitação");
                return aux;
            }
            else
            {
                return aux;
            }
        });

    }

    getMamae(formData){

      return this.postData(`${this.URLAspNet}convidado`, formData);
    }
    pedidoConvidadoInsert(formData){
      return this.postData(`${this.URLAspNet}convidado/pedido/insert`, formData);

    }
    pedidoConvidadoValor(formData){
      return this.postData(`${this.URLAspNet}convidado/pedido/valor`, formData);

    }
    confirmarPresenca(formData){
      return this.postDataSemToastr(`${this.URLAspNet}convidado/presenca/insert`, formData);

    }
    getEvento(formData){
      return this.postData(`${this.URLAspNet}convidado/evento/select`, formData);

    }
    getEventoPorID(formData){
      return this.postData(`${this.URLAspNet}convidado/evento/select/id`, formData);

    }
    getConvidadoPorID(formData){
      return this.postData(`${this.URLAspNet}convidado/lista/select/id`, formData);

    }
    deleteConvidadoPorID(formData){
      return this.postData(`${this.URLAspNet}convidado/lista/delete/id`, formData);

    }
    getPremiosRevelacao(formData){
      return this.postData(`${this.URLAspNet}convidado/revelacao/premios/select`, formData);

    }
    getVotosRevelacao(formData){
      return this.postData(`${this.URLAspNet}convidado/revelacao/votos/select`, formData);

    }
    insertVotosRevelacao(formData){
      return this.postData(`${this.URLAspNet}convidado/revelacao/votos/insert`, formData);

    }
}
