import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IvyCarouselModule } from 'angular-responsive-carousel';
//Landing Pages
import { RemarketingLandingComponent } from './landing/remarketing-landing/remarketing-landing.component';
import { PipoohMaisBaratoReComponent } from './landing/pipooh-mais-barato-re/pipooh-mais-barato-re.component';
import { ArmazenamentoFraldaReComponent } from './landing/armazenamento-fralda-re/armazenamento-fralda-re.component';
import { BilhetesRifaReComponent } from './landing/bilhetes-rifa-re/bilhetes-rifa-re.component';
import { PerderProdutosReComponent } from './landing/perder-produtos-re/perder-produtos-re.component';
import { ChaRifaCadComponent } from './landing/cha-rifa-cad/cha-rifa-cad.component';
import { ChaFraldaCadComponent } from './landing/cha-fralda-cad/cha-fralda-cad.component';
import { PerderFraldasCadComponent } from './landing/perder-fraldas-cad/perder-fraldas-cad.component';
import { MaisBilhetesCadComponent } from './landing/mais-bilhetes-cad/mais-bilhetes-cad.component';
import { PreLancamentoComponent } from './landing/pre-lancamento/pre-lancamento.component';
import { ClubePipoohPlanosebeneficiosComponent } from './landing/clube-pipooh-planosebeneficios/clube-pipooh-planosebeneficios.component';
import { EstabelecimentosComponent } from './landing/estabelecimentos/estabelecimentos.component';
import { ProdutosPromoComponent } from './landing/produtos-promo/produtos-promo.component';
import { EventoComponent } from './landing/evento/evento.component';
//páginas principais
import { AjudaPontosComponent } from './landing/ajuda-pontos/ajuda-pontos.component';
import { ConvidadoListaComponent } from './convidado-lista/convidado-lista.component';


import { PresentearMamaeComponent } from './presentear-mamae/presentear-mamae.component';
import { EventoPipoohComponent } from './evento-pipooh/evento-pipooh.component';

import { PrincipalComponent } from './principal/principal.component';
import { CarrinhoComponent } from './carrinho/carrinho.component';
import { CarrinhoRifaComponent } from './carrinho-rifa/carrinho-rifa.component';

import { EscolherMamaeComponent } from './escolher-mamae/escolher-mamae.component';
import { ContatoComponent } from './contato/contato.component';
import { TrocaComponent } from './troca/troca.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { FaqComponent } from './faq/faq.component';
import { ParceiroComponent } from './parceiro/parceiro.component';
import { AfiliadoComponent } from './afiliado/afiliado.component';
import { EmpresarialComponent } from './empresarial/empresarial.component';
import { RedirectComponent } from './redirect/redirect.component';
import { CarrinhoRevelacaoComponent } from './carrinho-revelacao/carrinho-revelacao.component';
import { LembreteCanceladoComponent } from './landing/lembrete-cancelado/lembrete-cancelado.component';
import { ContatosLembreteComponent } from './landing/contatos-lembrete/contatos-lembrete.component';
import { ComprePontueComponent } from './landing/compre-pontue/compre-pontue.component';
import { CadastroLiveloComponent } from './landing/cadastro-livelo/cadastro-livelo.component';
import { ComprePontueRegulamentoComponent } from './landing/compre-pontue-regulamento/compre-pontue-regulamento.component';
import { CadastroLiveloRegulamentoComponent } from './landing/cadastro-livelo-regulamento/cadastro-livelo-regulamento.component';
import { VoucherRhComponent } from './landing/voucher-rh/voucher-rh.component';
import { CartaoPresenteComponent } from './landing/cartao-presente/cartao-presente.component';
import { EventosComponent } from './eventos/eventos.component';
import { BeneficiosComponent } from './beneficios/beneficios.component';
import { EventoEstabelecimentoComponent } from './evento-estabelecimento/evento-estabelecimento.component';
import { EventosEstabelecimentoComponent } from './eventos-estabelecimento/eventos-estabelecimento.component';
import { IngressoEstabelecimentoComponent } from './ingresso-estabelecimento/ingresso-estabelecimento.component';
import { VisualizacaoCartaopresenteComponent } from './landing/visualizacao-cartaopresente/visualizacao-cartaopresente.component';

import { environment } from "../environments/environment.prod";
import { PesquisaNpsComponent } from './landing/pesquisa-nps/pesquisa-nps.component';


const URL = `${environment.domain}`;
var rotas = [];

if(URL == 'https://pipooh.com.br/'){
    rotas = [
      //landing pages
        { path: 'remarketing-landing', component: RemarketingLandingComponent },
        { path: 'pipooh-mais-barato-re', component: PipoohMaisBaratoReComponent },
        { path: 'armazenamento-fralda-re', component: ArmazenamentoFraldaReComponent },
        { path: 'bilhetes-rifa-re', component: BilhetesRifaReComponent },
        { path: 'perder-produtos-re', component: PerderProdutosReComponent },
        { path: 'cha-rifa-cad', component: ChaRifaCadComponent },
        { path: 'cha-fralda-cad', component: ChaFraldaCadComponent },
        { path: 'perder-fraldas-cad', component: PerderFraldasCadComponent },
        { path: 'mais-bilhetes-cad', component: MaisBilhetesCadComponent },
        { path: 'pre-lancamento', component: PreLancamentoComponent },
        { path: 'assinatura', component: PreLancamentoComponent },
        { path: 'clube-pipooh-planosebeneficios', component: ClubePipoohPlanosebeneficiosComponent },
        { path: 'estabelecimentos', component: EstabelecimentosComponent },
        { path: 'sextou-pipooh', component: ProdutosPromoComponent },
        { path: 'evento', component: EventoComponent },
        {path: 'lembrete-cancelado', component: LembreteCanceladoComponent},
        {path: 'contatos-lembrete', component: ContatosLembreteComponent},
        {path: 'compre-pontue', component: ComprePontueComponent},
        {path: 'cadastro-livelo', component: CadastroLiveloComponent},
        {path: 'compre-pontue-regulamento', component: ComprePontueRegulamentoComponent},
        {path: 'cadastro-livelo-regulamento', component: CadastroLiveloRegulamentoComponent},
        {path: 'voucherRh', component: VoucherRhComponent},
        {path: 'cartao-presente', component: CartaoPresenteComponent},
        {path: 'cartaoPresente', component: VisualizacaoCartaopresenteComponent},
        {path: 'pesquisa-nps', component: PesquisaNpsComponent},
      //páginas principais
        { path: 'ajuda-pontos', component: AjudaPontosComponent },
        { path: 'convidado-lista', component: ConvidadoListaComponent },
      
        { path: 'cha-fralda', component: PresentearMamaeComponent },
        { path: 'evento-pipooh', component: EventoPipoohComponent },
        { path: 'evento-estabelecimento', component: EventoEstabelecimentoComponent },
        { path: 'eventos-estabelecimento', component: EventosEstabelecimentoComponent },
        { path: 'ingresso-estabelecimento', component: IngressoEstabelecimentoComponent },
      
        { path: '', component: PrincipalComponent },
        { path: 'carrinho', component: CarrinhoComponent },
        { path: 'carrinho-rifa', component: CarrinhoRifaComponent },
        { path: 'carrinho-revelacao', component: CarrinhoRevelacaoComponent },
      
      
        { path: 'beneficios', component: BeneficiosComponent },
        { path: 'escolher-mamae', component: EscolherMamaeComponent },
        { path: 'eventos', component: EventosComponent },
        { path: 'contato', component: ContatoComponent },
        { path: 'troca', component: TrocaComponent },
        { path: 'produtos', component: ProdutosComponent },
        { path: 'faq', component: FaqComponent },
        { path: 'parceiro', component: ParceiroComponent },
        { path: 'afiliado', component: AfiliadoComponent },
        { path: 'empresarial', component: EmpresarialComponent },
        { path: 'PresentearMamae.aspx', component: RedirectComponent },
      
      
      ];
} else if (URL == 'https://sistema.zull.com.br/') {
  rotas = [
    //landing pages
    //páginas principais
      { path: 'evento-estabelecimento', component: EventoEstabelecimentoComponent },
      { path: 'eventos-estabelecimento', component: EventosEstabelecimentoComponent },
      { path: 'ingresso-estabelecimento', component: IngressoEstabelecimentoComponent },
      {path: 'pesquisa-nps', component: PesquisaNpsComponent},
    ];
}



const routes: Routes = rotas;

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', enableTracing: false,
  anchorScrolling: 'enabled' }), IvyCarouselModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
