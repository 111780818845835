import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EstabelecimentoService } from 'src/app/_services/estabelecimento.service';
import { ProductsService } from 'src/app/_services/products.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { PedidoService } from 'src/app/_services/pedido.service';
import { cpf } from 'cpf-cnpj-validator';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MarketplaceService } from 'src/app/_services/marketplace.service';
import { ServicoGiftService } from 'src/app/_services/servico-gift.service';
import { CategoriaEstabelecimentoService } from 'src/app/_services/categoria-estabelecimento.service';
import { SEOService } from 'src/app/_services/seo.service';
@Component({
  selector: 'app-compre-pontue',
  templateUrl: './compre-pontue.component.html',
  styleUrls: ['./compre-pontue.component.css']
})
export class ComprePontueComponent implements OnInit {
  public URLNova = "https://img.pipooh.com.br/"

  //
  closeResult = '';
  //pedido
  valor: any;
  pedido: any = [];
  //Formularios
  formCadastrado: FormGroup;
  formCadastrar: FormGroup;
  submitted: any = false;
  //
  semanas: any = [];
  semana: any;
  pageProdutos = 1;
  pageLojas = 1;
  pageAnuncios = 1;
  pageServicos = 1;
  pageSize = 6;
  collectionSize = [];
  selected = new FormControl(0);
  // estabelecimento
  estabelecimentos: any = [];
  estabelecimentoImagem: any = [];
  categoriasEstabelecimento: any[];
  categoriaSelecionada: any = "";
  //produtos
  products: any = [];
  categorys: any = "";
  product: any = [];
  more: boolean = false;
  chosenImg: any;
  imagens: any;
  totalProdutos: any = 0;
  //busca
  buscaProduto: any = { "categoria": "", "loja": "", "genero": "", "ordem": "" };
  buscar: any = "";
  //loading
  loading: any = false;
  loadingProdutos: any = false;
  loadingLojas: any = false;
  loadingAnuncios: any = false;
  loadingServicos: any = false;
  //anuncios
  anunciosVendas: any = [];
  anunciosDoacao: any = [];
  anuncios: any = [];
  categoriasAnuncio: any = [];
  ufs: any = [];
  cidades: any = [];
  buscaAnuncio: any = { "categoria": "", "uf": "", "cidade": "", "tipo": "" };
  //servicos
  servicos: any = [];


  constructor(private _estabelecimentoService: EstabelecimentoService,
    private modalService: NgbModal,
    private _router: Router,
    private _prodService: ProductsService,
    private _usuarioService: UsuarioService,
    private _pedidoService: PedidoService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _marketplace: MarketplaceService,
    private _servicoGiftService: ServicoGiftService,
    private _categoriaEstabelecimentoService: CategoriaEstabelecimentoService,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.seoService.updateMetaInfo(
      'og:image',
      "../../../assets/img/banners/ComprePontueMobile1.jpeg"
    );
    this.formCadastrado = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, , Validators.email]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      celular: ['', [Validators.required, Validators.minLength(11)]]
    });
    this.formCadastrar = this.formBuilder.group({
      nome: ['', [Validators.required]],
      sexo: ['mamãe', [Validators.required]],
      email: ['', [Validators.required, , Validators.email]],
      cpf: ['', [Validators.required, Validators.minLength(11)]],
      celular: ['', [Validators.required, Validators.minLength(11)]],
      senha: ['', [Validators.required,]],
      senhaR: ['', [Validators.required,]],
      tipo: ['gestante', [Validators.required,]],
      termos: ['', [Validators.required,]],
      politica: ['', [Validators.required,]],
      semana: ['',],
      pedidoValor: ['',],
      ultimaMenstruacao: ['',],
      dataNascimentoCrianca: ['',],
    });
    for (var i = 42; i >= 1; i--) {
      if (i != 0) {
        this.semanas[i] = i;
      }
    }
    this.semanas.shift();
    this.search();
    this.getCategorias();
    this.buscarCategoriasEstabelecimento();
    this.buscarFiltrosAnuncio();
    this.imagens = JSON.parse('{"payload":[{"id":"1"}, {"id":"2"}, {"id":"3"}]}');
  }

  formatDateBD(date) {
    if (date) {
      var dateSent = new Date(date);
      var startTime = moment(dateSent).format("YYYY/MM/DD");
      return startTime;
    }
  }

  onSubmitFormCadastrado() {
    this.loading = true;
    this.submitted = true;
    if (!this.formCadastrado.valid) {
      this.loading = false;
      return false;
    } else {
      this._usuarioService.selecionarEmail({ "email": this.formCadastrado.value.email }).then(data => {
        if (data.payload.length == 0) {
          this.loading = false;
          this.toastr.error("E-mail não cadastrado na Pipooh", "E-mail inválido");
        } else {
          if (this.formCadastrado.value.nome.indexOf(' ') <= 0) {
            this.toastr.error('Informe o nome completo', 'Falha no cadastro'); this.loading = false; return false;
          };
          this._pedidoService.inserirPedidoLivelo({ "pPedidoValor": this.valor, "fkUsuario": data.payload[0].UserID, "nome": this.formCadastrado.value.nome, "email": this.formCadastrado.value.email, "cpf": this.formCadastrado.value.cpf, "ddd": this.formCadastrado.value.celular.substr(0, 2), "celular": this.formCadastrado.value.celular.substr(2) }).then(data => {
            if (data.success) {
              var aux = data.payload[0];
              this.loading = false;
              window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];
            } else {
              this.loading = false;
            }

          }).catch(e => {
            this.loading = false;
          });
        }
      });
    }
    this.loading = false;
  }

  onSubmitFormCadastrar() {
    this.submitted = true;
    this.loading = true;
    if (!this.formCadastrar.valid) {
      return false;
    } else {
      this._usuarioService.selecionarEmail({ "email": this.formCadastrar.value.email }).then(data => {
        if (data.payload.length > 0) {
          this.loading = false;
          this.toastr.error("E-mail já cadastrado na Pipooh", "E-mail inválido");
        } else {
          if (this.formCadastrar.value.nome.indexOf(' ') <= 0) {
            this.toastr.error('Informe o nome completo', 'Falha no cadastro'); this.loading = false; return false;
          };
          if (cpf.isValid(this.formCadastrar.value.cpf) == false) { this.toastr.error('CPF inválido', 'Falha no cadastro'); this.loading = false; return false; }
          if (this.formCadastrar.value.senha != this.formCadastrar.value.senhaR) { this.toastr.error('As senhas não coincidem', 'Falha no cadastro'); this.loading = false; return false; }
          if (this.formCadastrar.value.tipo == "gestante") {
            if (this.formCadastrar.value.semana == undefined || this.formCadastrar.value.semana == "") {
              this.toastr.error('Semana é obrigátoria', 'Falha no cadastro');
              this.loading = false;
              return false;
            }
            else {
              var date = new Date;
              var dias = parseInt(this.formCadastrar.value.semana) * 7;
              date.setDate(date.getDate() - dias);
              this.formCadastrar.value.ultimaMenstruacao = this.formatDateBD(date);
            }
            if (this.formCadastrar.value.ultimaMenstruacao == undefined || this.formCadastrar.value.ultimaMenstruacao == "") { this.toastr.error('Data da menstruação é obrigátoria', 'Falha no cadastro'); this.loading = false; return false; }
          } else if (this.formCadastrar.value.tipo == "criancaNasceu") {
            if (this.formCadastrar.value.dataNascimentoCrianca == undefined || this.formCadastrar.value.dataNascimentoCrianca == "") {
              this.toastr.error('Data de nascimento é obrigátoria', 'Falha no cadastro');
              this.loading = false;
              return false;
            }
          }
          this.formCadastrar.value.pedidoValor = this.valor;
          this._pedidoService.inserirPedidoUsuarioLivelo(this.formCadastrar.value).then(data => {
            if (data.success) {
              var aux = data.payload[0];
              this.loading = false;
              window.location.href = "https://checkout.pipooh.com.br/?id=" + aux["token"];
            } else {
              this.loading = false;
            }
          }).catch(e => {
            this.loading = false;
          })
        }
      });
    }
  }



  formatDecimal(string) {
    if (string != "") {
      return parseFloat(string).toFixed(2);
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title,', size: 'lg', windowClass: 'modal', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openProduto(prod) {
    this.chosenImg = 0;
    this.product.obs = "";
    this.more = false;
    document.getElementById("openModalProduto").click();
    this.product = prod;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  
  search() {
    this.getProducts();
    this.buscarEstabelecimentoLista();
    this.getAnuncios();
    this.buscarServicos();
  }
  async getProducts() {
    this.loadingProdutos = true;
    await this._prodService.buscarProdutosMarketplace({ "page": this.pageProdutos, "itens": this.pageSize, "busca": this.buscar, "loja": this.buscaProduto.loja, "genero": this.buscaProduto.genero, "categoria": this.buscaProduto.categoria, "ordem": this.buscaProduto.ordem }).then(data => {
      this.loadingProdutos = false;
      var prod: any = data;
      this.products = prod.payload.produtos;
      console.log("produtos", this.products)
      this.totalProdutos = prod.payload.totalItens[0].totalItens;
        for (var i = 0; i < this.products.length; i++) {
          this.products[i].imagens = [];
          this.products[i].imagens.push(this.products[i].ProductsImage);
          
        }
    }).catch(e => { this.loadingProdutos = false; });
  }

  changePage(page) {
    this.loadingProdutos = true;
    this._prodService.buscarProdutosMarketplace({ "page": this.pageProdutos, "itens": this.pageSize, "busca": this.buscar, "loja": this.buscaProduto.loja, "genero": this.buscaProduto.genero, "categoria": this.buscaProduto.categoria, "ordem": this.buscaProduto.ordem }).then(data => {
      this.loadingProdutos = false;
      var prod: any = data;
      this.products = prod.payload.produtos;
      this.totalProdutos = prod.payload.totalItens[0].totalItens;
        for (var i = 0; i < this.products.length; i++) {
          this.products[i].imagens = [];
          this.products[i].imagens.push(this.products[i].ProductsImage);
          
        }
    }).catch(e => { this.loadingProdutos = false; });
  }
  async buscarEstabelecimentoLista() {
    this.loadingLojas = true
    await this._estabelecimentoService.selecionarEstabelecimentoLista({ "busca": this.buscar, "categoria": this.categoriaSelecionada }).then(data => {
      var aux = data;
      this.estabelecimentos = aux.payload;
      this.loadingLojas = false
      for (var i = 0; i < this.estabelecimentos.length; i++) {
        if (this.estabelecimentos[i].foto) {
          this.estabelecimentoImagem.push({ "path": "https://img.pipooh.com.br/" + this.estabelecimentos[i].foto });
        }
      }
    }).catch(e => { this.loadingLojas = false });

  }

  async getAnuncios() {
    this.loadingAnuncios = true;
    await this._marketplace.getAnuncios({ "tipo": this.buscaAnuncio.tipo, "uf": this.buscaAnuncio.uf, "cidade": this.buscaAnuncio.cidade, "categoria": this.buscaAnuncio.categoria, "busca": this.buscar }).then(data => {
      this.loadingAnuncios = false;
      var aux: any = data;
      this.anuncios = aux.payload;
      this.anuncios.forEach(anuncio => {
        if (anuncio.tipo == 'Venda') {
          this.anunciosVendas.push(anuncio)
        } else {
          this.anunciosDoacao.push(anuncio);
        }
      });
    }).catch(e => { this.loadingAnuncios = false; })
  }

  getCategorias() {
    this._prodService.buscarCategorias({}).then(data => {
      var cat: any = data;
      this.categorys = cat.payload;
      if (this.route.snapshot.params['filtro']) {
        this.buscaProduto.categoria = this.route.snapshot.params['filtro'];

      }
    })

  }

  buscarCategoriasEstabelecimento() {
    this._categoriaEstabelecimentoService.selecionarCategoria({}).then(data => {
      this.categoriasEstabelecimento = data.payload;
    })
  }
  buscarFiltrosAnuncio() {
    this._marketplace.buscarFiltros({}).then(data => {
      this.cidades = data.payload.cidade;
      this.ufs = data.payload.uf;
      this.categoriasAnuncio = data.payload.categoria;
    })
  }
  buscarServicos() {
    this.loadingServicos = true;
    this._servicoGiftService.buscarServicos({ "fkEstabelecimento": 64 }).then(data => {
      this.loadingServicos = false;
      this.servicos = data.payload;
      for (var i = 0; i < this.servicos.length; i++) {
        this.servicos[i].imagem = "https://img.pipooh.com.br/imagens/servicos_estabelecimento/" + this.servicos[i].imagem;
      }
    }).catch(e => { this.loadingServicos = false; })
  }
  comprarServico(servico) {
    var fkServico = servico.fkEstabelecimento + "-" + servico.id
    this._router.navigate(['/estabelecimento', { 'servico': fkServico }]);
  }

  abrirInfoCompra(content) {
    if (this.valor == "" || this.valor == null || this.valor < 0) {
      this.toastr.error("Valor inválido");
      return;
    }
    this.modalService.open(content, { centered: true });
  }

  urlImage(image, loja) {
    if (loja == 5 || loja == 0) {
        image = this.URLNova + image;
    }
    return image;
}
}
